import React from 'react';

function InformationSharing() {
  return (
    <div>
         
      {/* Section 3 */}
     
      <p>
        <strong>In Short:</strong> We may share information in specific situations described in this section and/or with the following third parties.
      </p>
      <p>We may share your personal information in the following situations:</p>
      <ul>
        <li>
          <strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
        </li>
        <li>
          <strong>When we use Google Maps Platform APIs:</strong> We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API).
        </li>
        <li>
          <strong>Other Users:</strong> When you share personal information or interact with public areas of the Services, such as comments or other content, this information may be viewed by all users.
        </li>
      </ul>
      <p>
        <strong>Note:</strong> For more details, refer to the relevant sections of our privacy policy to better understand how your information is shared and processed.
      </p>

    </div>
  );
}

export default InformationSharing;
