import {
  FormModalLayout,
  SuccessModal,
  WarningModal,
} from "Components/GeneralComponents";
import React, { useState } from "react";
import Profile from "./Profile";
import AddEmployees from "./AddEmployees";
import { addCompany } from "network/serviceProfessional/company";
import { validateServiceProCompanyDetails } from "utils/validations";
import { serviceProCompanyDetailsFormData } from "utils/FormDataStructure";

const CompanyProfileSetup = ({ isOpen, onClose }) => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [insuranceDocuments, setInsuranceDocuments] = useState([]);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [employeesModal, setEmployeesModal] = useState(false);
  const [employeeFormErros, setEmployeeFormErros] = useState({});
  const [form, setForm] = useState({
    companyName: "",
    companyType: "",
    email: "",
    phoneType: "",
    phone: "",
    ein: "",
    ssn: "",
    doingBusinessAs: "",
    description: "",
    services: [],
    addressLine_1: "",
    city: "",
    state: "",
    zipcode: "",
    country: "USA",
    insuranceDetails: "",
    insuranceExpirationDate: "",
    hourlyRateStart: "",
    hourlyRateEnd: "",
  });

  const [employees, setEmployees] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      accessLevel: "",
    },
  ]);

  const handleChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });
  };

  const handleSubmit = async () => {
    const newErrors = validateServiceProCompanyDetails(
      form,
      images,
      insuranceDocuments
    );

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    setIsLoading(true);
    
    const formData = await serviceProCompanyDetailsFormData(
      form,
      images[0],
      insuranceDocuments
    );

    try {
      addCompany(formData).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          setIsSuccessModalOpen(true);
          onClose();
        } else if (res.apiCallStatus === "error") {
          if (res.error === "DuplicateKeyError") {
            setIsWarningModalOpen(true);
          }
        }
      });
    } catch (error) {
      console.error("Error creating company:", error);
    }
  };

  const handleEmployeeFormChange = (index, fieldName, value) => {
    console.log("handleEmployeeFormChange", index, fieldName, value);
    setEmployees((prevEmployees) => {
      const updatedEmployees = [...prevEmployees];
      updatedEmployees[index] = {
        ...updatedEmployees[index],
        [fieldName]: value,
      };
      return updatedEmployees;
    });
  };

  const handleAddEmployee = () => {
    setEmployees((prevEmployees) => [
      ...prevEmployees,
      {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phone: "",
        accessLevel: "",
      },
    ]);
  };

  // Function to remove an employee from the form
  const removeEmployee = (indexToRemove) => {
    console.log("indexToRemove", employees);
    const updatedForm = employees.filter((_, index) => index !== indexToRemove);
    setEmployees(updatedForm); // Update the state with the new array
  };



  const handleEmployeeSave = () => {
    console.log("handleEmployeeSave", employees);
    // Validation function for employees
    const employeeErrors = employees.map((employee) => {
      const errors = {};
      if (!employee?.firstName.trim())
        errors.firstName = "First Name is required";
      if (!employee?.middleName.trim())
        errors.middleName = "Middle Name is required";
      if (!employee?.lastName.trim()) errors.lastName = "Last Name is required";
      if (!employee?.email.trim()) errors.email = "Email is required";
      if (!employee?.phone.trim()) errors.phone = "Phone Number is required";
      if (!employee?.accessLevel.trim())
        errors.accessLevel = "Access Level is required";
      return errors;
    });

    // Check if there are any errors
    const hasErrors = employeeErrors.some(
      (error) => Object.keys(error).length > 0
    );

    // If there are errors, set them in the state
    if (hasErrors) {
      setEmployeeFormErros(employeeErrors);
      return;
    } else {
      setEmployeeFormErros(employeeErrors);
    }

    // If no errors, save employees
    console.log("Employees Saved", employees);
  };

  const handleEmployeesModalClose = () => {
    setEmployeesModal(false);
  };
  return (
    <>
      {isSuccessModalOpen && (
        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={() => {
            setIsSuccessModalOpen(false);
            setEmployeesModal(true);
          }}
          title="Your Information Saved Successfully"
        />
      )}
      <WarningModal
        onClose={() => {
          setIsWarningModalOpen(false);
          handleChange("email", "");
        }}
        isOpen={isWarningModalOpen}
        message={
          "Company already exist with same email, please use another email."
        }
      />
      {isOpen && (
        <FormModalLayout
          title="Company Profile Setup"
          isOpen={isOpen}
          onClose={onClose}
          submitLabel="Submit"
          submitDisabled={false}
        >
          <Profile
            form={form}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            images={images}
            setImages={setImages}
            insuranceDocuments={insuranceDocuments}
            setInsuranceDocuments={setInsuranceDocuments}
            errors={errors}
            isLoading={isLoading}
          />
        </FormModalLayout>
      )}

      {employeesModal && (
        <FormModalLayout
          title="ADD EMPLOYEES"
          isOpen={employeesModal}
          onClose={handleEmployeesModalClose}
          submitLabel="Submit"
          submitDisabled={false}
        >
          <AddEmployees
            form={employees}
            handleChange={handleEmployeeFormChange}
            handleRemoveEmployee={removeEmployee}
            handleAddEmployee={handleAddEmployee}
            handleSubmit={handleEmployeeSave}
            onClose={handleEmployeesModalClose}
            errors={employeeFormErros}
            isLoading={isLoading}
          />
        </FormModalLayout>
      )}
    </>
  );
};

export default CompanyProfileSetup;
