import React from "react";

function InformationCollection() {
  const personalInfo = [
    "Names",
    "Email addresses",
    "Phone numbers",
    "Mailing addresses",
    "Usernames",
    "Job titles",
    "Passwords",
    "Contact preferences",
    "Contact or authentication data",
    "Debit/credit card numbers",
    "Billing addresses",
    "Credit reporting and background checks",
    "Information available from publicly available records",
    "Payment history and communications with landlords or tenants",
    "Usage information automatically collected when using our website or services",
    "Any other information you provide us",
    "Login information",
    "Credit information and social security number when you direct us to obtain your credit report",
    "Identification information such as driver’s license, pay stubs, and other documents you provide",
    "Geolocation data and current location",
    "Internet or other electronic network activity information",
    "Information that identifies, relates to, describes, or is capable of being associated with, a particular individual",
  ];
  const sensitiveInfo = [
    "credit worthiness data",
    "social security numbers or other government identifiers",
    "financial data",
    "passport number",
    "license plate number",
    "internet protocol address",
  ];

  return (
    <div>
      <h3 className="mt-3">Personal information you disclose to us</h3>
      <p className="fst-italic">
        <strong>IN Short: </strong> We collect personal information that you
        provide to us.
      </p>
      <p>
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Services, or otherwise when you contact us.
      </p>
      <p>
        <strong>Personal Information Provided by You.</strong> The personal
        information that we collect depends on the context of your interactions
        with us and the Services, the choices you make, and the products and
        features you use. The personal information we collect may include the
        following:
      </p>
      <ul>
        {personalInfo.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <p>
        <strong>Sensitive Information.</strong> When necessary, with your
        consent or as otherwise permitted by applicable law, we process the
        following categories of sensitive information:
      </p>
      <ul>
        {sensitiveInfo.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <p>
        <strong>Payment Data.</strong> We may collect data necessary to process
        your payment if you choose to make purchases, such as your payment
        instrument number, and the security code associated with your payment
        instrument. All payment data is handled and stored by Strip. You may
        find their privacy notice link(s) here: https://stripe.com/privacy.
      </p>
      <p>
        <strong>Social Media Login Data.</strong> We may provide you with the
        option to register with us using your existing social media account
        details, like your Facebook, X, or other social media account. If you
        choose to register in this way, we will collect certain profile
        information about you from the social media provider, as described in
        the section called{" "}
        <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=24188160-8816-438d-b645-f9996d8d05c3#sociallogins">
          "HOW DO WE HANDLE YOUR SOCIAL LOGINS?"
        </a>{" "}
        below.
      </p>
      <p>
        <strong>Application Data.</strong> If you use our application(s), we
        also may collect the following information if you choose to provide us
        with access or permission:
      </p>
      <ul>
        <li>
          <span className="fst-italic">Geolocation Information.</span> We may
          request access or permission to track location-based information from
          your mobile device, either continuously or while you are using our
          mobile application(s), to provide certain location-based services. If
          you wish to change our access or permissions, you may do so in your
          device's settings.
        </li>
        <li>
          <span className="fst-italic">Mobile Device Access.</span> We may
          request access or permission to certain features from your mobile
          device, including your mobile device's storage, reminders, calendar,
          and other features. If you wish to change our access or permissions,
          you may do so in your device's settings.
        </li>
        <li>
          <span className="fst-italic">Mobile Device Data.</span> We
          automatically collect device information (such as your mobile device
          ID, model, and manufacturer), operating system, version information
          and system configuration information, device and application
          identification numbers, browser type and version, hardware model
          Internet service provider and/or mobile carrier, and Internet Protocol
          (IP) address (or proxy server). If you are using our application(s),
          we may also collect information about the phone network associated
          with your mobile device, your mobile device’s operating system or
          platform, the type of mobile device you use, your mobile device’s
          unique device ID, and information about the features of our
          application(s) you accessed.
        </li>
        <li>
          <span className="fst-italic">Push Notifications.</span> We may request
          to send you push notifications regarding your account or certain
          features of the application(s). If you wish to opt out from receiving
          these types of communications, you may turn them off in your device's
          settings.
        </li>
      </ul>
      <p>
        This information is primarily needed to maintain the security and
        operation of our application(s), for troubleshooting, and for our
        internal analytics and reporting purposes.
      </p>
      <p>
        All personal information that you provide to us must be true, complete,
        and accurate, and you must notify us of any changes to such personal
        information.
      </p>
      <h3 className="mt-3">Information automatically collected</h3>
      <p>
       <strong>In Short:</strong> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is
        collected automatically when you visit our Services.
      </p>

      <p>
        We automatically collect certain information when you visit, use, or navigate the Services. This information
        does not reveal your specific identity (like your name or contact information) but may include device and usage
        information, such as your IP address, browser and device characteristics, operating system, language
        preferences, referring URLs, device name, country, location, information about how and when you use our
        Services, and other technical information. This information is primarily needed to maintain the security and
        operation of our Services, and for our internal analytics and reporting purposes.
      </p>

      <p>Like many businesses, we also collect information through cookies and similar technologies.</p>

      <h3>The information we collect includes:</h3>
      <ul>
        <li>
          <strong>Log and Usage Data:</strong> Log and usage data is service-related, diagnostic, usage, and performance
          information our servers automatically collect when you access or use our Services and which we record in log
          files. Depending on how you interact with us, this log data may include your IP address, device information,
          browser type and settings, and information about your activity in the Services (such as the date/time stamps
          associated with your usage, pages and files viewed, searches, and other actions you take such as which
          features you use), device event information (such as system activity, error reports (sometimes called "crash
          dumps"), and hardware settings).
        </li>
        <li>
          <strong>Device Data:</strong> We collect device data such as information about your computer, phone, tablet,
          or other device you use to access the Services. Depending on the device used, this device data may include
          information such as your IP address (or proxy server), device and application identification numbers, location,
          browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system
          configuration information.
        </li>
        <li>
          <strong>Location Data:</strong> We collect location data such as information about your device's location,
          which can be either precise or imprecise. How much information we collect depends on the type and settings of
          the device you use to access the Services. For example, we may use GPS and other technologies to collect
          geolocation data that tells us your current location (based on your IP address). You can opt out of allowing
          us to collect this information either by refusing access to the information or by disabling your Location
          setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the
          Services.
        </li>
      </ul>

      <h3>Google API:</h3>
      <p>
        Our use of information received from Google APIs will adhere to Google API Services User Data Policy, including
        the Limited Use requirements.
      </p>
    </div>
  );
}

export default InformationCollection;
