import React from "react";

function UpdatesToNotice() {
  return (
    <div>
      <p className="fst-italic">
        <strong>In Short:</strong> Yes, we will update this notice as necessary
        to stay compliant with relevant laws.
      </p>
      <p>
        We may update this Privacy Notice from time to time. The updated version
        will be indicated by an updated "Revised" date at the top of this
        Privacy Notice. If we make material changes to this Privacy Notice, we
        may notify you either by prominently posting a notice of such changes or
        by directly sending you a notification. We encourage you to review this
        Privacy Notice frequently to be informed of how we are protecting your
        information.
      </p>
    </div>
  );
}

export default UpdatesToNotice;
