import React from "react";

const Checkbox = ({ label, checked, onChange, errorMessage }) => {
  return (
    <div className="col">
      <div className="modal-check-box-container d-flex align-items-center">
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className={checked ? "checked" : ""}
        />
        <span className="tenant-personal-details-title ms-1">{label}</span>
      </div>
      {errorMessage && (
        <div className="text-danger fw-bold mt-2" style={{ fontSize: "12px" }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default Checkbox;
