import React, { useEffect, useState } from "react";
import activeDot from "assets/_Dot.png";
import inactiveDot from "assets/inactivedot.png";
import trashIconWhite from "assets/trash-icon-white.png";
import { useNavigate, Link } from "react-router-dom";
import { formatPhoneNumber } from "utils";
import * as Icon from "assets/icons";
import * as API from "network/landlord/tenant";
import * as Component from "Components/GeneralComponents";
import * as SVG from "assets/svg";
import helpIcon from "assets/help-circle.png";
import { message, Tooltip } from "antd";



const AllTenants = () => {
  // States start
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [key, setKey] = useState([]);
  const [delId, setDelId] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDeleteTenantModal, setIsDeleteTenantModal] = useState(false);
  const [isRerender, setIsRerender] = useState(false);
  const [isDeleteTenantRender, setIsDeleteTenantRender] = useState(false);
  const [isDeleteTenantsRender, setIsDeleteTenantsRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [filteredTenants, setFilteredTenants] = useState([]);
  const [deleteMsg, setDeleteMsg] = useState("Are you sure you want to delete this tenant?");
  const [disableDltBtn, setDisableDltBtn] = useState(false);
  const [mulDeleteMsg, setMulDeleteMsg] = useState("Are you sure want to delete selected tenants?");
  const [disableMulDltBtn, setDisableMulDltBtn] = useState(false);
  const [filter, setFilter] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    range: "",
    property: "",
  });

  const navigate = useNavigate();

  // handle get tenants with filters
  useEffect(() => {
    setIsTableLoading(true);
    API.getTenants(filter).then((res) => {
      if (res.apiCallStatus === "success") {
        setTenants(
          res.message.data?.map((e) => ({
            key: e.id,
            name: e.name,
            img: e?.profileImage,
            properties: e.properties,
            email: e.email,
            phone: e.phone,
            status: e.status,
          }))
        );
        setIsTableLoading(false);
      }
    });
  }, [isRerender]);

  const handleResetFilter = () => {
    setFilter({
      search: "",
      fromDate: "",
      toDate: "",
      range: "",
      property: "",
    });
  };

  // handle delete tenant
  useEffect(() => {
    if (delId) {
      setIsLoading(true);
      API.deleteTenant(delId).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          onCloseDeleteModal();
          message.success("Tenant Deleted Successfully");
          setIsRerender(!isRerender);
          setDelId("");
        } else {
          setDeleteMsg(res.message);
          setDisableDltBtn(true);
        }
      });
    }
  }, [isDeleteTenantRender]);

  // handle multiple delete tenants
  useEffect(() => {
    if (selectedTableItem.length > 0) {
      setIsLoading(true);
      API.DeleteBulkTenant(selectedTableItem).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          onCloseDeleteTenantModal();
          message.success("Tenants Deleted Successfully");
          setIsRerender(!isRerender);
          setSelectedTableItem([]);
        } else {
          setMulDeleteMsg(res.message);
          setDisableMulDltBtn(true);
        }
      });
    }
  }, [isDeleteTenantsRender]);

  // handle search
  useEffect(() => {
    if (filter.search) {
      setFilteredTenants(
        tenants.filter((item) => {
          if (item.name.toLowerCase().includes(filter.search.toLowerCase()))
            return item;
          if (item.email.toLowerCase().includes(filter.search.toLowerCase()))
            return item;
          if (item.phone.includes(filter.search)) return item;
          if (item.status.toLowerCase().includes(filter.search.toLowerCase()))
            return item;
          if (item.properties.includes(filter.search)) return item;
        })
      );
    } else {
      setFilteredTenants(tenants);
    }
  }, [filter.search, tenants]);

  // Data Table Functions
  const handleIconClick = (result) => {
    const filterData = tenants.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setDelId("");
    setDeleteMsg("Are you sure you want to delete this tenant?");
    setDisableDltBtn(false);
  };
  const onCloseDeleteTenantModal = () => {
    setIsDeleteTenantModal(false);
    setMulDeleteMsg("Are you sure want to delete selected tenants?");
    setDisableMulDltBtn(false);
  };

  const handleSendInvite = () => {
    API.sendInvite(key).then((res) => {
      if (res.apiCallStatus === "success") {
        message.success(res.message.message);
      }
    });
  };

  const columns = [
    {
      title: "Tenant Name",
      dataIndex: "name",
      render: (text, name) => (
        <>
          <Link
            to={`/tenant-details-view?id=${name.key}`}
            className="d-flex align-items-center gap-2"
          >
            <div
              className="d-flex align-items-center justify-content-center flex-shrink-0"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                overflow: "hidden",
                border: `0.5px solid #c9c9c9`,
                position: "relative",
              }}
            >
              {name.img ? (
                <img
                  src={name.img}
                  alt="task"
                  style={{ width: "100%", height: "100%", objectFit: "cover",}}
                />
              ) : (
                <Component.FirstCharacterIcon name={name.name} />
              )}
            </div>
            <div className="">{text}</div>
          </Link>
        </>
      ),
    },
    {
      title: (
        <>
          Properties
          <Tooltip title={"Non-Expired Leases Properties"}>
            <img src={helpIcon} alt="" className="cursor ps-2" />
          </Tooltip>
        </>
      ),
      dataIndex: "properties",
      render: (text) => (
        <>
          <span className="tenants_table_properties_text">
            <Component.IconList
              properties={text}
              defaultimage={SVG.TownHouse}
            />
          </span>
        </>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      render: (text) => (
        <>
          <span className="tenants_table_email_text">{text}</span>
        </>
      ),
    },
    {
      title: (
        <>
          Phone No
          <Tooltip title={"Tenant Phone Number"}>
            <img src={helpIcon} alt="" className="cursor ps-2" />
          </Tooltip>
        </>
      ),
      dataIndex: "phone",
      render: (text) => (
        <>
          {" "}
          <span className="tenants_table_phone_text phone">
            {formatPhoneNumber(text)}
          </span>
        </>
      ),
    },
    {
      title: (
        <>
          Status
          <Tooltip title={"Shows if tenant account is created"}>
            <img src={helpIcon} alt="" className="cursor ps-2" />
          </Tooltip>
        </>
      ),
      dataIndex: "status",
      render: (text, status) => (
        <>
          <div
            className={
              text === "Active"
                ? "prospect-active-bar"
                : "prospect-inactive-bar"
            }
          >
            <img src={text === "Active" ? activeDot : inactiveDot} alt="" />{" "}
            <span>{text}</span>
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDeleteRecord: () => {
                  onOpenDeleteModal();
                  setDelId(setting.key);
                },
                handleView: () => {
                  navigate(`/tenant-details-view?id=${setting.key}`);
                },
                handleEdit: () => {
                  navigate(`/edit-tenant-details/${setting.key}`);
                },
                handleSendInvite: handleSendInvite,
              }}
              fields={{
                view: true,
                sendInvite: setting.status !== "Active",
                edit: true,
                delete: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableItem([...selectedRowKeys]);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  return (
    <>
      <Component.DeleteModal
        isOpen={openDeleteModal}
        onClose={onCloseDeleteModal}
        message={deleteMsg}
        deleteCall={() => setIsDeleteTenantRender(!isDeleteTenantRender)}
        isLoading={isLoading}
        disableBtn={disableDltBtn}
      />

      <Component.DeleteModal
        isOpen={isDeleteTenantModal}
        onClose={onCloseDeleteTenantModal}
        message={mulDeleteMsg}
        deleteCall={() => setIsDeleteTenantsRender(!isDeleteTenantsRender)}
        isLoading={isLoading}
        disableBtn={disableMulDltBtn}
      />

      <div className="container-fluid bg-white p-3 ">
        <Component.FilterRow
          filter={filter}
          setFilter={setFilter}
          btnTitle={"Add New Tenant"}
          btnHandler={() => navigate("/add-tenant-details")}
          handleSubmit={() => setIsRerender(!isRerender)}
          handleReset={handleResetFilter}
          filterList={{
            date: true,
            range: true,
            property: true,
          }}
        />

        <div className="row mt-5 bg">
          <div className="col-md-12">
            {selectedTableItem.length !== 0 && (
              <div className="table-delete-icon mb-3">
                <button
                  onClick={() => setIsDeleteTenantModal(true)}
                  className="table-delete-btn next-btn-main"
                >
                  <img src={trashIconWhite} alt="" />
                  Delete
                </button>
              </div>
            )}
            <div>
              <Component.CustomTable
                loading={isTableLoading}
                pagination={true}
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={filteredTenants}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllTenants;
