import React, { useEffect,useState } from "react";
import ProfileUploader from "../ProfileUploader/ProfileUploader";
import {
  InputField,
  PhoneNumberField,
  DateField,
  SelectField,
  SubmitButton,
  PasswordField,
  SSNField
} from "Components/GeneralComponents";
import {
  usaStates,
  employmentStatus,
  driverLicenseState,
  incomeFrequency,
  maritalStatus,
  selectGender,
} from "utils/dropdownData";
import { formatDate } from "utils";
const EditProfile = ({
  form,
  handleChange,
  errors,
  setProfileImages,
  ProfileImages,
  setDeletedImages,
  DeletedImages,
  handleNext,
  disabledFields,
  isLoading = false,
}) => {
  console.log("Disabled Fields", disabledFields);
  console.log("formatDate",form)

  return (
    <div className="container-fluid">
      {/* <div className="row">
        <div className="col-md-6 mt-3">
          <div className="stepper-content d-flex gap-2 align-items-center">
            <div className="dragger">
              <p>Your Photo</p>
              <ProfileUploader
                setProfileImages={setProfileImages}
                ProfileImages={ProfileImages}
                setDeletedImages={setDeletedImages}
                DeletedImages={DeletedImages}
              />
            </div>
          </div>
        </div>
      </div> */}
      <div className="row">
        <div className="col-md-4 mt-3">
          <InputField
            name={"First Name"}
            required={true}
            label={"First Name"}
            placeholder={"First Name"}
            value={form.firstName}
            handler={(e) => handleChange("firstName", e.target.value)}
            errorMessage={errors.firstName}
            // disabled={disabledFields.includes("firstName")}
          />
        </div>
        <div className="col-md-4 mt-3">
          <InputField
            name={"Middle Name"}
            label={"Middle Name"}
            placeholder={"Middle Name"}
            value={form.middleName}
            handler={(e) => handleChange("middleName", e.target.value)}
            // disabled={disabledFields.includes("middleName")}
          />
        </div>
        <div className="col-md-4 mt-3">
          <InputField
            name={"Last Name"}
            required={true}
            label={"Last Name"}
            placeholder={"Last Name"}
            value={form.lastName}
            handler={(e) => handleChange("lastName", e.target.value)}
            errorMessage={errors.lastName}
            // disabled={disabledFields.includes("lastName")}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mt-3">
          <InputField
            name={"Email"}
            required={true}
            label={"Email"}
            placeholder={"Email"}
            value={form.email}
            handler={(e) => handleChange("email", e.target.value)}
            errorMessage={errors.email}
            // disabled={disabledFields.includes("email")}
          />
        </div>
        <div className="col-md-6 mt-3">
          <PhoneNumberField
            name={"Phone No"}
            required={true}
            label={"Phone No"}
            placeholder={"Phone No"}
            value={form.phone}
            handler={(value) => handleChange("phone", value)}
            errorMessage={errors.phone}
            // disabled={disabledFields.includes("phone")}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mt-3">
          <DateField
            required={true}
            label={"Date of Birth"}
            placeholder={"Select Date"}
            defaultValue={form.dob && formatDate(form.dob)}
            handler={(e) => {
              const formattedDate = new Date(e).toLocaleDateString();
              handleChange("dob", formattedDate);
            }}
            errorMessage={errors.dob}
            // disabled={disabledFields.includes("dob")}
          />
        </div>
        <div className="col-md-6 mt-3">
          <SSNField
          type="text"
            name={"SSN"}
            required={true}
            label={"SSN"}
            placeholder={"SSN"}
            value={form.ssn}
            handler={handleChange}
            errorMessage={errors.ssn}
          />
        </div>
        {/* <div className="col-md-4 mt-3">
          <InputField
          type="password"
            name={"National Id"}
            required={true}
            label={"Natinoal Id"}
            placeholder={"National Id"}
            value={form.nationalId}
            handler={(e) => handleChange("nationalId", e.target.value)}
            errorMessage={errors.password}
          />
        </div> */}
      </div>
      <div className="row">
        <div className="col-md-4 mt-3">
          <SelectField
            name={"Gender"}
            required={false}
            label={"Gender"}
            placeholder={"Select Gender"}
            value={form.gender}
            options={selectGender}
            handler={(e) => handleChange("gender", e)}
            errorMessage={errors.gender}
          />
        </div>
        <div className="col-md-4 mt-3">
          <SelectField
            name={"Marital Status"}
            required={true}
            label={"Marital Status"}
            placeholder={"Select Marital Status"}
            value={form.maritalStatus}
            options={maritalStatus}
            handler={(e) => handleChange("maritalStatus", e)}
            errorMessage={errors.maritalStatus }
          />
        </div>
        <div className="col-md-4 mt-3">
          <SelectField
            name={"Employement Status"}
            required={true}
            label={"Employement Status"}
            placeholder={"Select Employee Status"}
            value={form.employmentStatus}
            options={employmentStatus}
            handler={(e) => handleChange("employmentStatus", e)}
            errorMessage={errors.employmentStatus}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mt-3">
          <DateField
            required={true}
            label={"Move-in Date"}
            placeholder={"Select Date"}
            defaultValue={form.moveInDate && formatDate(form.moveInDate)}
            handler={(e) => {
              const formattedDate = new Date(e).toLocaleDateString();
              handleChange("moveInDate", formattedDate);
            }}
            errorMessage={errors.moveInDate}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputField
        type={"number"}
            name={"ITIN"}
            required={true}
            label={"Individual Taxpayer Identification Number (ITIN)"}
            placeholder={"ITIN"}
            value={form.itin}
            handler={(e) => handleChange("itin", e.target.value)}
            errorMessage={errors.itin}
          />
        </div>
      </div>
      <div className="row ">
        <div className="col-md-12 mt-3">
          <InputField
            name={"Address"}
            required={true}
            label={"Address"}
            placeholder={"Address"}
            value={form.address}
            handler={(e) => handleChange("address", e.target.value)}
            errorMessage={errors.address}
            // disabled={disabledFields.includes("address")}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 mt-3">
          <SelectField
            name={"Country"}
            required={true}
            label={"Country"}
            placeholder={"Select Country"}
            value={"USA"}
            options={[{ name: "USA" }, { name: "Canada" }]}
            handler={(e) => handleChange("country", e)}
            errorMessage={errors.country}
            disabled={true}
          />
        </div>
        <div className="col-md-3 mt-3">
          <SelectField
            name={"State"}
            required={true}
            label={"State"}
            placeholder={"Select State"}
            options={usaStates}
            value={form.state}
            handler={(e) => handleChange("state", e)}
            errorMessage={errors.state}
            // disabled={disabledFields.includes("city")}
          />
        </div>
        <div className="col-md-3 mt-3">
          <InputField
            name={"City"}
            required={true}
            label={"City"}
            placeholder={"Select City"}
            value={form.city}
            handler={(e) => handleChange("city", e.target.value)}
            errorMessage={errors.city}
            // disabled={disabledFields.includes("city")}
          />
        </div>
        <div className="col-md-3 mt-3">
          <InputField
            name={"Postal code"}
            required={true}
            label={"Zip Code"}
            placeholder={"Zip Code"}
            value={form.zipCode}
            handler={(e) => {
              if (/^\d{0,5}$/.test(e.target.value))
                handleChange("zipCode", e.target.value);
            }}
            errorMessage={errors.zipCode}
            // disabled={disabledFields.includes("zipCode")}
          />
        </div>
      </div>{" "}
      <div className="row">
        <div className="col-md-6 mt-3">
          <InputField
          type={"number"}
            name={"Income"}
            required={true}
            label={"Income"}
            placeholder={"Income"}
            value={form.income}
            handler={(e) => handleChange("income", e.target.value)}
            errorMessage={errors.income}
          />
        </div>

        <div className="col-md-6 mt-3">
          <SelectField

            name={"Income Frequency"}
            required={true}
            label={"Income Frequency"}
            placeholder={"Select Income Frequency"}
            value={form.incomeFrequency}
            options={incomeFrequency}
            handler={(e) => handleChange("incomeFrequency", e)}
            errorMessage={errors.incomeFrequency}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mt-3">
          <InputField
          type="Number"
            name={"Other Income"}
            required={true}
            label={"Other Income"}
            placeholder={"Other Income"}
            value={form.otherIncome}
            handler={(e) => handleChange("otherIncome", e.target.value)}
            errorMessage={errors.otherIncome}
          />
        </div>

        <div className="col-md-6 mt-3">
          <SelectField
            name={"Other Income Frequency"}
            required={true}
            label={"Other Income Frequency"}
            placeholder={"Select Other Income Frequency"}
            value={form.otherIncomeFrequency}
            options={incomeFrequency}
            handler={(e) => handleChange("otherIncomeFrequency", e)}
            errorMessage={errors.otherIncomeFrequency}
          />
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-6 mt-3">
          <SelectField
            name={"Driver License"}
            required={true}
            label={"Driver License"}
            placeholder={"Select Driver License"}
            value={form.driverLicense}
            options={driverLicenseState}
            handler={(e) => handleChange("driverLicense", e)}
            errorMessage={errors.driverLicense}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputField
            name={"Driver License Number"}
            required={true}
            label={"Driver License Number"}
            placeholder={"Driver License Number"}
            value={form.licenseNumber}
            handler={(e) => handleChange("licenseNumber", e.target.value)}
            errorMessage={errors.licenseNumber}
          />
        </div>
      </div> */}
      <div className="stepper-first-btn property-details-next-btn-main d-flex justify-content-between gap-3 mt-5">
        <SubmitButton handler={handleNext}>Next</SubmitButton>
      </div>
    </div>
  );
};

export default EditProfile;
