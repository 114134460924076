import React, { useState, useEffect } from "react";
import * as Icon from "assets/icons";
import { useMediaQuery } from "@mui/material";

function FormModalLayout({
  isOpen,
  overflowY = "auto",
  onClose,
  title,
  children,
  width,
}) {
  const isResponsive = useMediaQuery("(max-width:768px)");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShow(true);
    } else {
      setTimeout(() => setShow(false), 300); // Wait for the animation to finish before removing
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className={isResponsive ? "maindiv_responsive" : "maindiv"}>
          <div
            className={`internalmaindiv${isResponsive ? "_responsive" : ""} ${
              show ? "show" : "hide"
            }`}
            style={width ? { minWidth: width } : undefined} // Apply width only if provided
          >
            <div className="heading-div sticky-top">
              <h1 className="divtitleh1">{title}</h1>
              <button onClick={onClose} className="modal-cancel-btn">
                <Icon.CloseIcon />
              </button>
            </div>
            <div
              className="childdiv"
              style={{
                overflowY: overflowY,
              }}
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FormModalLayout;
