import React, { useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  InputField,
  DateField,
  AmountField,
  SubmitButton,
} from "Components/GeneralComponents";
import { formatDate } from "utils";
import { createMileage } from "network/landlord/mileage";

const AddMileage = () => {
  const navigate = useNavigate();

  const [form, setForm] = useState({
    fromLocation: "",
    toLocation: "",
    businessName: "",
    tripDate: "",
    returnTrip: false,
    returnTripDate: "",
    purpose: "",
    miles: "",
    centsPerMile: "",
    amount: "",
  });

  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);

  const calculateAmount = (updatedForm) => {
    const miles = parseFloat(updatedForm.miles || 0);
    const centsPerMile = parseFloat(updatedForm.centsPerMile || 0);
    const isRoundTrip = updatedForm.returnTrip ? 2 : 1;
    return ((miles * isRoundTrip * centsPerMile) / 100).toFixed(2);
  };

  const handleChange = (fieldName, value) => {
    const updatedForm = {
      ...form,
      [fieldName]: value,
    };

    if (fieldName === "miles" || fieldName === "centsPerMile" || fieldName === "returnTrip") {
      updatedForm.amount = calculateAmount(updatedForm);
    }

    setForm(updatedForm);
  };

  const formatKey = (key) => {
    return key
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const handleNext = async () => {
    setLoader(true);
    const newErrors = {};
    for (const key in form) {
      if ((key === "returnTripDate" && !form.returnTrip)) {
        continue;
      }
      if (form[key] === "") {
        newErrors[key] = `${formatKey(key)} is required`;
      }
    }
    setErrors(newErrors);
    console.error(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const response = await createMileage(form);
      if (response.apiCallStatus === "success") {
        setLoader(false);
        message.success("Mileage added successfully");
        navigate("/mileage");
      } else {
        setLoader(false);
        message.error("Failed to add mileage");
      }
    } else {
      setLoader(false);
    }
  };

  return (
    <div className="container p-3">
      <div className="container-fluid bg-white p-3">
        <div className="row mt-3">
          <div className="col-md-6">
            <InputField
              name={"From Location"}
              required={true}
              label={"From Location"}
              placeholder={"Enter starting location"}
              value={form.fromLocation}
              handler={(e) => handleChange("fromLocation", e.target.value)}
              errorMessage={errors.fromLocation}
            />
          </div>
          <div className="col-md-6">
            <InputField
              name={"To Location"}
              required={true}
              label={"To Location"}
              placeholder={"Enter destination"}
              value={form.toLocation}
              handler={(e) => handleChange("toLocation", e.target.value)}
              errorMessage={errors.toLocation}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-6">
            <InputField
              name={"Business Name"}
              required={true}
              label={"Business Name"}
              placeholder={"Enter business name"}
              value={form.businessName}
              handler={(e) => handleChange("businessName", e.target.value)}
              errorMessage={errors.businessName}
            />
          </div>
          <div className="col-md-6">
            <DateField
              label={"Trip Date"}
              name={"tripDate"}
              placeholder={"Select trip date"}
              defaultValue={form.tripDate && formatDate(form.tripDate)}
              handler={(e) => {
                const formattedDate = formatDate(new Date(e));
                handleChange("tripDate", formattedDate);
              }}
              errorMessage={errors.tripDate}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <label className="d-flex align-items-center">
              <input
                type="checkbox"
                checked={form.returnTrip}
                onChange={(e) => handleChange("returnTrip", e.target.checked)}
              />
              <span className="ms-2">Return Trip</span>
            </label>
          </div>

          {form.returnTrip && (
            <div className="col-md-6">
              <DateField
                // label={"Return Trip Date"}
                name={"returnTripDate"}
                placeholder={"Select return date"}
                defaultValue={
                  form.returnTripDate && formatDate(form.returnTripDate)
                }
                handler={(e) => {
                  const formattedDate = formatDate(new Date(e));
                  handleChange("returnTripDate", formattedDate);
                }}
                errorMessage={errors.returnTripDate}
              />
            </div>
          )}
        </div>

        <div className="row mt-3">
          <div className="col-md-12">
            <InputField
              name={"Purpose"}
              required={true}
              label={"Purpose"}
              placeholder={"Enter purpose of trip"}
              value={form.purpose}
              handler={(e) => handleChange("purpose", e.target.value)}
              errorMessage={errors.purpose}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12 d-flex flex-wrap">
            <div className="col-md-4 px-2">
              <InputField
                type={"number"}
                name={"Cents Per Mile"}
                required={true}
                label={"Cents Per Mile"}
                placeholder={"Enter cents per mile"}
                value={form.centsPerMile}
                handler={(e) => handleChange("centsPerMile", e.target.value)}
                errorMessage={errors.centsPerMile}
              />
            </div>
            <div className="col-md-4 px-2">
              <InputField
                type={"number"}
                name={"Miles"}
                required={true}
                label={"Miles (One Way)"}
                placeholder={"Enter miles"}
                value={form.miles}
                handler={(e) => handleChange("miles", e.target.value)}
                errorMessage={errors.miles}
              />
            </div>
            <div className="col-md-4 px-2">
              <AmountField
                name="amount"
                required={true}
                label="Amount"
                placeholder={"Calculated amount"}
                disabled={true}
                value={{ amount: form.amount }}
                handler={(value) => handleChange("amount", value.amount)}
                errorMessage={errors.amount}
              />
            </div>
          </div>
        </div>

        

        <div className="stepper-btn d-flex justify-content-between gap-1 pb-3 mt-5">
          <button
            onClick={() => {
              navigate("/mileage");
            }}
            className="back-prev-btn mt-3"
          >
            Cancel
          </button>
          <SubmitButton
            handler={handleNext}
            isLoading={loader}
            disabled={loader}
            className="next-btn-same-class mt-3"
          >
            Save
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default AddMileage;
