import React from 'react';

function CookiesAndTrackingTechnologies() {
  return (
    <div>
      <p>
        <strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your information.
      </p>
      <p>
        We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when
        you interact with our Services. Some online tracking technologies help us maintain the security of our Services
        and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.
      </p>
      <p>
        We also permit third parties and service providers to use online tracking technologies on our Services for
        analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your
        interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third
        parties and service providers use their technology to provide advertising about products and services tailored
        to your interests which may appear either on our Services or on other websites.
      </p>
      <p>
        To the extent these online tracking technologies are deemed to be “sale”/“sharing” (which includes targeted
        advertising, as defined under applicable laws) under applicable US state laws, you can opt out of these online
        tracking technologies by submitting a request as described below under section “DO UNITED STATES RESIDENTS HAVE
        SPECIFIC PRIVACY RIGHTS?”.
      </p>
      <p>
        Specific information about how we use such technologies and how you can refuse certain cookies is set out in our
        Cookie Notice.
      </p>
      <h3>Google Analytics</h3>
      <p>
        We may share your information with Google Analytics to track and analyze the use of the Services. The Google
        Analytics Advertising Features that we use include Remarketing with Google Analytics, Google Display Network
        Impression Reporting, and Google Analytics Demographics and Interests Reporting. To opt out of being tracked by
        Google Analytics across the Services, visit <a href="https://tools.google.com/dlpage/gaoptout">Google Opt-Out</a>.
      </p>
      <p>
        You can opt out of Google Analytics Advertising Features through{" "}
        <a href="https://adssettings.google.com/">Ads Settings</a> and{" "}
        <a href="http://optout.networkadvertising.org/">opt-out tools</a>.
      </p>
      <p>
        For more information on the privacy practices of Google, please visit the{" "}
        <a href="https://policies.google.com/privacy">Google Privacy & Terms page</a>.
      </p>

   
     
    </div>
  );
}

export default CookiesAndTrackingTechnologies;
