import React, { useState, useEffect } from "react";

const CustomMobileTablePaginagtion = ({
  totalItems,
  itemsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const totalPages = itemsPerPage > 0 ? Math.ceil(totalItems / itemsPerPage) : 1;
  const pagesPerGroup = 4; // Number of pages to show at a time
  const [currentPageGroup, setCurrentPageGroup] = useState(1);

  const totalPageGroups = Math.ceil(totalPages / pagesPerGroup);
  const startPage = (currentPageGroup - 1) * pagesPerGroup + 1;
  const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages);

  // Synchronize current page with page group when navigating with arrows
  useEffect(() => {
    if (currentPage < startPage) {
      setCurrentPageGroup((prevGroup) => prevGroup - 1);
    } else if (currentPage > endPage) {
      setCurrentPageGroup((prevGroup) => prevGroup + 1);
    }
  }, [currentPage, startPage, endPage]);

  const handlePreviousGroup = () => {
    if (currentPageGroup > 1) {
      const newGroup = currentPageGroup - 1;
      setCurrentPageGroup(newGroup);
      // Set the current page to the last page of the previous group
      setCurrentPage((newGroup - 1) * pagesPerGroup + pagesPerGroup);
    }
  };

  const handleNextGroup = () => {
    if (currentPageGroup < totalPageGroups) {
      const newGroup = currentPageGroup + 1;
      setCurrentPageGroup(newGroup);
      // Set the current page to the first page of the next group
      setCurrentPage((newGroup - 1) * pagesPerGroup + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="d-flex flex-wrap align-items-center justify-content-end gap-3 my-4 mx-3">
      <div className="text-center w-100 w-sm-auto">
        <p>
          {itemsPerPage * (currentPage - 1) + 1}-
          {Math.min(itemsPerPage * currentPage, totalItems)} of {totalItems}{" "}
          items
        </p>
      </div>
      <div className="d-flex flex-wrap align-items-center justify-content-center gap-2 w-100 w-sm-auto">
        {/* Big Previous Button */}
        <button
          className={`btn btn-outline-secondary ${
            currentPage === 1 ? "disabled" : ""
          }`}
          onClick={handlePrevious}
          disabled={currentPage === 1}
        >
          {"<"}
        </button>

        {/* Small Previous Group Button */}
        {currentPageGroup > 1 && (
          <button
            className="btn btn-outline-secondary"
            onClick={handlePreviousGroup}
          >
            {"<<"}
          </button>
        )}

        {/* Page Buttons */}
        <div className="d-flex">
          {endPage >= startPage &&
            [...Array(endPage - startPage + 1)].map((_, index) => (
              <button
                key={startPage + index}
                onClick={() => setCurrentPage(startPage + index)}
                className={`btn mx-1 ${
                  currentPage === startPage + index
                    ? "btn-primary text-white"
                    : "btn-outline-secondary"
                }`}
              >
                {startPage + index}
              </button>
            ))}
        </div>

        {/* Small Next Group Button */}
        {currentPageGroup < totalPageGroups && (
          <button
            className="btn btn-outline-secondary"
            onClick={handleNextGroup}
          >
            {">>"}
          </button>
        )}

        {/* Big Next Button */}
        <button
          className={`btn btn-outline-secondary ${
            currentPage === totalPages ? "disabled" : ""
          }`}
          onClick={handleNext}
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default CustomMobileTablePaginagtion;