'use client';

import React from 'react';
import { 
  CheckCircleOutlined, 
  CreditCardOutlined, 
  SafetyCertificateOutlined, 
  SolutionOutlined, 
  TeamOutlined 
} from '@ant-design/icons';

export default function TenantPassportModal({ isOpen, onClose, onViewPricing }) {
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop position-fixed top-0 start-0 w-100 h-100 bg-black bg-opacity-50" 
         style={{ zIndex: 1050 }}>
      <div className="modal d-block" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '700px' }}>
          <div className="modal-content border-0 shadow p-4 p-md-5">
            {/* Header */}
            <h2 className="modal-title fs-2 fw-bold text-center mb-3" 
                style={{ color: '#ff6b4a' }}>
              What is a Tenant Passport?
            </h2>

            {/* Description */}
            <p className="text-secondary text-center mb-4">
              Tenant Passport is an all-in-one rental application profile we help you create that can 
              be shared with multiple landlords.
            </p>

            {/* What's Included Section */}
            <div className="mb-4">
              <h3 className="fs-5 fw-bold mb-3">What is included in my Passport?</h3>
              <ul className="list-unstyled mb-0">
                {[
                  { icon: <CreditCardOutlined />, text: "Credit Report" },
                  { icon: <SafetyCertificateOutlined />, text: "Background Check" },
                  { icon: <SolutionOutlined />, text: "Employment Details" },
                  { icon: <TeamOutlined />, text: "Rental History" },
                  { icon: <CheckCircleOutlined />, text: "Identity & Income Verification" }
                ].map((item, index) => (
                  <li key={index} className="d-flex align-items-center mb-2">
                    <span style={{ color: '#ff6b4a' }} className="me-2">{item.icon}</span>
                    <span className="text-dark">{item.text}</span>
                  </li>
                ))}
              </ul>
            </div>

            {/* Benefits Section */}
            <div className="mb-4">
              <h3 className="fs-5 fw-bold mb-3">What are the benefits?</h3>
              <ul className="list-unstyled mb-0">
                {[
                  "Valid for 30 days",
                  "Apply to multiple properties with one profile",
                  "Streamlined Application Process",
                  "Control Landlord Access",
                  "Shared Dashboard"
                ].map((benefit, index) => (
                  <li key={index} className="d-flex align-items-center mb-2">
                    <CheckCircleOutlined style={{ color: '#ff6b4a' }} className="me-2" />
                    <span className="text-dark">{benefit}</span>
                  </li>
                ))}
              </ul>
            </div>

            {/* Pricing Section */}
            <div className="mb-4">
              <h3 className="fs-5 fw-bold mb-2">Simple Pricing</h3>
              <p className="mb-1">Choose from three one-time payment options.</p>
              <p className="fw-bold mb-0">
                No Subscriptions – One-Time Fee.
              </p>
            </div>

            {/* Buttons */}
            <div className="d-flex flex-column flex-sm-row gap-2 mt-4">
              <button
                onClick={onViewPricing}
                className="btn w-100 text-white fw-medium px-4 py-2"
                style={{ 
                  backgroundColor: '#ff6b4a',
                  borderColor: '#ff6b4a'
                }}
              >
                View Pricing Options
              </button>
              <button
                onClick={onClose}
                className="btn w-100 fw-medium px-4 py-2"
                style={{ 
                  color: 'white',
                  backgroundColor: '#f14c4c',
                  borderColor: '#ff6b4a'
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

