import { useEffect, useState } from "react";
import { message } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import InviteProfessionalModal from "Modals/InviteProfessionalModal/InviteProfessionalModal";
import config from "Helpers/config.js";
import { FormModalLayout } from "Components/GeneralComponents";
import * as Component from "Components/GeneralComponents";
import * as Icon from "assets/icons";
import helpIcon from "assets/help-circle.png";
import { Tooltip } from "antd";
import ListServiceProfessionalTab from "./ListServiceProfessionalTab";
import FindLocalProfessionalTab from "./FindLocalProfessionalTab";
import { getServiceProfessinals } from "network/landlord/serviceprofessional";
import { FetchUser } from "network/landlord/Account";
import { getCoordinatesFromAddress } from "utils";

const AllServiceProfessional = () => {
  //States start
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [key, setKey] = useState([]);
  const { id, tab } = UseUrlParamsHook();
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [companyId, setCompanyId] = useState("");
  const [professionalsdata, setProfessionalsData] = useState([]);
  const [localServiceProFilters, setLocalServiceProFilters] = useState({
    coordinates: "",
    distanceInMiles: "",
    minCharges: "",
    maxCharges: "",
    service: "",
    state: "",
    page: 1,
    limit: 10,
  });
  const [localServiceProLoading, setLocalServiceProLoading] = useState(false);
  const [localServiceProCount, setLocalServiceProCount] = useState(0);

  useEffect(() => {
    setActiveTab(tab || "1");
  }, [tab]);

  const onChange = (key) => {
    setActiveTab(key);
    searchParams.set("tab", key);
    navigate({ search: searchParams.toString() });
  };

  useEffect(() => {
    FetchUser().then((res) => {
      if (res.apiCallStatus === "success") {
        const address = `${res.message.address}, ${res.message.city.name}, ${res.message.state.name}, ${res.message.zipcode.number}, ${res.message.country.name}`;
        getCoordinatesFromAddress(address).then((res) => {
          setLocalServiceProFilters({
            ...localServiceProFilters,
            coordinates: {
              longitude: res.lng,
              latitude: res.lat,
              // longitude: -97.7305,
              // latitude: 30.2821,
            },
          });
        });
      }
    });
  }, []);

  useEffect(() => {
    setLocalServiceProLoading(true);
    getServiceProfessinals(localServiceProFilters).then((res) => {
      setLocalServiceProLoading(false);
      if (res.apiCallStatus === "success") {
        setProfessionalsData(
          res.message.companies.map((e, index) => ({
            id: e.id,
            companyLogo: e?.companyLogo || "",
            companyName: e?.companyName || "",
            services: e?.services || [],
            state: e?.state || "",
            charges: e?.hourlyRateStart + " - " + e?.hourlyRateEnd,
            description: e?.description || "",
            new: true,
          }))
        );
        setLocalServiceProCount(res.message.totalCompanies);
      }
    });
  }, [update]);

  // Data Table Functions
  const handleIconClick = (result) => {
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const onOpenModal = (company) => {
    console.log("onOpenModal company: ", company);
    setCompanyId(company);
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  const deleteData = (id) => {
    fetch(`${config["baseUrl"]}/api/serviceProviders/deleteInvite/${id}`, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.apiCallStatus === "success") {
          message.success("Invitation deleted successfully");
          navigate(`/all-service-professional`);
          if (setUpdate) setUpdate(true);
        }
        if (res.apiCallStatus === "error") {
          message.error("error in deleting");
        }
      });
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "name",
      render: (text, setting) => (
        <>
          <Link
            to={`/all-service-professional-details?id=6737a48a0cff9153fe227835`}
            className="d-flex align-items-center gap-2"
          >
            <div
              className="d-flex align-items-center justify-content-center flex-shrink-0"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                overflow: "hidden",
                border: `0.5px solid #c9c9c9`,
                position: "relative",
              }}
            >
              {setting.file ? (
                <img
                  src={setting.file}
                  alt="task"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <Component.FirstCharacterIcon name={setting.name} />
              )}
            </div>
            <div className="">{text}</div>
          </Link>
        </>
      ),
    },
    {
      title: (
        <>
          Services
          <Tooltip title={"Services will be provided."}>
            <img src={helpIcon} alt="" className="cursor ps-2" />
          </Tooltip>
        </>
      ),
      dataIndex: "services",
      render: (text) => (
        <>
          <span className="tenant_table_properties_sub_text">{text}</span>
        </>
      ),
    },
    {
      title: (
        <>
          Location
          <Tooltip title={"Service provided location."}>
            <img src={helpIcon} alt="" className="cursor ps-2" />
          </Tooltip>
        </>
      ),
      dataIndex: "location",
      render: (text) => (
        <>
          <span className="tenant_table_phone_text phone">{text}</span>
        </>
      ),
    },
    {
      title: "Charges",
      dataIndex: "charges",
      render: (text) => (
        <>
          <span className="tenant_table_email_text">{text}</span>
        </>
      ),
    },
    {
      title: <>Status</>,
      dataIndex: "status",
      render: (text, status) => (
        <>
          <div className="status-active">
            <span>{text}</span>
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <div
          className="position-relative cursor text-center"
          onClick={() => handleIconClick(setting.key)}
        >
          <Icon.VerticalElipsisIcon width={23} height={23} />
          <Component.RecordMenuPopup
            isOpen={setting.key === key}
            onClose={() => setKey(null)}
            handler={{
              handleView: () =>
                navigate(
                  `/all-service-professional-details?id=6737a48a0cff9153fe227835`
                ),
              handleSendInvite: () => onOpenModal("6737a48a0cff9153fe227835"),
              handleDelete: () => deleteData(setting.id),
            }}
            fields={{
              view: true,
              sendInvite: setting.status === "Declined" ? true : false,
              delete: setting.status === "Declined" ? true : false,
            }}
          />
        </div>
      ),
    },
  ];

  const data = [
    {
      key: 1,
      id: 1,
      name: "ABC Plumbing Services",
      file: "https://www.shutterstock.com/image-vector/circle-line-simple-design-logo-600nw-2174926871.jpg", // Placeholder image URL
      services: "Plumbing, Repair",
      location: "New York, NY",
      charges: "$100 - $500",
      status: "Active",
      setting: { key: 1, status: "Active" },
    },
    {
      key: 2,
      id: 2,
      name: "XYZ Electricians",
      file: "https://www.shutterstock.com/image-vector/circle-line-simple-design-logo-600nw-2174926871.jpg",
      services: "Electrical, Installation",
      location: "Los Angeles, CA",
      charges: "$150 - $700",
      status: "Pending",
      setting: { key: 2, status: "Pending" },
    },
    {
      key: 3,
      id: 3,
      name: "Home Solutions",
      file: "https://www.shutterstock.com/image-vector/circle-line-simple-design-logo-600nw-2174926871.jpg",
      services: "HVAC, Repair",
      location: "Chicago, IL",
      charges: "$200 - $1000",
      status: "Declined",
      setting: { key: 3, status: "Declined" },
    },
    {
      key: 4,
      id: 4,
      name: "Green Landscaping",
      file: "https://www.shutterstock.com/image-vector/circle-line-simple-design-logo-600nw-2174926871.jpg",
      services: "Landscaping, Maintenance",
      location: "Miami, FL",
      charges: "$50 - $300",
      status: "Active",
      setting: { key: 4, status: "Active" },
    },
    {
      key: 5,
      id: 5,
      name: "Top Notch Cleaning",
      file: "https://www.shutterstock.com/image-vector/circle-line-simple-design-logo-600nw-2174926871.jpg",
      services: "Cleaning, Sanitization",
      location: "Austin, TX",
      charges: "$80 - $400",
      status: "Pending",
      setting: { key: 5, status: "Pending" },
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedTableItem(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const tabs = [
    {
      key: "1",
      label: "List of Service Professional",
      children: (
        <ListServiceProfessionalTab
          data={data}
          columns={columns}
          rowSelection={rowSelection}
        />
      ),
    },
    {
      key: "2",
      label: "Find Local Professional",
      children: (
        <FindLocalProfessionalTab
          professionals={professionalsdata}
          totalProfessionalsCount={localServiceProCount}
          filters={localServiceProFilters}
          setFilters={setLocalServiceProFilters}
          setUpdate={setUpdate}
          isLoading={localServiceProLoading}
        />
      ),
    },
  ];

  return (
    <>
      {openModal === true && (
        <FormModalLayout
          title={"Select Reason for Invite"}
          isOpen={openModal}
          onClose={onCloseModal}
          width={"600px"}
        >
          <InviteProfessionalModal company={companyId} />
        </FormModalLayout>
      )}

      <div className="container-fluid bg-white p-3">
        {activeTab && (
          <Component.CustomTabs
            items={tabs}
            defaultActiveKey={activeTab}
            onChange={onChange}
          />
        )}
      </div>
    </>
  );
};

export default AllServiceProfessional;
