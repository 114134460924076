import { AuthLayout } from "Components/GeneralComponents";
import TempModal from "Modals/TempModal/TempModal";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SetPasswordForm from "./SetPasswordForm";

const SetPassword = () => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate()

  const handleRedirect = () => {
    // localStorage.removeItem("role");
    // localStorage.removeItem("token");
    // localStorage.removeItem("email");
    // window.location.replace("/landlord/login");
    navigate("/landlord/login", { replace: true })
  };

  return (
    <>
      {openModal && (
        <TempModal
          onClose={() => setOpenModal(false)}
          heading={"Password Updated"}
          title={"Password Updated Successfully"}
          success="Login"
          handler={handleRedirect}
        />
      )}

      <AuthLayout>
        <SetPasswordForm
          openSuccessModal={() => setOpenModal(true)}
        />
      </AuthLayout>
    </>
  );
};

export default SetPassword;
