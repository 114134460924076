// import React, { useState } from "react";
// import FilterIcon from "assets/filter.png";
// import SearchIcon from "assets/MagnifyingGlass.png";
// import { Slider, ConfigProvider } from "antd";
// import * as Component from "Components/GeneralComponents";
// import { usaStates } from "utils";

// const LocalServiceProfessionalSearch = ({ filters, setFilters, setUpdate }) => {
//   const [
//     localServiceProfessionalFilterDropdown,
//     setLocalServiceProfessionalFilterDropdown,
//   ] = useState(false);
//   const [sliderValue, setSliderValue] = useState([0, 1000]);
//   const [isReset, setIsReset] = useState(false);
//   const locationOptions = [
//     { value: 5, label: "Near me" },
//     { value: 15, label: "Within 15 miles" },
//     { value: 30, label: "Within 30 miles" },
//     { value: 50, label: "Within 50 miles" },
//   ];
//   const [form, setForm] = useState({
//     service: "",
//     state: "",
//     distanceInMiles: filters.distanceInMiles || "",
//     minCharges: filters.minValue || 0,
//     maxCharges: filters.maxValue || 1000,
//   });

//   const handleChange = (fieldName, value) => {
//     setForm((prev) => ({
//       ...prev,
//       [fieldName]: value,
//     }));
//   };

//   function capitalizeFirstLetter(string) {
//     if (!string) return '';
//     return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
//   }

//   const handleApplyFilters = () => {
//     setFilters({
//       ...filters,
//       service: capitalizeFirstLetter(form.service),
//       state: form.state,
//       distanceInMiles: form.distanceInMiles,
//       minCharges: sliderValue[0],
//       maxCharges: sliderValue[1],
//     });
//     setUpdate((prev) => !prev);
//     setIsReset(true);
//     setLocalServiceProfessionalFilterDropdown(false); // Close the dropdown after applying filters
//   };

//   const resetFilters = () => {
//     setForm({
//       service: "",
//       state: "",
//       distanceInMiles: "",
//       minCharges: 0,
//       maxCharges: 1000,
//     });
//     setSliderValue([0, 1000]);
//     setFilters({
//       ...filters,
//       distanceInMiles: "",
//       minCharges: "",
//       maxCharges: "",
//       state: "",
//       service: "",
//       page: 1,
//       limit: 10,
//     });
//     setUpdate((prev) => !prev);
//     setIsReset(false);
//   };

//   return (
//     <div className="row">
//       <div className="col-md-12">
//         <div className="local-search-bar-container d-flex align-items-center gap-3">
//           <div className="local-search-bar flex-grow-2 position-relative">
//             <input
//               placeholder="What service you are looking for?"
//               type="text"
//               className="form-control local-input"
//               value={form.service}
//               onChange={(e) => handleChange("service", e.target.value)}
//             />
//             <div className="local-icon">
//               <img src={SearchIcon} alt="Search" />
//             </div>
//           </div>
//           <div className="local-search-bar-location flex-grow-1 position-relative">
//             <Component.SelectField
//               name={"state"}
//               required={true}
//               placeholder={"Select Location"}
//               value={form.state}
//               options={usaStates.map((e) => {
//                 return { value: e.name, label: e.name };
//               })}
//               handler={(e) => handleChange("state", e)}
//             />
//           </div>
//           <div className="local-search-bar-btn">
//             <button className="search-btn" onClick={handleApplyFilters}>Search</button>
//           </div>
//           <button
//             onClick={resetFilters}
//             className="task-filter-dropdown-btn"
//             disabled={!isReset}
//           >
//             Reset
//           </button>
//           <div className="position-relative">
//             <button
//               onClick={() =>
//                 setLocalServiceProfessionalFilterDropdown(
//                   !localServiceProfessionalFilterDropdown
//                 )
//               }
//               className="filter-btn d-flex align-items-center"
//             >
//               <img src={FilterIcon} alt="Filter" /> Filter
//             </button>
//             {localServiceProfessionalFilterDropdown && (
//               <div className="filter-dropdown position-absolute">
//                 <div className="filter-checkbox-container">
//                   <p className="task-filter-dropdown-title mb-2">FILTER BY</p>
//                   <p className="local-service-professional-filter-dropdown-sub-title mb-3">
//                     Location
//                   </p>
//                   <Component.RadioGroupField
//                     value={form.distanceInMiles}
//                     handler={(e) => handleChange("distanceInMiles", e)}
//                     options={locationOptions}
//                     gap={2}
//                   />
//                   <p className="local-service-professional-filter-dropdown-sub-title mb-1 mt-3">
//                     Charges
//                   </p>
//                   <ConfigProvider
//                     theme={{
//                       components: {
//                         Slider: {
//                           colorPrimary: "#EF6B3E",
//                           colorPrimaryBorder: "#EF6B3E",
//                           colorPrimaryBorderHover: "#EF6B3E",
//                           colorBgElevated: "#EF6B3E",
//                         },
//                       },
//                     }}
//                   >
//                     <Slider
//                       style={{ width: "95%" }}
//                       onChange={setSliderValue}
//                       range={{ draggableTrack: true }}
//                       max={1000}
//                       min={0}
//                       value={sliderValue}
//                     />
//                   </ConfigProvider>
//                   <div className="d-flex justify-content-between w-100">
//                     <span className="local-service-professional-filter-dropdown-range-picker-text">
//                       ${sliderValue[0].toLocaleString()}
//                     </span>
//                     <span>${sliderValue[1].toLocaleString()}</span>
//                   </div>
//                   <button
//                     onClick={handleApplyFilters}
//                     className="task-filter-dropdown-btn w-100 mt-2"
//                   >
//                     Apply
//                   </button>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LocalServiceProfessionalSearch;

import React, { useState } from "react";
import FilterIcon from "assets/filter.png";
import SearchIcon from "assets/MagnifyingGlass.png";
import { Slider, ConfigProvider } from "antd";
import * as Component from "Components/GeneralComponents";
import { usaStates } from "utils";

const LocalServiceProfessionalSearch = ({ filters, setFilters, setUpdate }) => {
  const [
    localServiceProfessionalFilterDropdown,
    setLocalServiceProfessionalFilterDropdown,
  ] = useState(false);
  const [sliderValue, setSliderValue] = useState([0, 1000]);
  const [isReset, setIsReset] = useState(false);
  const locationOptions = [
    { value: 5, label: "Near me" },
    { value: 15, label: "Within 15 miles" },
    { value: 30, label: "Within 30 miles" },
    { value: 50, label: "Within 50 miles" },
  ];
  const [form, setForm] = useState({
    service: "",
    state: "",
    distanceInMiles: filters.distanceInMiles || "",
    minCharges: filters.minValue || 0,
    maxCharges: filters.maxValue || 1000,
  });

  const handleChange = (fieldName, value) => {
    setForm((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  function capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const handleApplyFilters = () => {
    setFilters({
      ...filters,
      service: capitalizeFirstLetter(form.service),
      state: form.state,
      distanceInMiles: form.distanceInMiles,
      minCharges: sliderValue[0],
      maxCharges: sliderValue[1],
    });
    setUpdate((prev) => !prev);
    setIsReset(true);
    setLocalServiceProfessionalFilterDropdown(false); // Close the dropdown after applying filters
  };

  const resetFilters = () => {
    setForm({
      service: "",
      state: "",
      distanceInMiles: "",
      minCharges: 0,
      maxCharges: 1000,
    });
    setSliderValue([0, 1000]);
    setFilters({
      ...filters,
      distanceInMiles: "",
      minCharges: "",
      maxCharges: "",
      state: "",
      service: "",
      page: 1,
      limit: 10,
    });
    setUpdate((prev) => !prev);
    setIsReset(false);
  };

  return (
    <div>
      <div className="row g-3 align-items-center">
        <div className="col-12 col-md-6 col-lg-4">
          <div className="position-relative">
            <input
              placeholder="What service are you looking for?"
              type="text"
              className="form-control"
              value={form.service}
              onChange={(e) => handleChange("service", e.target.value)}
            />
            <div className="position-absolute top-50 end-0 translate-middle-y pe-3">
              <img src={SearchIcon} alt="Search" />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 col-lg-3">
          <Component.SelectField
            name={"state"}
            required={true}
            placeholder={"Select Location"}
            value={form.state}
            options={usaStates.map((e) => {
              return { value: e.name, label: e.name };
            })}
            handler={(e) => handleChange("state", e)}
          />
        </div>
        <div className="col-12 col-md-2 d-flex justify-content-md-end">
          <button className="search-btn w-100" onClick={handleApplyFilters}>
            Search
          </button>
        </div>
        <div className="col-md-auto">
          <div className="d-flex justify-content-between gap-2">
            <div className=" col-md-auto">
              <button
                onClick={() =>
                  setLocalServiceProfessionalFilterDropdown(
                    !localServiceProfessionalFilterDropdown
                  )
                }
                className="filter-btn flex-grow-1 d-flex align-items-center justify-content-center"
              >
                <img src={FilterIcon} alt="Filter" className="me-2" /> Filter
              </button>
              {localServiceProfessionalFilterDropdown && (
                <div className="position-absolute bg-white border p-3 mt-3 rounded" style={{maxWidth: "250px", zIndex: "9999"}}>
                  <div className="mb-3">
                    <p className="fw-bold mb-2">FILTER BY</p>
                    <p className="mb-2">Location</p>
                    <Component.RadioGroupField
                      value={form.distanceInMiles}
                      handler={(e) => handleChange("distanceInMiles", e)}
                      options={locationOptions}
                      gap={2}
                    />
                  </div>
                  <div className="mb-3">
                    <p className="mb-2">Charges</p>
                    <ConfigProvider
                      theme={{
                        components: {
                          Slider: {
                            colorPrimary: "#EF6B3E",
                            colorPrimaryBorder: "#EF6B3E",
                            colorPrimaryBorderHover: "#EF6B3E",
                            colorBgElevated: "#EF6B3E",
                          },
                        },
                      }}
                    >
                      <Slider
                        onChange={setSliderValue}
                        range={{ draggableTrack: true }}
                        max={1000}
                        min={0}
                        value={sliderValue}
                      />
                    </ConfigProvider>
                    <div className="d-flex justify-content-between">
                      <span>${sliderValue[0].toLocaleString()}</span>
                      <span>${sliderValue[1].toLocaleString()}</span>
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-between align-items-center gap-2 mb-3"
                    style={{ maxWidth: "200px" }}
                  >
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        value={sliderValue[0]}
                        onChange={(e) =>
                          setSliderValue([
                            Math.max(0, +e.target.value),
                            sliderValue[1],
                          ])
                        }
                        placeholder="Min"
                      />
                    </div>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        value={sliderValue[1]}
                        onChange={(e) =>
                          setSliderValue([
                            sliderValue[0],
                            Math.min(1000, +e.target.value),
                          ])
                        }
                        placeholder="Max"
                      />
                    </div>
                  </div>
                  <button
                    onClick={handleApplyFilters}
                    className="task-filter-dropdown-btn w-100"
                  >
                    Apply
                  </button>
                </div>
              )}
            </div>
            <button
              onClick={resetFilters}
              className="task-filter-dropdown-btn flex-grow-1"
              disabled={!isReset}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalServiceProfessionalSearch;
