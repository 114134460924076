import axios from "axios";
import config from "Helpers/config.js";

const getLandlordConversations = async (id, search, page) => {
  try {
    const response = await axios.get(
      `${config["baseUrl"]}/api/landlord/conversations/${id}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { search: "", page: page },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getLandlordMessages = async (id, page) => {
  console.log("id: ", id)
  console.log("page: ", page)
  try {
    const response = await axios.get(
      `${config["baseUrl"]}/api/landlord/messages/${id}/${page}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const addOrRemoveArchive = async (id) => {
  try {
    const response = await axios.put(
      `${config["baseUrl"]}/api/landlord/conversation/archive/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getLandlordArchive = async () => {
  try {
    const response = await axios.get(
      `${config["baseUrl"]}/api/landlord/archived/conversations/1`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export { getLandlordConversations, getLandlordMessages, addOrRemoveArchive, getLandlordArchive };
