import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CustomTabs } from "Components/GeneralComponents";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import TenantApplicationOverview from "./TenantApplicationOverview";
import TenantApplicationFiles from "./TenantApplicationFiles";
import TenantApplicationNotes from "./TenantApplicationNotes";
import TenantApplicationReports from "./TenantApplicationReports";

function TenantApplicationDetails() {
  const [activeTab, setActiveTab] = useState("");
  const { id, tab } = UseUrlParamsHook();

  console.log("TenantApplicationDetails:::: ", id);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const items = [
    {
      key: "1",
      label: "Overview",
      children: <TenantApplicationOverview />,
    },
    { key: "2", label: "Reports", children: <TenantApplicationReports /> },
    {
      key: "3",
      label: "Notes",
      children: <TenantApplicationNotes id={id} category="tenantApplication" />,
    },
    {
      key: "4",
      label: "Files",
      children: <TenantApplicationFiles id={id} category="tenantApplication" />,
    },
  ];
  useEffect(() => {
    setActiveTab(tab || "1");
  }, [tab]);
  const onChange = (key) => {
    setActiveTab(key);
    searchParams.set("tab", key);
    navigate({ search: searchParams.toString() }, { replace: true });
  };
  return (
    <div className="container-fluid bg-white p-3">
      {activeTab && (
        // <div className="container-fluid bg-white p-3 d-flex justify-content-center ">
        <CustomTabs
          items={items}
          defaultActiveKey={activeTab}
          onChange={onChange}
        />
        //   </div>
      )}
    </div>
  );
}

export default TenantApplicationDetails;
