import React, { useEffect, useState, useRef } from "react";
import searchIcon from "assets/search.png";
import FilterIcon from "assets/filter.png";
import { Select, ConfigProvider } from "antd";
import calendarIcon from "assets/calendar.png";
import NotFound from "assets/not-found-img.png";
import viewIcon from "assets/Icon.png";
import editIcon from "assets/edit-05.png";
import completeIcon from "assets/calendar-check-01.png";
import deleteIcon from "assets/trash-01.png";
import settingIcon from "assets/three-dots.png";
import { useNavigate, Link } from "react-router-dom";
import { Table, DatePicker, Space } from "antd";
import chevronIcon from "assets/chevron-down.png";
import calendarIconDate from "assets/calendar-icon.png";
import trashIconWhite from "assets/trash-icon-white.png";
import UseGetHook from "Hooks/UseGetHook";
import { useSelector } from "react-redux";
import CustomTable from "Components/GeneralComponents/CustomTable";
import {
  fetchReports,
  deleteReportById,
  bulkDeleteReports,
} from "network/landlord/reports";
import { CSVLink } from "react-csv";
import { usePDF } from "react-to-pdf";
import * as Component from "Components/GeneralComponents";
import person from "assets/person.jpg";
import { VerticalElipsisIcon } from "assets/icons";
import { message } from "antd";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { RangePicker } = DatePicker;
let calendar;
calendar = (
  <>
    <img src={calendarIconDate} alt="" />
  </>
);

const TaskReports = () => {
  // States start
  // States
  const [data, setData] = useState([]); // State for table data
  const [loading, setLoading] = useState(true); // Loading state
  const [selectedTableItem, setSelectedTableItem] = useState([]);
  const [report, setReport] = useState([]); // State for fetched report
  const [csvData, setCsvData] = useState([]); // State for
  const [deleteId, setDeleteId] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [openBulkDeleteModal, setOpenBulkDeleteModal] = useState(false);
  const [bulkDeleteLoading, setBulkDeleteLoading] = useState(false);

  const [selectionType, setSelectionType] = useState("checkbox");
  const [exportDropdown, setExportDropdown] = useState(false);
  const [taskFilterDropdown, settaskFilterDropdown] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [key, setKey] = useState([]);
  const csvLinkRef = useRef(null); // Reference to the CSVLink component
  const { toPDF, targetRef } = usePDF({ filename: "Task Reports.pdf" });

  const search = useSelector((state) => {
    return state.Search.value;
  });
  const fromDate = useSelector((state) => {
    return state.FilterValue.fromDate;
  });
  const toDate = useSelector((state) => {
    return state.FilterValue.toDate;
  });
  const exportType = useSelector((state) => state.reports.exportType);


  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const onOpenBulkDeleteModal = () => {
    setOpenBulkDeleteModal(true);
  };
  const onCloseBulkDeleteModal = () => {
    setOpenBulkDeleteModal(false);
  };

  const handleDeleteRecord = async (recordId) => {
    setDeleteLoading(true);
    const response = await deleteReportById(recordId);

    console.log("Delte Respsone: ", response);
    if (response.apiCallStatus === "success") {
      message.success(response.message.message);
      setDeleteId(null);
      fetchData();
    } else {
      message.error(response);
    }
    setOpenDeleteModal(false);
    setDeleteLoading(false);
  };

  const handleBulkDelete = async () => {
    setBulkDeleteLoading(true);
    const response = await bulkDeleteReports(selectedTableItem);

    console.log("Delte Respsone: ", response);
    if (response.apiCallStatus === "success") {
      message.success(response.message.message);
      setSelectedTableItem([]);
      fetchData();
    } else {
      message.error(response);
    }
    setOpenBulkDeleteModal(false);
    setBulkDeleteLoading(false);
  };

  const handleIconClick = (result) => {
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const navigate = useNavigate();
  // Modal Function
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  // Fetch and process report data
  const fetchData = async () => {
    try {
      setLoading(true); // Set loading before fetching
      const reportData = await fetchReports(
        `task?fromDate=${fromDate}&toDate=${toDate}&search=${search}`
      );
      setReport(reportData); // Save fetched data to state
    } catch (err) {
      console.error("Error fetching report data:", err);
    } finally {
      setLoading(false); // Stop loading after fetch
    }
  };

  useEffect(() => {
    const handleExport = async () => {
      if (exportType === "excel") {
        const toastId = toast.loading("Preparing your excel file...", {
          autoClose: false,
        });

        setTimeout(() => {
          if (csvLinkRef.current) {
            csvLinkRef.current.link.click();
            toast.update(toastId, {
              render: "CSV downloaded successfully!",
              type: "success",
              isLoading: false,
              autoClose: 3000,
            });
          }
        }, 2000);
      } else if (exportType === "pdf") {
        const toastId = toast.loading("Generating your PDF...");

        try {
          await toPDF();
          toast.update(toastId, {
            render: "PDF downloaded successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        } catch (error) {
          toast.update(toastId, {
            render: "Failed to generate PDF. Please try again.",
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
          console.error("PDF generation error:", error);
        }
      }
    };

    handleExport();
  }, [exportType]);
  // Process and filter data for table
  useEffect(() => {
    if (report?.length) {
      const filteredData = report.map((e, index) => ({
        key: e._id,
        title: e.data?.title,
        due: new Date(e.data?.dueDate).toLocaleDateString(),
        related: e.data?.property?.title,
        status: e.data?.status,
        priority: e.data?.priority,
        assignees: [
          ...(e.data?.assignedToVendor || []),
          ...(e.data?.assignedToUser || []),
        ],
      }));
      setData(filteredData);

      const filterDataForCSV = report.map((e, index) => ({
        key: index.toString(),
        title: e.data?.title,
        due: new Date(e.data?.dueDate).toLocaleDateString(),
        related: e.data?.property?.title,
        status: e.data?.status,
        priority: e.data?.priority,
        assignees: [
          ...(e.data?.assignedToVendor || []),
          ...(e.data?.assignedToUser || []),
        ]
          .map((assignee) => `${assignee.firstName} ${assignee.lastName}`)
          .join(", "), // Combine names with a comma
      }));

      setCsvData(filterDataForCSV); // Save filtered data for CSV export
    } else {
      setData([]); // Clear data if report is empty
    }
  }, [report, search]);

  // Fetch data on component mount and when filters change
  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, search]);

  // console.log(data, "task report success");
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      render: (text) => (
        <>
          {/* <Link to="/task-report-detail"> */}
            <span className="property-table-name-text">{text}</span>
          {/* </Link> */}
        </>
      ),
    },
    {
      title: "Assigned To",
      dataIndex: "assignees",
      render: (text, assignees) => (
        <>
          {" "}
          <Component.IconList
            properties={assignees.assignees}
            defaultimage={person}
            name={true}
          />
        </>
      ),
    },
    {
      title: "Due At",
      dataIndex: "due",
      render: (text, task) => (
        <div className="assign-date-container">
          <div
            className={`priority-text ${
              task?.priority === "High"
                ? "bg-error"
                : task?.priority === "Low"
                ? "bg-grey"
                : task?.priority === "Medium"
                ? "bg-yellow"
                : task?.priority === "Very High"
                ? "bg-error"
                : ""
            }`}
          >
            <span>{`${task?.priority} Priority`}</span>
          </div>
          <br />
          <span className="assign-to-date">
            {new Date(task?.due).toLocaleDateString()}
          </span>
        </div>
      ),
    },
    {
      title: "Related To",
      dataIndex: "related",
      render: (text) => <span className="related-text">{text}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, status) => (
        <>
          {text === "In Progress" && (
            <span className="table-status-bar ">In Progress</span>
          )}
          {text === "Completed" && (
            <span className="table-complete-status-bar ">Completed</span>
          )}
          {text === "Not Started" && (
            <span className="table-not-started-status-bar ">Not Started</span>
          )}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          {" "}
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDeleteRecord: () => {
                  onOpenDeleteModal();
                  setDeleteId(setting.key);
                },
              }}
              fields={{
                delete: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  let dropdownIcon;
  dropdownIcon = (
    <>
      <img src={chevronIcon} alt="" />
    </>
  );
  return (
    <>
      <ToastContainer />

      {
        <CSVLink
          data={csvData}
          ref={csvLinkRef}
          filename="Task Reports.csv"
          style={{ display: "none" }} // Hide the link
        />
      }
      {
        <Component.DeleteModal
          isOpen={openDeleteModal}
          onClose={onCloseDeleteModal}
          message={"Do you confirm to delete this report record?"}
          deleteCall={() => handleDeleteRecord(deleteId)}
          isLoading={deleteLoading}
        />
      }
      {
        <Component.DeleteModal
          isOpen={openBulkDeleteModal}
          onClose={onCloseBulkDeleteModal}
          message={`Do you confirm to delete ${selectedTableItem.length} report record(s)?`}
          deleteCall={() => handleBulkDelete()}
          isLoading={bulkDeleteLoading}
        />
      }
      <div className="container-fluid">
        {selectedTableItem.length >= 1 && (
          <div className="table-delete-icon mt-3">
            <button
              onClick={onOpenBulkDeleteModal}
              className="table-delete-btn next-btn-main"
            >
              <img src={trashIconWhite} />
              Delete
            </button>
          </div>
        )}
        <div className="report-table-container mt-3" ref={targetRef}>
          <CustomTable
            columns={columns}
            dataSource={data}
            loading={loading}
            rowSelection={{
              selectedRowKeys: selectedTableItem,
              onChange: setSelectedTableItem,
            }}
            pagination={true}
            //  onPageChange={handlePageChange}
            //  total={totalRecords} // Pass total records for pagination
            //  currentPage={currentPage}
            //  setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
};

export default TaskReports;
