import axios from "axios";
import config from "Helpers/config.js";

const resetPassword = async (payload) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/auth/tenant/resetpassword`,
      payload,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const tenantsignin = async (payload) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/auth/tenant/login`,
      payload,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const tenantSignup = async (payload) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/auth/tenant/signup`,
      payload,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const googleAuthUrl = async () => {
  window.location.href = `${config.baseUrl}/api/auth/google/tenant`;
};

const tenantGoogleRegister = async (payload) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/auth/tenant/register/google`,
      payload,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteTenant = async () => {
  try {
    const response = await axios.delete(`${config.baseUrl}/api/auth/tenant/delete`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export { resetPassword, tenantsignin, tenantSignup, googleAuthUrl, tenantGoogleRegister, deleteTenant };
