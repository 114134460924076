import axios from "axios";
import config from "Helpers/config.js";

const getServiceProfessinals = async (filters) => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/companies`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: filters,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getServiceProfessionalById = async (id) => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/compFany/${id}`, {
      headers: {
        // Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });
    // Check if the API call was successful
    if (response.data?.apiCallStatus === "success") {
      return response.data; // Return the full response data
    } else {
      // Return an error message if the status is not success
      return {
        apiCallStatus: "error",
        message: response.data?.message || "Failed to fetch details",
      };
    }
  } catch (error) {
    console.error("Error fetching professional details:", error);
    // Return the error response data
    return {
      apiCallStatus: "error",
      message: error.response?.data?.message || "An error occurred",
    };
  }
};

const sendInvite = async (form) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/serviceProviders/sendInvite`,
      form,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const terminateService = async (id) => {
  try {
    const response = await axios.delete(
      `${config.baseUrl}/api/serviceProviders/terminateService/${id}`, // Include id in the URL params
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
  getServiceProfessinals,
  getServiceProfessionalById,
  sendInvite,
  terminateService,
};
