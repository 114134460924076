import React from 'react';
import TenantApplicationDetails from 'Components/TenantApplicationDetails'
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";

const TenantApplicationsDetailPage = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Tenant Application Details"}
        pop={true}
      />
      <TenantApplicationDetails />
    </div>
  );
};

export default TenantApplicationsDetailPage;
