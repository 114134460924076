import React, { useState, useEffect } from "react";
import { Table, ConfigProvider } from "antd";
import Loader from "Helpers/Loader";

const MyCutomTable = ({
  loading = false,
  pagination = false,
  rowSelection,
  columns,
  dataSource,
  onPageChange,
  currentPage,
  setCurrentPage,
  total, // total records for pagination
  taskType
}) => {
  // Manage current page and page size state
  // const [currentPage, setCurrentPage] = useState(crntPage);
  const [pageSize, setPageSize] = useState(10);

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    setCurrentPage(current);
    setPageSize(pageSize);
    if (onPageChange) {
      onPageChange(current, pageSize);
    }
  };
 // Reset page number when taskType changes
 useEffect(() => {
    handleTableChange({ current: 1, pageSize })
  }, [taskType]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ConfigProvider
          theme={{
            components: {
              Table: {
                colorTextHeading: "#667085",
                colorText: "#667085",
                fontSize: 14,
                fontWeightStrong: 500,
                fontFamily: "Montserrat",
              },
              Pagination: {
                itemActiveBg: "#EF6B3E",
                sizeChangerBg: "#EF6B3E", 
                sizeChangerHoverBg: "red", 

              },

              

              Checkbox: {
                colorPrimary: "#EF6B3E",
                colorPrimaryHover: "#EF6B3E",
              },
            },
          }}
        >
          <Table
            pagination={
              pagination
                ? {
                    current: currentPage,
                    pageSize: pageSize,
                    total: total,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => handleTableChange({ current: page, pageSize }),
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  
                  }
                : false
            }
            className="scroll-responsive-tablet"
            rowClassName={(record, index) =>
              index % 2 === 0 ? "bg-gray-50" : "bg-white"
            }
            rowSelection={rowSelection}
            columns={columns}
            dataSource={dataSource}
          />
        </ConfigProvider>
      )}
    </>
  );
};

export default MyCutomTable;
