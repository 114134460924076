import axios from "axios";
import config from "Helpers/config.js";

const addCompany = async (payload) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}/api/company`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getCompany = async (id) => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/company/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getCompanies = async (filters) => {
  try {
    const response = await axios.get(`${config.baseUrl}/api/company`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: filters,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export { addCompany, getCompany, getCompanies };
