import { useEffect, useRef, useState } from "react";
import active_dot from "assets/_Dot.png";
import offline_dot from "assets/_red-Dot.png";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getLandlordMessages,
  getLandlordConversations,
  getLandlordArchive,
  addOrRemoveArchive,
} from "network/landlord/chat";
import {
  getTenantMessages,
  getTenantConversations,
  getTenantArchive,
  addOrRemoveTenantArchive,
} from "network/tenant/chat";
import { Avatar, Tooltip } from "antd";
import {
  FirstCharacterIcon,
  RecordMenuPopup,
} from "Components/GeneralComponents";
import Loader from "Helpers/Loader";
import { VerticalElipsisIcon } from "assets/icons";

const ChatBody = ({
  archive,
  text,
  data,
  setOpenedChat,
  setIsRerender,
  landlordData,
}) => {
  // States start
  const scrollChatRef = useRef();
  const { id } = UseUrlParamsHook();
  const [page, setPage] = useState(1); // Tracks the current page
  const [hasMore, setHasMore] = useState(true); // To stop fetching if no more data
  const [loadingMore, setLoadingMore] = useState(false); // Prevent redundant API calls
  const chatContainerRef = useRef(null);
  const [chatPerson, setChatPerson] = useState(
    data
      ? data.tenant
        ? data.tenant
        : data.landlord
      : {
          firstName: "",
          lastName: "",
          profileImage: "",
          status: false,
        }
  );
  const [textMessage, settextMessage] = useState("");
  const [read, setread] = useState(true);
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [setting, setSetting] = useState(false);
  const role = localStorage.getItem("role");
  let prevSenderId = null;
  const navigate = useNavigate();
  // States end

  const socket = useSelector((state) => {
    return state.Chat.socket;
  });

  const fetchConversations = (pageNumber)=>{
    console.log("userChat")
    // setIsLoading(true);
    if (archive) {
      if (role === "landlord" || role === "user") {
        console.log("Landlord Archive");
        getLandlordArchive().then((res) => {
          // setIsLoading(false);
          if (res.apiCallStatus === "success") {
            const chatPerson = res.message.filter((e) => e.id === id)[0];
            setChatPerson(
              chatPerson?.tenant ? chatPerson?.tenant : chatPerson?.landlord
            );
          }
        });
      } else {
        console.log("Tenant Archive");
        getTenantArchive().then((res) => {
          // setIsLoading(false);
          if (res.apiCallStatus === "success") {
            const chatPerson = res.message.filter((e) => e.id === id)[0];
            setChatPerson(
              chatPerson?.tenant ? chatPerson?.tenant : chatPerson?.landlord
            );
          }
        });
      }
    } else {
      if (role === "landlord" || role === "user") {
        getLandlordConversations(1, "", pageNumber).then((res) => {
          // setIsLoading(false);
          if (res.apiCallStatus === "success") {
            // console.log("Chat Id ========>", id);
            const chatPerson = res.message.filter((e) => e.id === id)[0];
            // console.log("Chat Person Data ========>", chatPerson);
            setChatPerson(
              chatPerson?.tenant ? chatPerson?.tenant : chatPerson?.landlord
            );
          }
        });
      } else {
        getTenantConversations(1, "").then((res) => {
          // setIsLoading(false);
          if (res.apiCallStatus === "success") {
            const chatPerson = res.message.filter((e) => e.id === id)[0];
            setChatPerson(
              chatPerson?.tenant ? chatPerson?.tenant : chatPerson?.landlord
            );
          }
        });
      }
    }
  }
  useEffect(() => {
    fetchConversations(page)
  }, [data]);

  const fetchMessages = (pageNumber)=>{
    if (socket && id) {
      socket.emit("lastReadMessage", { id: id });
    }
    setIsLoading(true);
    if (role === "landlord" || role === "user") {
      getLandlordMessages(id, pageNumber).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          setChatMessages(res.message);
        }
      });
    } else {
      getTenantMessages(id).then((res) => {
        setIsLoading(false);
        if (res.apiCallStatus === "success") {
          setChatMessages(res.message);
        }
      });
    }
  }
  useEffect(() => {
    fetchMessages(page);
  }, [id, page]);
  const handleScroll = () => {
    if (chatContainerRef.current.scrollTop === 0 && hasMore && !loadingMore) {
      // Fetch previous messages when scrolled to the top
      setPage((prevPage) => prevPage + 1);
      fetchMessages(page + 1);
    }
  };

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
      return () => {
        chatContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [hasMore, loadingMore]);

  // Scroll to the bottom of the chat
  useEffect(() => {
    if (!scrollChatRef.current) return;
    const scrollHeight = scrollChatRef.current.scrollHeight;

    scrollChatRef.current.scrollTop = scrollHeight;
  }, [chatMessages]);

  useEffect(() => {
    if (socket) {
      socket.emit("join", { id: id });
      socket.on("chatMessage", (data) => {
        console.log("Chat Message Received", data);
        data._id = data.id;
        setChatMessages((prevMessage) => [...prevMessage, data]);
        socket.emit("readMessage", {
          send_by_landlord: data.send_by_landlord,
          messageId: data.id,
          id: id,
        });
      });
      socket.on("readMessage", (data) => {
        let mm = [...chatMessages];
        // mm.filter((e) => e._id === data.id)[0].unread = false;
        const message = mm.find((e) => e._id === data.id);
        if (message) {
          message.unread = false;
        }
        setChatMessages(mm);
      });
    }
    return () => {
      socket?.off("chatMessage");
      socket?.off("readMessage");
    };
  }, [chatMessages, read]);

  const SendMessage = () => {
    console.log("Send Message to ", {
      content: textMessage,
      conversation: id,
      role: role,
      user: role == "user" ? localStorage.getItem("user_id") : null,
    });
    socket.emit("chatMessage", {
      content: textMessage,
      conversation: id,
      role: localStorage.getItem("role"),
      user: localStorage.getItem("user_id"),
    });
    settextMessage("");
  };

  const AddOrRemoveArchieve = () => {
    if (role === "landlord" || role === "user") {
      addOrRemoveArchive(id).then((res) => {
        if (res.apiCallStatus === "success") {
          if (!archive) {
            setOpenedChat(null);
            setIsRerender((prev) => !prev);
          }
          navigate("/user-chat");
        }
      });
    } else {
      addOrRemoveTenantArchive(id).then((res) => {
        if (res.apiCallStatus === "success") {
          if (!archive) {
            setOpenedChat(null);
            setIsRerender((prev) => !prev);
          }
          navigate("/user-chat");
        }
      });
    }
  };

  let seen = chatMessages.map((e) => e._id).slice(-1)[0];

  const IsUserConsecutive = (currentMessage, prevMessage) => {
    if (!prevMessage) return false;

    const currentUser = currentMessage.user
      ? currentMessage.user.id
      : currentMessage.send_by_landlord
      ? "landlord"
      : "tenant";
    const prevUser = prevMessage.user
      ? prevMessage.user.id
      : prevMessage.send_by_landlord
      ? "landlord"
      : "tenant";

    return currentUser === prevUser;
  };

  return (
    <div className="position-absolute left-0 top-0 w-100 h-100">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="d-flex flex-column h-100">
          <div className="chat-body-header d-flex justify-content-between align-items-center border-bottom">
            <div className="chat-body-header-left d-flex align-items-center justify-content-center gap-3">
              <div className="chat-body-header-img">
                {chatPerson?.profileImage ? (
                  <Avatar
                    src={chatPerson?.profileImage}
                    size="large"
                    style={{ backgroundColor: "" }}
                  />
                ) : (
                  chatPerson.firstName && (
                    <FirstCharacterIcon name={chatPerson?.firstName[0]} />
                  )
                )}
                {text}
              </div>
              <div className="chat-body-header-text">
                <p className="mb-0 text-dark fw-medium">
                  {chatPerson?.firstName + " " + chatPerson?.lastName}
                </p>
                <div>
                  {chatPerson.status === true ? (
                    <p className="mb-0 online-text">
                      {" "}
                      <img
                        src={active_dot}
                        alt=""
                      />{" "}
                      Online
                    </p>
                  ) : (
                    <p className="mb-0 offline-text">
                      {" "}
                      <img
                        src={offline_dot}
                        alt=""
                      />{" "}
                      Offline
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="chat-body-header-right">
              {/* <button
                className={archive ? "d-none" : "move-to-archive-btn"}
                onClick={AddOrRemoveArchieve}
              >
                Move to Archieve
              </button> */}
              <div
                className="position-relative cursor text-center"
                onClick={() => setSetting(!setting)}>
                <VerticalElipsisIcon
                  width={23}
                  height={23}
                />
                {archive ? (
                  <RecordMenuPopup
                    isOpen={setting}
                    onClose={() => {}}
                    handler={{
                      unarchive: () => {
                        AddOrRemoveArchieve();
                      },
                    }}
                    fields={{
                      unarchive: true,
                    }}
                  />
                ) : (
                  <RecordMenuPopup
                    isOpen={setting}
                    onClose={() => {}}
                    handler={{
                      archive: () => {
                        AddOrRemoveArchieve();
                      },
                    }}
                    fields={{
                      archive: true,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            ref={scrollChatRef}
            className="chat-body-container">
            <div className="chat-body mt-5" ref={chatContainerRef}>
              {chatMessages && chatMessages.length > 0
                ? chatMessages.map((data, index) => {
                    const isConsecutive =
                      prevSenderId === data.send_by_landlord; // Check if the current sender is the same as the previous one
                    prevSenderId = data.send_by_landlord;

                    const prevMessage =
                      index > 0 ? chatMessages[index - 1] : null;
                    const isUserConsecutive = IsUserConsecutive(
                      data,
                      prevMessage
                    );

                    return role === "landlord" || role === "user" ? (
                      <div key={data.id}>
                        {data.send_by_landlord === false ? (
                          <>
                            {/* Tenan side messages */}
                            <div className="chat-message-body-outgoing d-flex justify-content-start align-items-center gap-2 mt-1">
                              {!isConsecutive ? (
                                chatPerson?.profileImage ? (
                                  <Avatar
                                    src={chatPerson.profileImage}
                                    size="large"
                                    style={{ backgroundColor: "" }}
                                  />
                                ) : (
                                  chatPerson.firstName && (
                                    <FirstCharacterIcon
                                      name={chatPerson?.firstName[0]}
                                    />
                                  )
                                )
                              ) : (
                                <div style={{ width: "40px" }}></div>
                              )}
                              <div className="position-relative">
                                <div className="message-incoming mt-1">
                                  {data.text}
                                  <div className="message-time normal-grey-text fw-medium">
                                    {moment(data.timestamp)
                                      .format("h:mm a")
                                      .toUpperCase()}
                                  </div>
                                </div>
                                {data._id === seen && data.unread === false && (
                                  <div className="seen-img position-absolute end-0">
                                    <span className="normal-grey-text seen-text">
                                      Seen
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {/* Landlord or user side messages */}
                            <div className="chat-message-body-incoming d-flex justify-content-end align-items-center gap-2 mt-1">
                              <div className="position-relative">
                                <div className="message-incoming">
                                  {data.text}
                                  <div className="message-time normal-grey-text fw-medium">
                                    {moment(data.timestamp)
                                      .format("h:mm a")
                                      .toUpperCase()}
                                  </div>
                                </div>
                              </div>
                              {!isUserConsecutive ? (
                                data.user !== null ? (
                                  data.user?.profileImage ? (
                                    <Tooltip
                                      title={
                                        data.user?.firstName +
                                        " " +
                                        data.user?.lastName
                                      }>
                                      <Avatar
                                        src={data.user?.profileImage}
                                        size="large"
                                        style={{ backgroundColor: "" }}
                                      />
                                    </Tooltip>
                                  ) : (
                                    data.user?.firstName && (
                                      <FirstCharacterIcon
                                        name={
                                          data.user?.firstName +
                                          " " +
                                          data.user?.lastName
                                        }
                                        tooltip={true}
                                      />
                                    )
                                  )
                                ) : landlordData?.profileImage ? (
                                  <Tooltip
                                    title={`${landlordData.firstName} ${landlordData.lastName}`}>
                                    <Avatar
                                      src={landlordData.profileImage}
                                      size="large"
                                      style={{ backgroundColor: "" }}
                                    />
                                  </Tooltip>
                                ) : (
                                  landlordData?.firstName && (
                                    <FirstCharacterIcon
                                      name={
                                        landlordData &&
                                        `${landlordData.firstName} ${landlordData.lastName}`
                                      }
                                      tooltip={true}
                                    />
                                  )
                                )
                              ) : (
                                <div style={{ width: "40px" }}></div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div key={data.id}>
                        {data.send_by_landlord === false ? (
                          <>
                            {/* Tenant side messages on Tenant Portal */}
                            <div className="chat-message-body-outgoing d-flex justify-content-end align-items-center gap-3">
                              <div className="position-relative">
                                <div className="message-incoming mt-1">
                                  {data.text}
                                  <div className="message-time normal-grey-text fw-medium">
                                    {moment(data.timestamp)
                                      .format("h:mm a")
                                      .toUpperCase()}
                                  </div>
                                </div>
                                {data._id === seen && data.unread === false && (
                                  <div className="seen-img position-absolute end-0">
                                    <span className="normal-grey-text seen-text">
                                      Seen
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {/* Landlord side messages on Tenant Portal */}
                            <div className="chat-message-body-incoming d-flex justify-content-start align-items-center gap-2 mt-1">
                              {!isConsecutive ? (
                                chatPerson?.profileImage ? (
                                  <Avatar
                                    src={chatPerson.profileImage}
                                    size="large"
                                    style={{ backgroundColor: "" }}
                                  />
                                ) : (
                                  chatPerson.firstName && (
                                    <FirstCharacterIcon
                                      name={chatPerson?.firstName[0]}
                                    />
                                  )
                                )
                              ) : (
                                <div style={{ width: "40px" }}></div>
                              )}
                              <div className="position-relative">
                                <div className="message-incoming">
                                  {data.text}
                                  <div className="message-time normal-grey-text fw-medium">
                                    {moment(data.timestamp)
                                      .format("h:mm a")
                                      .toUpperCase()}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
          <div className="chat-text-area border-top">
            <div className="send-message-text-area">
              <textarea
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    SendMessage();
                  }
                }}
                onChange={(e) => settextMessage(e.target.value)}
                value={textMessage}
                placeholder="Type your message.... "
                name=""
                id=""
                rows={1}
                cols={1}></textarea>
            </div>
            <button
              onClick={SendMessage}
              className="save-btn-outline fw-bold primary-orange-text">
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBody;
