import React, { useEffect, useState, useRef } from "react";
import { ConfigProvider } from "antd";
import NotFound from "assets/not-found-img.png";
import { useNavigate, Link } from "react-router-dom";
import { Table, DatePicker } from "antd";
import chevronIcon from "assets/chevron-down.png";
import calendarIconDate from "assets/calendar-icon.png";
import trashIconWhite from "assets/trash-icon-white.png";
import UseGetHook from "Hooks/UseGetHook";
import { useSelector } from "react-redux";
import { CustomTable } from "Components/GeneralComponents";
import { fetchReports, deleteReportById, bulkDeleteReports } from "network/landlord/reports";
import { CSVLink } from "react-csv";
import { usePDF } from "react-to-pdf";
import * as Component from "Components/GeneralComponents";
import { VerticalElipsisIcon } from "assets/icons";
import { message } from "antd";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const { RangePicker } = DatePicker;
let calendar;
calendar = (
  <>
    <img src={calendarIconDate} alt="" />
  </>
);

const PropertyReport = ({ children }) => {
  // States start
  const [selectionType, setSelectionType] = useState("checkbox");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [report, setReport] = useState([]); // State for fetched report
  const [data, setData] = useState([]);
  const [csvData, setCsvData] = useState([]); // State for
  const [deleteId, setDeleteId] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [key, setKey] = useState(null);

  const [openBulkDeleteModal, setOpenBulkDeleteModal] = useState(false)
  const [bulkDeleteLoading, setBulkDeleteLoading] = useState(false);

  // States end
  const search = useSelector((state) => {
    return state.Search.value;
  });
  const fromDate = useSelector((state) => {
    console.log("fromDate:", state.FilterValue.fromDate);
    return state.FilterValue.fromDate;
  });
  const toDate = useSelector((state) => {
    console.log("toDate:", state.FilterValue.toDate);
    return state.FilterValue.toDate;
  });
  const exportType = useSelector((state) => state.reports.exportType);
  const csvLinkRef = useRef(null); // Reference to the CSVLink component
  const { toPDF, targetRef } = usePDF({ filename: "Property Reports.pdf" });
  const navigate = useNavigate();
  // Modal Function
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  // Fetch and process report data
  const fetchData = async () => {
    try {
      setLoading(true); // Set loading before fetching
      const reportData = await fetchReports(
        `property?fromDate=${fromDate}&toDate=${toDate}&search=${search}`
      );
      setReport(reportData); // Save fetched data to state
    } catch (err) {
      console.error("Error fetching report data:", err);
    } finally {
      setLoading(false); // Stop loading after fetch
    }
  };

  useEffect(() => {
    const handleExport = async () => {
      if (exportType === "excel") {
        const toastId = toast.loading("Preparing your excel file...", {
          autoClose: false,
        });

        setTimeout(() => {
          if (csvLinkRef.current) {
            csvLinkRef.current.link.click();
            toast.update(toastId, {
              render: "CSV downloaded successfully!",
              type: "success",
              isLoading: false,
              autoClose: 3000,
            });
          }
        }, 2000);
      } else if (exportType === "pdf") {
        const toastId = toast.loading("Generating your PDF...");

        try {
          await toPDF();
          toast.update(toastId, {
            render: "PDF downloaded successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        } catch (error) {
          toast.update(toastId, {
            render: "Failed to generate PDF. Please try again.",
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
          console.error("PDF generation error:", error);
        }
      }
    };

    handleExport();
  }, [exportType]);

  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const onOpenBulkDeleteModal = () => {
    setOpenBulkDeleteModal(true);
  };
  const onCloseBulkDeleteModal = () => {
    setOpenBulkDeleteModal(false);
  };
  const handleDeleteRecord = async (recordId) => {
    setDeleteLoading(true);
    const response = await deleteReportById(recordId);

    console.log("Delte Respsone: ", response);
    if (response.apiCallStatus === "success") {
      message.success(response.message);
      setDeleteId(null);
      fetchData();
    } else {
      message.error(response);
    }
    setOpenDeleteModal(false);
    setDeleteLoading(false);
  };
  const handleBulkDelete = async () => {
    setBulkDeleteLoading(true);
   const response  = await bulkDeleteReports(selectedTableItem)

   console.log("Delte Respsone: ", response);
    if (response.apiCallStatus === "success") {
      message.success(response.message.message);
      setSelectedTableItem([]);
      fetchData(); 
    }else{
      
      message.error(response);
    }
    setOpenBulkDeleteModal(false);
    setBulkDeleteLoading(false);

  };
  const handleIconClick = (result) => {
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  const formatString = (str) => {
    return str
      .split('_') // Split the string by underscores
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(' '); // Join words with a space
  };
  
  // Process and filter data for table
  useEffect(() => {
    if (report?.length) {
      const filteredData = report.map((e, index) => ({
        key: e._id,
        propertyName: e.data.title,
        type: formatString(e.data.property_type), // Format type
        sub_type: formatString(e.data.property_sub_type), // Format sub_type
        address: e.data.address.address_line_1 +", "+ e.data.address.city+", "+ e.data.address.state+", "+ e.data.address.zipcode,
        rent: `$${e.data.rent_amount.toLocaleString()}`,
      }));
      
      setData(filteredData);

      const filterDataForCSV = report.map((e, index) => ({
        // key: e._id,
        "Property Name": e.data.title,
        "Type": formatString(e.data.property_type), // Format type
        "Sub Type": formatString(e.data.property_sub_type), // Format sub_type
        "Address": e.data.address.address_line_1 +", "+ e.data.address.city+", "+ e.data.address.state+", "+ e.data.address.zipcode,
        "Rent": `$${e.data.rent_amount.toLocaleString()}`,
      }));

      setCsvData(filterDataForCSV); // Save filtered data for CSV export
    } else {
      setData([]); // Clear data if report is empty
    }
  }, [report, search]);

  // Fetch data on component mount and when filters change
  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, search]);

  const columns = [
    {
      title: "Property Name",
      dataIndex: "propertyName",
      render: (text) => (
        <>
          {/* <Link to=""> */}
            <span className="property-table-name-text">{text}</span>
          {/* </Link> */}
        </>
      ),
    },

    {
      title: "Type",
      dataIndex: "type",
      render: (text, record) => (
        <>
         <span>{text}</span>
        </>
      ),
    },
    {
      title: "Sub Type",
      dataIndex: "sub_type",
      render: (text, record) => (
        <>
         <span
         className={`${record.type === "Commercial" ? "type-text-multi-commercial" : "type-text-multi-family"} rounded-4`}
         >{text}</span>
        </>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Rent Amount",
      dataIndex: "rent",
      render: (text) => <span className="rent-text">{text}</span>,
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          {" "}
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDeleteRecord: () => {
                  onOpenDeleteModal();
                  setDeleteId(setting.key);
                },
              }}
              fields={{
                delete: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  let dropdownIcon;
  dropdownIcon = (
    <>
      <img src={chevronIcon} alt="" />
    </>
  );
  return (
    <>
     <ToastContainer />

      {
        <CSVLink
          data={csvData}
          ref={csvLinkRef}
          filename="Property Reports.csv"
          style={{ display: "none" }} // Hide the link
        />
      }
       {
        <Component.DeleteModal
          isOpen={openDeleteModal}
          onClose={onCloseDeleteModal}
          message={"Do you confirm to delete this report record?"}
          deleteCall={() => handleDeleteRecord(deleteId)}
          isLoading={deleteLoading}
        />
      }
       {
        <Component.DeleteModal
          isOpen={openBulkDeleteModal}
          onClose={onCloseBulkDeleteModal}
          message={`Do you confirm to delete ${selectedTableItem.length } report record(s)?`}
          deleteCall={() => handleBulkDelete()}
          isLoading={bulkDeleteLoading}
        />
      }
      <div className="container-fluid">
        {selectedTableItem.length >= 1 && (
          <div className="table-delete-icon mt-3">
            <button onClick={onOpenBulkDeleteModal} className="table-delete-btn next-btn-main">
              <img src={trashIconWhite} />
              Delete
            </button>
          </div>
        )}

        <div className="report-table-container mt-3" ref={targetRef}>
          <CustomTable
            columns={columns}
            dataSource={data}
            loading={loading} // Show loading spinner while fetching
            rowSelection={{
              selectedRowKeys: selectedTableItem,
              onChange: setSelectedTableItem,
            }}
            pagination={true}
            //  onPageChange={handlePageChange}
            //  total={totalRecords} // Pass total records for pagination
            //  currentPage={currentPage}
            //  setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
};

export default PropertyReport;
