import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import trashIconWhite from "assets/trash-icon-white.png";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { MyCutomTable, FilterRow } from "Components/GeneralComponents";

const TenantApplications = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedApplicationIds, setSelectedApplicationIds] = useState([]);
  const [loading, setLoading] = useState(true); // Set initial loading to true
  const [filter, setFilter] = useState({
    search: "",
    fromDate: "",
    toDate: "",
  });
  const [data, setData] = useState([]); // Initially empty data array

  const fetchData = () => {
    setLoading(true); // Start loading
    setTimeout(() => {
      // Simulate API call
      const apiData = [
        {
          key: "1",
          applicant: { name: "Jane Doe", status: "Verified" },
          property: "123 Main St.",
          applicationDate: "12/01/2024",
          moveInCountdown: { days: 10, color: "danger" },
          incomeVerification: "Verified by Plaid",
          applicationStatus: "Approve",
        },
        {
          key: "2",
          applicant: { name: "John Smith", status: "Pending" },
          property: "456 Elm St.",
          applicationDate: "12/05/2024",
          moveInCountdown: { days: 20, color: "warning" },
          incomeVerification: "View Uploaded Documents",
          applicationStatus: "Pending",
        },
        {
          key: "3",
          applicant: { name: "Alice Green", status: "Not Verified" },
          property: "789 Oak Ave.",
          applicationDate: "11/28/2024",
          moveInCountdown: { days: 35, color: "success" },
          incomeVerification: "Verified by Plaid",
          applicationStatus: "Deny",
        },
      ];
      setData(apiData);
      setLoading(false); 
    }, 1000); 
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "Applicant & Identity",
      dataIndex: "applicant",
      render: ({ name, status, key }) => (
        <div>
         <Link  to={`/tenant-application-details?id=66e5ffafaa69d778568d813a`}>
         <div className="fw-bold text-black">{name}</div>
        
          <div>
            {status === "Verified" && (
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            )}
            {status === "Pending" && (
              <ExclamationCircleTwoTone twoToneColor="#faad14" />
            )}
            {status === "Not Verified" && (
              <CloseCircleTwoTone twoToneColor="#ff4d4f" />
            )}
            <span className="ms-2 text-black">{status}</span>
          </div>
          </Link>
        </div>
      ),
    },
    {
      title: "Property",
      dataIndex: "property",
    },
    {
      title: "Application Date",
      dataIndex: "applicationDate",
    },
    {
      title: "Move-In Countdown",
      dataIndex: "moveInCountdown",
      render: ({ days, color }) => (
        <span className={`text-${color}`}>
          <ClockCircleOutlined className="me-1 " />{" "}
          <span className="text-black">{days} Days</span>
        </span>
      ),
    },
    {
      title: "Income Verification",
      dataIndex: "incomeVerification",
    },
    {
      title: "Application Status",
      dataIndex: "applicationStatus",
      render: (status) => (
        <div
          className={`p-2 border-0 rounded-pill text-center ${
            status === "Approve"
              ? "bg-approve"
              : status === "Pending"
              ? "bg-pending"
              : "bg-deny"
          } d-flex align-items-center justify-content-center`}
        >
          <span>{status}</span>
        </div>
      ),
    },
  ];

  const handleFilter = () => {
    console.log("filters: ", filter);
  };
  const handleResetFilter = () => {
    setFilter({
      search: "",
      fromDate: "",
      toDate: "",
    });
  };

  return (
    <div className="container-fluid mt-4">
      <div className="">
        <FilterRow
          filter={filter}
          setFilter={setFilter}
          handleSubmit={handleFilter}
          handleReset={handleResetFilter}
          filterList={{ date: true, range: false }}
        />
      </div>
      <div className="mt-5">
        {selectedApplicationIds.length > 0 && (
          <button className="table-delete-btn next-btn-main mb-2">
            <img src={trashIconWhite} alt="delete" />
            Delete
          </button>
        )}
        <div className="table-container">
          <MyCutomTable
            loading={loading}
            rowSelection={{
              selectedRowKeys: selectedApplicationIds,
              onChange: setSelectedApplicationIds,
            }}
            columns={columns}
            dataSource={data}
            pagination={false}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            bordered
            className="table table-striped table-hover"
          />
        </div>
      </div>
    </div>
  );
};

export default TenantApplications;
