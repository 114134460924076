import { useState } from "react";
import SignUpLeftBanner from "Components/SignUpLeftBanner/SignUpLeftBanner";
import ChevronLeft from "assets/chevron-left.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import config from "Helpers/config.js";

const ForgotPassword = () => {
  const navigate = useNavigate();  
  const { role } = useParams();
  console.log("role: " + role)
  // States start
  const [email, setEmail] = useState("");
  // States end

  const encodedUrl = encodeURIComponent(`${email}`);
  const Forgotpassword = () => {
    fetch(
      // `${config["baseUrl"]}/api/auth/tenant/forgetpassword?email=${encodedUrl}`,
      `https://digitalappbackend.technoholicasprojects.com/api/auth/${role}/forgetpassword?email=${email}`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.apiCallStatus === "success") {
          console.log(res);
          message.success("Email send successfully");
          navigate(`/email/check?email=${email}&role=${role}`);
        } else {
          message.error(res.error.message);
        }
      });
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{height:'100vh'}}>
        <div className="height-issue col-xl-7 col-lg-12 col-md-12 p-0" >
            <SignUpLeftBanner />

          </div>
          <div className="col-xl-5 col-lg-12 col-md-8 ps-5 pe-5">
            <div className="forgot-password-heading my-5 pt-5">
              <h1>Forgot Password</h1>
              <p>If you forget your password, no worries</p>
            </div>
            <div className="forgot-password">
              <div className="row">
                <div className="col-md-12">
                  <p className="fp-text-style">
                    Enter the email you used to create your account so we can
                    add you instructions on how to reset password
                  </p>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <span>Email</span>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-control"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-12">
                  <div className="sign-up-buttons d-flex gap-4">
                    <button
                      onClick={Forgotpassword}
                      className="forgot-password-btn w-100"
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </div>
              <div className="already-account-text text-center mt-5">
                <span>
                  <img src={ChevronLeft} alt="" />
                  <Link
                    to={`/${role}/login`}
                    className="custom-link-text fw-medium"
                  >
                    Back to log in
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
