import React, { useState, useEffect } from "react";
import deductions from "assets/deduction.png";
import mile from "assets/miles.png";
import drive from "assets/lease-balance.png";
import settingIconOrange from "assets/dots-vertical.png";
import viewIcon from "assets/Icon.png";
import deleteIcon from "assets/trash-01.png";
import editIcon from "assets/edit-05.png";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import { Link } from "react-router-dom";
import settingIcon from "assets/three-dots.png";
import completeIcon from "assets/calendar-check-01.png";
import DeleteModal from "Modals/DeleteModal/DeleteModal";
import UseGetHook from "Hooks/UseGetHook";
import MileageEditModal from "Modals/MileageEditModal/MileageEditModal";
import config from "Helpers/config.js";
import { fetchMileageById } from "network/landlord/mileage";
import Loader from "Helpers/Loader";
const MileageView = () => {
  //States start
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteFile, setDeleteFile] = useState(false);
  const [key, setKey] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isHoveredDelete, setIsHoveredDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const [Data, setData] = useState([]);
  const [openEditModalMileage, setOpenEditModalMileage] = useState(false);
  const [mileageId, setMileageId] = useState("");
  //States end

  const OpenFileDeleteModal = () => {
    setDeleteFile(true);
  };
  const onOpenEditModalMileage = () => {
    setOpenEditModalMileage(true);
  };
  const onCloseEditModalMileage = () => {
    setOpenEditModalMileage(false);
  };

  const handleIconClickCustom = () => {
    // Toggle the dropdown state when the icon is clicked
    setDropdownOpen(!isDropdownOpen);
  };
  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleMouseEnterDelete = () => {
    setIsHoveredDelete(true);
  };
  const handleMouseLeaveDelete = () => {
    setIsHoveredDelete(false);
  };

  const { id } = UseUrlParamsHook();
  // const { fetchMileageId, TenantData } = UseGetHook("mileage", id);

  const fetchMileage = async() => {
    setLoading(true)
    const response  = await fetchMileageById(id)
    console.log("Mileage Details: ",response);


    if(response.apiCallStatus === "success"){
      setData(response.message);
      setLoading(false)
    }else{
      console.log("Error in fetching mileage data: ", response);
      setLoading(false)

    }
  }
  useEffect(() => {
    fetchMileage();
  }, []);

  const columns = [
    {
      title: "Business Name",
      dataIndex: "businessName",
      render: (text, businessName) => (
        <Link to={`/mileage-view?id=${businessName.key}`}>
          <span className="mileage-main-table-bold-text">{text}</span>
        </Link>
      ),
    },
    {
      title: "From",
      dataIndex: "fromLocation",
      render: (text, fromLocation) => (
        <>
          <span className="mileage-main-table-bold-text">{text}</span>
        </>
      ),
    },
    {
      title: "To",
      dataIndex: "toLocation",
      render: (text, toLocation) => (
        <>
          <span className="mileage-main-table-bold-text">{text}</span>
        </>
      ),
    },
    {
      title: "Trip Date",
      dataIndex: "tripDate",
    },
    {
      title: "Miles",
      dataIndex: "miles",
      render: (text, miles) => <span>{text}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text, amount) => <span>${text}</span>,
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div className="task-table-setting-container position-relative cursor">
            <img
              src={settingIcon}
              alt=""
              onClick={() => handleIconClick(setting.key)}
            />
            {setting.key === key && (
              <div className="task-table-setting-dropdown-prospect bg-white box-shadow">
                <ul className="p-0 d-flex flex-column gap-3">
                  <Link to="/mileage-view">
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={viewIcon} alt="" /> View
                    </li>
                  </Link>
                  <Link>
                    <li
                      onClick={() => {
                        onOpenEditModalMileage();
                        setMileageId(setting.key);
                      }}
                      className="list-style-none table-setting-dropdown-menu"
                    >
                      {" "}
                      <img src={editIcon} alt="" /> Edit
                    </li>
                  </Link>
                  <Link>
                    <li className="list-style-none table-setting-dropdown-menu">
                      {" "}
                      <img src={completeIcon} alt="" /> Complete
                    </li>
                  </Link>
                  <Link>
                    <li
                      onClick={() => {
                        onOpenModal();
                        setDeleteId(setting.key);
                      }}
                      className="list-style-none table-setting-dropdown-menu"
                    >
                      {" "}
                      <img src={deleteIcon} alt="" /> Delete
                    </li>
                  </Link>
                </ul>
              </div>
            )}
          </div>
        </>
      ),
    },
  ];

  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = Data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  // // Get Mileages
  // useEffect(() => {
  //   fetch(`${config["baseUrl"]}/api/mileage/${id}`, {
  //     method: "GET",
  //     headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  //   })
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((res) => {
  //       if (res.apiCallStatus === "success") {
  //         let temp = [];
  //         temp = res.message;
  //         setData([temp]);
  //         console.log(Data, "success");
  //       } else {
  //         console.log(res, "error");
  //       }
  //     });
  // }, []);

  if(loading){
    return <div className="mx-auto"> <Loader /></div>;
  }

  return (
    <>
      {openEditModalMileage && (
        <MileageEditModal id={mileageId} onClose={onCloseEditModalMileage} />
      )}
      {openDeleteModal && (
        <DeleteModal
          onClose={onCloseDeleteModal}
          component="mileage"
          route="mileage"
          fetchFun={fetchMileageById}
          deleteBtnText="Delete Mileage"
          delId={id}
        />
      )}
      <div className="container-fluid bg-white p-3">
        <div className="row">
          <div className="col-md-12 text-end">
            <img
              onClick={handleIconClickCustom}
              src={settingIconOrange}
              alt=""
              className="cursor"
            />
            {isDropdownOpen && (
              <div className="task-table-setting-dropdown-prospect bg-white box-shadow text-start">
                <ul className="p-0 d-flex flex-column gap-3">
                  <li
                    onClick={() => {
                      onOpenEditModalMileage();
                      setMileageId(id);
                    }}
                    className="list-style-none cursor lease-details-dropdown-icons"
                  >
                    {" "}
                    <img src={editIcon} alt="" /> Edit
                  </li>
                  <li
                    onClick={setOpenDeleteModal}
                    onMouseEnter={handleMouseEnterDelete}
                    onMouseLeave={handleMouseLeaveDelete}
                    className="list-style-none cursor lease-details-dropdown-icons"
                  >
                    {" "}
                    <img src={deleteIcon} alt="" /> Delete
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-4">
            <div className="task-overview-tab-boxes p-3 position-relative">
              <div className="overview-box-img">
                <img src={deductions} alt="" />
              </div>
              <div className="over-view-box-text">
                <span>Total Deduction 2022</span>
                <h3 className="error-text">$532,00</h3>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="task-overview-tab-boxes p-3 position-relative">
              <div className="overview-box-img">
                <img src={mile} alt="" />
              </div>
              <div className="over-view-box-text">
                <span>Miles Driven 2022</span>
                <p className="active-label pb-0 mb-0"></p>
                <h2>2904</h2>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="task-overview-tab-boxes p-3 position-relative">
              <div className="overview-box-img">
                <img src={drive} alt="" />
              </div>
              <div className="over-view-box-text">
                <span>Drives 2022</span>
                <h2>2</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-12">
            <div className="task-info-heading">
              <h4>Mileage Details</h4>
            </div>
            <div className="task-info-lists mt-4">
              <p>
                <span className="task-info-list-span me-3">Business Name:</span>{" "}
                {Data.businessName}
              </p>
              <p>
                <span className="task-info-list-span me-3">From:</span>{" "}
                {Data.fromLocation}
              </p>
              <p>
                <span className="task-info-list-span me-3">To:</span>{" "}
                {Data.toLocation}
              </p>
              <p>
                <span className="task-info-list-span me-3">Trip Date:</span>{" "}
                {/* {Data.map((e) => e.tripDate)} */}
                { Data?.tripDate ? new Date(Data?.tripDate).toLocaleDateString() : "N/A"}
              </p>
             
             {
              Data?.returnTrip && (<>
               <p>
                <span className="task-info-list-span me-3">Return Trip Date:</span>{" "}
                {/* {Data.map((e) => e.tripDate)} */}
                { Data?.returnTripDate ? new Date(Data?.returnTripDate).toLocaleDateString() : "N/A"}
              </p>
              </>)
             }
              <p>
                <span className="task-info-list-span me-3">Miles (one way):</span>{" "}
                {Data.miles}
              </p>
              <p>
                <span className="task-info-list-span me-3">Cents per mile:</span>{" "}
                {Data.centsPerMile}
              </p>
              <p>
                <span className="task-info-list-span me-3">Amount:</span>{" "}
                ${Data.amount}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MileageView;
