import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { RightOutlined } from '@ant-design/icons';
import './styles.css';

import GeneralPoints from './GeneralPoints';
import InformationCollection from './InformationCollection';
import InformationProcessing from './InformationProcessing';
import InformationSharing from './InformationSharing';
import CookiesAndTrackingTechnologies from './CookiesAndTrackingTechnologies';
import SocialLogins from './SocialLogins';
import KeepingInformation from './KeepingInformation';
import KeepingInformationSafe from './KeepingInformationSafe';
import InformationFromMinors from './InformationFromMinors';
import PrivacyRights from './PrivacyRights';
import ControlFeatures from './ControlFeatures';
import SpecificPrivacyRights from './SpecificPrivacyRights';
import UpdatesToNotice from './UpdatesToNotice';
import ContactUsForNotice from './ContactUsForNotice';
import UpdateAndDeleteData from './UpdateAndDeleteData';

const sections = [
    {
        id: 1,
        title: "Privacy Policy",
        content: (
            <>
              <GeneralPoints/>
            </>
          )
      },
  {
    id: 2,
    title: "1. WHAT INFORMATION DO WE COLLECT?",
    content: (
        <>
          <InformationCollection/>
        </>
      )
  },
  {
    id: 3,
    title: "2. HOW DO WE PROCESS YOUR INFORMATION?",
    content: (
        <>
          <InformationProcessing/>
        </>
      )
  },
  {
    id: 4,
    title: "3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?",
    content: (
        <>
          <InformationSharing/>
        </>
      )
  },
  {
    id: 5,
    title: "4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?",
    content: (
        <>
          <CookiesAndTrackingTechnologies/>
        </>
      )
  },
  {
    id: 6,
    title: "5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?",
    content: (
        <>
          <SocialLogins/>
        </>
      )
  },
  {
    id: 7,
    title: "6. HOW LONG DO WE KEEP YOUR INFORMATION?",
    content: (
        <>
          <KeepingInformation/>
        </>
      )
  },
  {
    id: 8,
    title: "7. HOW DO WE KEEP YOUR INFORMATION SAFE?",
    content: (
        <>
          <KeepingInformationSafe/>
        </>
      )
  },
  {
    id: 9,
    title: "8. DO WE COLLECT INFORMATION FROM MINORS?",
    content: (
        <>
          <InformationFromMinors/>
        </>
      )
  },
  {
    id: 10,
    title: "9. WHAT ARE YOUR PRIVACY RIGHTS?",
    content: (
        <>
          <PrivacyRights/>
        </>
      )
  },
  {
    id: 11,
    title: "10. CONTROLS FOR DO-NOT-TRACK FEATURES",
    content: (
        <>
          <ControlFeatures/>
        </>
      )
  },
  {
    id: 12,
    title: "11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?",
    content: (
        <>
          <SpecificPrivacyRights/>
        </>
      )
  },
  {
    id: 13,
    title: "12. DO WE MAKE UPDATES TO THIS NOTICE?",
    content: (
        <>
          <UpdatesToNotice/>
        </>
      )
  },
  {
    id: 14,
    title: "13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?",
    content: (
        <>
          <ContactUsForNotice/>
        </>
      )
  },
  {
    id: 15,
    title: "14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?",
    content: (
        <>
          <UpdateAndDeleteData/>
        </>
      )
  }
];

function App() {
  const scrollToSection = (id) => {
    const element = document.getElementById(`section-${id}`);
    if (element) {
      document.querySelector('.main-content').scrollTo({
        top: element.offsetTop - 20,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="container-fluid p-0">
      <div className="row g-0">
        {/* Table of Contents */}
        <div className="col-lg-3 toc-container">
          <div className="toc-wrapper">
            <h2 className="toc-title">Table of Contents</h2>
            <div className="toc-list">
              {sections.map((section) => (
                <div
                  key={section.id}
                  className="toc-item"
                  onClick={() => scrollToSection(section.id)}
                >
                  <RightOutlined className="toc-icon" />
                  <span>{section.title}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="col-lg-9 main-content-wrapper">
          <div className="main-content">
            {/* <h1 className="policy-title">Privacy Policy</h1> */}
            {sections.map((section) => (
              <div key={section.id} id={`section-${section.id}`} className="content-section">
                <h2>{section.title}</h2>
                <p>{section.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

