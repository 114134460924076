import axios from 'axios';
import config from "Helpers/config.js"

const getDashboardAnalytics = async () => {
    console.log('getDashboardAnalytics called')
  try {
    const response = await axios.get(
      `${config["baseUrl"]}/api/tenant/dashboard/analytics`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      }
    );
    console.log("get Dashboard analytics : ", response);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export {
    getDashboardAnalytics
}