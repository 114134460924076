import React from "react";
import { DatePicker, ConfigProvider } from "antd";
import calendarIconDate from "assets/calendar-icon.png";

function DateRangeSelect({ handleChange }) {
  const { RangePicker } = DatePicker;
  let calendar;
  calendar = (
    <>
      <img src={calendarIconDate} alt="" />
    </>
  );

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            DatePicker: {
              zIndexPopupBase: 99999,
              colorPrimaryHover: "#EF6B3E",
              borderRadius: 4,
              fontFamily: "Montserrat",
              colorText: "#667085",
              colorTextPlaceholder: "#667085",
              fontSize: 16,
              colorPrimary: "#EF6B3E",
              cellActiveWithRangeBg: "#FFEAD5",
            },
          },
        }}
      >
        <RangePicker
          onChange={(e) => {
            handleChange(e);
          }}
          suffixIcon={calendar}
          style={{
            width: "100%",
            height: 45,
          }}
        />
      </ConfigProvider>
    </>
  );
}

export default DateRangeSelect;
