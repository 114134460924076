import React from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";

// import './tenant-passport-subscription-modal.css'; // We'll create this CSS file for custom styles

const TenantPassportSubscriptionModal = ({ isOpen, onClose, onSelect, plans }) => {



//   const plans = [
//     {
//       name: "Basic",
//       price: "90",
//       description: "Perfect if you already know what you want.",
//       features: [
//         "3 passport shares",
//         "Valid for 30 days",
//         "Credit report included",
//         "Background check included",
//         "Identity & Income Check",
//         "Share management dashboard",
//       ],
//     },
//     {
//       name: "Standard",
//       price: "120",
//       description: "Includes everything in the Basic plan.",
//       subDescription: "Ideal for applying to multiple properties",
//       popular: true,
//       features: [
//         "10 passport shares",
//         "Valid for 30 days",
//         "Up to 5 shares per day",
//         "Share management dashboard",
//         "Perfect for active searches",
//       ],
//     },
//     {
//       name: "Premium",
//       price: "179",
//       description: "Includes everything in the standard plan.",
//       subDescription: "Best for highly competitive markets.",
//       features: [
//         "Unlimited total shares",
//         "Valid for 30 days",
//         "Share management dashboard",
//         "Priority support",
//         "Great for competitive markets",
//       ],
//     },
//   ];

  if (!isOpen) return null;

  if(plans.length===0) return  (  <div className="modal d-block passport-modal" tabIndex="-1">
    <div className="passport-modal-content modal-xl modal-dialog-centered">
      <div className="passport-modal-content-2 border-0 rounded-4">
        <div className="modal-header border-0 pb-0">
          <div className="w-100 d-flex flex-column text-center position-relative">
            <Skeleton.Button active style={{ width: 700, height: 20 }} />
            <Skeleton.Input active style={{ width: 300, margin: "10px 0" }} />
            <Skeleton.Input active style={{ width: 400, margin: "10px 0" }} />
          </div>
        </div>

        <div className="modal-body">
          <div className="row g-4">
            {[...Array(3)].map((_, index) => (
              <div key={index} className="col-12 col-md-4">
                <div className="passport-card h-100 bg-light border-0 rounded-4 position-relative">
                  <Skeleton.Button active style={{ width: 80, height: 20, margin: "10px 20px"}} />
                  <div className="card-body p-4">
                    <Skeleton.Input active style={{ width: 100, height: 24, marginBottom: 16 }} />
                    <Skeleton.Input active style={{ width: 80, height: 32, marginBottom: 16 }} />
                    <Skeleton.Input active style={{ width: 150, height: 16, marginBottom: 8 }} />
                    <Skeleton.Input active style={{ width: 180, height: 16, marginBottom: 16 }} />
                    <Skeleton active paragraph={{ rows: 4 }} />
                    <Skeleton.Button active style={{ width: "100%", height: 40, marginTop: 16 }} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="modal-footer flex-column border-0 text-center">
          <Skeleton.Input active style={{ width: 300, height: 16, marginBottom: 8 }} />
          <Skeleton.Input active style={{ width: 250, height: 16, marginBottom: 8 }} />
          <Skeleton.Input active style={{ width: 200, height: 16 }} />
        </div>
      </div>
    </div>
  </div>)

  return (
    <div className="modal d-block passport-modal" tabIndex="-1">
    <div className="passport-modal-content modal-xl modal-dialog-centered">
      <div className="passport-modal-content-2 border-0 rounded-4">
        <div
          className="modal-header border-0 pb-0"
         
        >
          <div className="text-center position-relative"
           style={{ width: "98%" }}
          >
            <h1 className="mb-2 fw-bold">Choose Your Tenant Passport</h1>
            <p className="text-muted mb-1">One-time payment, valid for 30 days</p>
            <p className="text-muted">Includes background check and credit report</p>
            <button
              className="btn-close position-absolute top-0 end-0 passport-close-btn"
              onClick={onClose}
            >
              <CloseOutlined />
            </button>
          </div>
        </div>
  
        <div className="modal-body">
          <div className="row g-4">
            {plans.map((plan, index) => (
              <div key={index} className="col-12 col-md-4 d-flex">
                <div
                  className="passport-card h-100 bg-light border-0 rounded-4 position-relative d-flex flex-column"
                >
                  {plan.popular && (
                    <div className="passport-popular-badge">Popular</div>
                  )}
                  <div className="card-body p-4 w-100 d-flex flex-column">
                    <h3 className="card-title h4 text-center fw-bold mb-3">
                      {plan.name}
                    </h3>
                    <div className="passport-price mb-3">
                      <span className="h2 fw-bold text-center">${plan.price}</span>
                    </div>
                    <p className="fst-italic mb-2 text-center fs-6 fw-semibold">
                      {plan.description}
                    </p>
                    {plan.subDescription && (
                      <p className="fst-italic fs-6 mb-4 text-center fw-semibold">
                        {plan.subDescription}
                      </p>
                    )}
                    <ul className="list-unstyled mb-4">
                      {plan.features.map((feature, idx) => (
                        <li key={idx} className="mb-2 fs-6">
                          <CheckOutlined className="me-2 passport-check-icon" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                    <div className="mt-auto">
                      <button
                        onClick={() => onSelect(plan._id)}
                        className="btn w-100 text-white"
                        style={{
                          backgroundColor: "#ff6b4a",
                          borderColor: "#ff6b4a",
                          zIndex: 99999999999,
                          cursor: "pointer",
                        }}
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
  
        <div className="modal-footer flex-column border-0 text-center">
          <p className="mb-2">
            <span className="fw-bold">
              With DigitalTenant's Tenant Passport
            </span>
            <span className="text-muted">
              , you get comprehensive credit and background checks at a
              fraction of the cost of traditional applications.
            </span>
          </p>
          <p className="text-muted mb-2">
            Save up to $60–$115 per application compared to traditional
            methods.
          </p>
          <p className="fw-bold">
            Start saving today—choose the plan that works for you.
          </p>
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default TenantPassportSubscriptionModal;
