import React from 'react';
import { BarChart, Bar, Rectangle, XAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const TenantDashboardBarChart = ({ data }) => {
    // Trim the data to include only the last 6 entries
    // data = [
    //     { name: 'Jan', price: 11000 },
    //     { name: 'Feb', price: 14000 },
    //     { name: 'Mar', price: 15000 },
    //     { name: 'Apr', price: 16000 },
    //     { name: 'May', price: 17000 },
    //     { name: 'Jun', price: 18000 },
    //     // Add more data points if needed...
    //     { name: 'Jul', price: 19000 },
    //     { name: 'Aug', price: 20000 },
    //     { name: 'Sep', price: 21000 },
    //     { name: 'Oct', price: 22000 },
    //     { name: 'Nov', price: 23000 },
    //     { name: 'Dec', price: 24000 },
    //     // Add more data points if needed...
    //     // { name: 'Jan', price: 12000 },
    //     // { name: 'Feb', price: 13000 },
    //     // { name: 'Mar', price: 14000 },
    //     // { name: 'Apr', price: 15000 },
    //     // { name: 'May', price: 16000 },
    // ]
    const chartData = data.slice(-6);

    return (
        <>
            <BarChart
                width={window.innerWidth <= 850 ? 180 : 500}
                height={300}
                data={chartData}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <XAxis dataKey="name" />
                <Tooltip
                    formatter={(value) => [`$ ${value.toLocaleString()}`]}
                    itemStyle={{ color: "#fff", fontSize: "12px", fontWeight: "600" }}
                    wrapperClassName="bar-chart-tooltip"
                    cursor={false}
                />
                <Bar
                    radius={[50, 50, 0, 0]}
                    dataKey="price"
                    fill="#98A2B3"
                    activeBar={<Rectangle fill="#231F20" stroke="#231F20" />}
                />
            </BarChart>
        </>
    );
};

export default TenantDashboardBarChart;
