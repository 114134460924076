import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { message } from "antd";
import person from "assets/person.jpg";
import PostUpdateModal from "Modals/PostUpdateModal/PostUpdateModal";
import * as Component from "Components/GeneralComponents";
import * as InnerComponent from "./index";
import * as Icon from "assets/icons";
import {
  getTasks,
  getRecurringTasks,
  deleteTask,
  deleteTasks,
  completeTask,
  deleteRecurringTask,
} from "network/landlord/task";

const AllTasks = () => {
  const navigate = useNavigate();
  const [taskType, setTaskType] = useState("oneTime");
  const [tasks, setTasks] = useState([]);
  const [openModalTask, setOpenModalTask] = useState(false);

  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [openEditModalTask, setOpenEditModalTask] = useState(false);
  const [openModalPostUpdate, setOpenModalPostUpdate] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [DeleteId, setDeleteId] = useState("");
  const [isDeleteTasksRender, setIsDeleteTasksRender] = useState(false);

  //for pagination
  const [totalTasks, setTotalTasks] = useState(0); // Track total tasks for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [filters, setFilters] = useState({
    search: "",
    fromDate: "",
    toDate: "",
    range: "",
    service: "",
  });
  const [selectedTaskData, setSelectedTaskData] = useState(null);
  const [key, setKey] = useState(null); // to track the open record menu
  const [isRerender, setIsRerender] = useState(false);

  const fetchTasks = async (currentPage = 1, pageSize = 10, filters = {}) => {
    setIsLoading(true);
    const fetchFunction = taskType === "oneTime" ? getTasks : getRecurringTasks;
    const response = await fetchFunction(currentPage, pageSize, filters);

    if (response.apiCallStatus === "success") {
      setTasks(response.message.data);
      setTotalTasks(response.message.pagination.totalTasks); // Assuming totalTasks is in pagination object
    } else {
      message.error("Failed to load tasks");
    }
    setIsLoading(false);
  };

  const handleTaskComplete = async (id) => {
    const response = await completeTask(id);
    if (response.apiCallStatus === "success") {
      message.success("Task completed successfully");
      fetchTasks();
    } else {
      message.error("Failed to complete task");
    }
  };

  const handleDeleteTasks = async (taskIds) => {
    setIsLoading(true);
    const deleteFunction = taskIds.length > 1 ? deleteTasks : deleteTask;
    const response = await deleteFunction(taskIds);

    if (response.apiCallStatus === "success") {
      message.success("Tasks deleted successfully");
      fetchTasks();
    } else {
      message.error("Failed to delete tasks");
    }
  };

  useEffect(() => {
    if (filters.search) {
      console.log("filters.search is enabled: ", filters);
      const searchQuery = filters.search.toLowerCase();
      console.log("searchQuery: ", searchQuery);
      console.log("Tasks: ", tasks);
      setTasks((prevTasks) =>
        prevTasks.filter(
          (task) =>
            task.title.toLowerCase().includes(searchQuery) ||
            task.propertyAddress.toLowerCase().includes(searchQuery) ||
            task.status.toLowerCase().includes(searchQuery)
        )
      );
    } else {
      setFilters({
        search: "",
        fromDate: "",
        toDate: "",
        range: "",
        property: "",
      });
      const updatedFilter = {
        search: "",
        fromDate: "",
        toDate: "",
        range: "",
        property: "",
      };
      fetchTasks(1, 10, updatedFilter);
      setCurrentPage(1);
    }
  }, [filters.search, taskType]);

  const handleIconClick = (result) => {
    console.log("Result: ", result);
    console.log("Task Data: ", tasks);
    // Toggle the dropdownOpen state
    const filterData = tasks.filter((item) => {
      return item._id === result;
    });
    setKey(filterData[0]._id);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };
  // Modal Function
  const onOpenModalPostUpdate = () => {
    setOpenModalPostUpdate(true);
  };
  const onCloseModalPostUpdate = () => {
    setOpenModalPostUpdate(false);
  };
  const onOpenEditModalTask = () => {
    setOpenEditModalTask(true);
  };
  const onCloseEditModalTask = () => {
    setOpenEditModalTask(false);
  };
  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  // handle delete task
  useEffect(() => {
    setIsLoading(true);
    if (DeleteId) {
      setIsLoading(true);
      if (taskType === "oneTime") {
        deleteTask(DeleteId).then((res) => {
          setIsLoading(false);
          if (res.apiCallStatus === "success") {
            setOpenDeleteModal(false);
            message.success("Task Deleted Successfully");
            setIsRerender(!isRerender);
            setDeleteId("");
          }
        });
      } else {
        deleteRecurringTask(DeleteId).then((res) => {
          setIsLoading(false);
          if (res.apiCallStatus === "success") {
            setOpenDeleteModal(false);
            message.success("Task Deleted Successfully");
            setIsRerender(!isRerender);
            setDeleteId("");
          }
        });
      }
    }
    fetchTasks();
  }, [isDeleteTasksRender]);
  useEffect(() => {
    setIsLoading(true);

    const fetchTasks = taskType === "oneTime" ? getTasks : getRecurringTasks;

    fetchTasks(1, 10, filters).then((res) => {
      setIsLoading(false);

      if (res.apiCallStatus === "success") {
        setTaskType(taskType);
        setCurrentPage(1);
        setTasks(res.message.data);
        setTotalTasks(res.message.pagination.totalTasks); // Assuming totalTasks is in pagination object
      }
    });
  }, [isRerender, taskType]); // Add taskType to dependencies to trigger on taskType change

  useEffect(() => {
    fetchTasks(currentPage, pageSize, filters);
  }, [currentPage, pageSize]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      render: (text, task) => (
        <Link
          to={`/task-details?id=${task._id}`}
          className="d-flex align-items-center gap-2"
        >
          <div
            className="d-flex align-items-center justify-content-center flex-shrink-0"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              overflow: "hidden",
              border: `0.5px solid ${task.image ? "#c9c9c9" : "#ef6b3e"}`,
              backgroundColor: "white",
            }}
          >
            {task.image ? (
              <img
                src={task.image}
                alt="task"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            ) : (
              <Icon.TaskIcon width={20} height={20} />
            )}
          </div>
          <div className="assign-date-container">
            {taskType === "recurring" && (
              <>
                <div className="priority-text bg-error">
                  <span>Recurring</span>
                </div>
                <br />
              </>
            )}

            <span className="assign-to-date">{task?.title}</span>
          </div>
        </Link>
      ),
    },
    {
      title: "Assigned To",
      dataIndex: "assignees",
      render: (text, assignees) => (
        <>
          {" "}
          <Component.IconList
            properties={assignees.assignees}
            defaultimage={person}
            name={true}
          />
        </>
      ),
    },
    {
      title: "Due At",
      dataIndex: "due",
      render: (text, task) => (
        <div className="assign-date-container">
          <div
            className={`priority-text ${
              task?.priority === "High"
                ? "bg-error"
                : task?.priority === "Low"
                ? "bg-grey"
                : task?.priority === "Medium"
                ? "bg-yellow"
                : task?.priority === "Very High"
                ? "bg-error"
                : ""
            }`}
          >
            <span>{`${task?.priority} Priority`}</span>
          </div>
          <br />
          <span className="assign-to-date">
            {new Date(task?.dueDate).toLocaleDateString()}
          </span>
        </div>
      ),
    },
    {
      title: "Related To",
      dataIndex: "related",
      render: (text, task) => (
        <div className="all-lease-property-text">{task.propertyAddress}</div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <span
          className={
            status === "Completed"
              ? "completed-status text-white"
              : status === "In Progress"
              ? "in-progress-status text-white"
              : "not-started-status text-dark"
          }
        >
          {status}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting._id)}
          >
            <Icon.VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting._id === key}
              onClose={() => setKey(null)}
              handler={{
                handleView: () =>
                  navigate(`/task-details?id=${setting._id}&type=${taskType}`),
                handleEdit: () => {
                  setSelectedTaskData(setting);
                  onOpenEditModalTask();
                  setTaskId(setting._id);
                },
                handlePostAnUpdate: () => {
                  setSelectedTaskData(setting);
                  console.log("setting", setting);
                  onOpenModalPostUpdate();
                  setTaskId(setting._id);
                },
                handleComplete: () => {
                  handleTaskComplete(setting._id);
                  setIsRerender(!isRerender);
                },
                handleDeleteRecord: () => {
                  onOpenDeleteModal();
                  setDeleteId(setting._id);
                },
              }}
              fields={{
                view: true,
                edit: taskType === "oneTime" && setting.updates.length <= 1,
                postAnUpdate: taskType === "oneTime",
                complete: taskType === "oneTime",
                delete: taskType === "recurring" || taskType === "oneTime",
              }}
            />
          </div>
        </>
      ),
    },
  ];
  const handleResetFilter = () => {
    setFilters({
      search: "",
      fromDate: "",
      toDate: "",
      range: "",
      property: "",
    });
    const updatedFilter = {
      search: "",
      fromDate: "",
      toDate: "",
      range: "",
      property: "",
    };
    fetchTasks(1, 10, updatedFilter);
    setCurrentPage(1);
  };

  const onOpenModalTask = () => {
    setOpenModalTask(true);
  };
  const onCloseModalTask = () => {
    setOpenModalTask(false);
  };

  return (
    <div className="container-fluid bg-white p-3">
      <div className="row mt-1 ps-md-4 col-md-5 col-12">
        <div className="stepper-btn d-flex justify-content-between  gap-1 pb-3">
          <button
            onClick={() => setTaskType("oneTime")}
            className={`${
              taskType === "oneTime" ? "next-btn-same-class" : "recuring-btn"
            }`}
          >
            Single Task
          </button>
          <button
            onClick={() => setTaskType("recurring")}
            className={`${
              taskType === "recurring"
                ? "next-btn-same-class text-nowrap"
                : "recuring-btn text-nowrap"
            }`}
          >
            Recurring Task
          </button>
        </div>
      </div>
      <Component.FilterRow
        filter={filters}
        setFilter={setFilters}
        btnTitle={"Add New Task"}
        btnHandler={() => onOpenModalTask()}
        handleSubmit={() => setIsRerender(!isRerender)}
        handleReset={handleResetFilter}
        filterList={{
          range: true,
          date: true,
          property: true,
        }}
      />

      <Component.DeleteModal
        isOpen={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        message="Are you sure you want to delete the selected task(s)?"
        deleteCall={() => setIsDeleteTasksRender(!isDeleteTasksRender)}
        isLoading={isLoading}
      />

      <Component.FormModalLayout
        title={"Add New Tasks"}
        isOpen={openModalTask}
        onClose={onCloseModalTask}
      >
        <InnerComponent.CreateTaskForm
          onClose={() => {
            onCloseModalTask();
            setIsRerender(!isRerender);
          }}
        />
      </Component.FormModalLayout>

      <Component.FormModalLayout
        title={"Post Update"}
        isOpen={openModalPostUpdate}
        onClose={onCloseModalPostUpdate}
      >
        <PostUpdateModal
          onClose={() => {
            onCloseModalPostUpdate();
            setIsRerender(!isRerender);
          }}
          id={taskId}
          data={selectedTaskData}
        />
      </Component.FormModalLayout>
      <Component.FormModalLayout
        title={"Edit Task"}
        isOpen={openEditModalTask}
        onClose={onCloseEditModalTask}
      >
        <InnerComponent.EditTaskForm
          id={taskId}
          onClose={() => {
            onCloseEditModalTask();
            setIsRerender(!isRerender);
          }}
          data={selectedTaskData}
        />
      </Component.FormModalLayout>

      <div className="table-container mt-5">
      <Component.MyCutomTable
        columns={columns}
        dataSource={tasks}
        loading={isLoading}
        pagination={true}
        rowSelection={{
          selectedRowKeys: selectedTaskIds,
          onChange: setSelectedTaskIds,
        }}
        onPageChange={handlePageChange}
        total={totalTasks} // Pass total records for pagination
        taskType={taskType}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      </div>
    </div>
  );
};

export default AllTasks;
