import { SubmitButton, TextAreaField } from 'Components/GeneralComponents';
import UsePostHook from 'Hooks/UsePostHook';
import UseUrlParamsHook from 'Hooks/UseUrlParamsHook';
import { message } from 'antd';
import React, { useState } from 'react';
import * as Component from "Components/GeneralComponents";
import { sendInvite } from 'network/landlord/serviceprofessional';
const InviteProfessionalModal = ({ onClose, company = "" }) => {
  const { id } = UseUrlParamsHook();
  const [loader, setLoader] = useState(false);

  const radioOptions = [
    { value: "need_services", label: "Need Services" },
    { value: "queryForIssue", label: "Query for Issue" },
    { value: "other", label: "Other" },
  ];

  const [form, setForm] = useState({
    company: company,
    reason: '',
    description: '',
  });

  const [errors, setErrors] = useState({
    reason: '',
    description: '',
  });

  // Handle change for form inputs
  const handleChange = (fieldName, value) => {
    setForm({
      ...form,
      [fieldName]: value,
    });

    // Reset error messages when user starts typing
    if (value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '',
      }));
    }
  };

  // Function to validate the form fields
  const validateForm = () => {
    const newErrors = {};

    if (!form.reason) {
      newErrors.reason = "Please select a reason";
    }
    if (!form.description) {
      newErrors.description = "Please enter a description";
    }

    setErrors(newErrors);

    // Returns true if no errors are found
    return Object.keys(newErrors).length === 0;
  };

  // Submit function
  const inviteProfessional = async () => {
    // Validate form fields
    if (!validateForm()) return;

    const payload = {
      company: form.company,
      description: form.description,
      reason: form.reason,
    };

    const response = await sendInvite(form);

    console.log("RESS: ", response)

    if (response?.apiCallStatus === 'success') {
      message.success('Invite sent successfully');
      onClose();
    } else {
      message.error('Failed to send invite');
    }
  };

  return (
    <div style={{ width: '100%', padding: '20px', boxSizing: 'border-box' }}>
      <div className="row mb-4">
        <div className="col-md-12">
          <p className="fw-semibold" style={{ fontSize: '18px', marginBottom: '10px' }}>
            Why would you like to send an invitation?
          </p>
          <div className="mb-4">
            <Component.RadioGroupField
              value={form.reason}
              handler={(e) => handleChange("reason", e)}
              options={radioOptions}
              errorMessage={errors.reason}
            />
         
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-12">
          <TextAreaField
            handler={(e) => handleChange("description", e.target.value)}
            placeholder="Type the reason.."
            required={true}
            errorMessage={errors.description}
            rows={5}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #d9d9d9',
            }}
          />
        
        </div>
      </div>

      <div className="d-flex justify-content-end mt-4">
        <SubmitButton
          className="modal-submit-btn next-btn-main"
          style={{
            padding: '10px 20px',
            borderRadius: '5px',
            fontSize: '16px',
            fontWeight: '600',
            minWidth: '120px',
          }}
          handler={inviteProfessional}
          isLoading={loader}
        >
          Submit
        </SubmitButton>
      </div>
    </div>
  );
};

export default InviteProfessionalModal;
