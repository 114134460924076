import React, { useState } from "react";
import { deleteTenant } from "network/tenant/auth";
import {
  Checkbox,
  DeleteModal,
  SubmitButton,
} from "Components/GeneralComponents";
import { message } from "antd";

const SettingsDeleteAccount = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState(
    "Are you sure you want to delete this Account?"
  );

  const onClose = () => {
    setOpenDeleteModal(false);
    setIsDeleteDisabled(false);
    setDeleteMsg("Are you sure you want to delete this Account?");
  };

  const onOpen = () => {
    console.log("ischecked", isChecked);
    if (isChecked) {
      setOpenDeleteModal(true);
      setErrorMessage("");
    } else {
      setErrorMessage("Please check the box to delete your account");
    }
  };

  const handleDelete = () => {
    setIsLoading(true);
    deleteTenant().then((res) => {
      setIsLoading(false);
      if (res.apiCallStatus === "success") {
        message.success("Account deleted successfully");
        localStorage.clear();
        window.location.href = "/tenant/login";
      } else {
        setDeleteMsg(res.message);
        setIsDeleteDisabled(true);
      }
    });
  };

  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        onClose={onClose}
        message={deleteMsg}
        deleteCall={handleDelete}
        isLoading={isLoading}
        disableBtn={isDeleteDisabled}
      />

      <div style={{ height: "calc(100vh - 350px)" }}>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="heading pt-4 pb-5 ps-2">DELETE YOUR ACCOUNT</div>
        </div>
        <div className="d-flex flex-column h-100">
          <div className="mt-3 ps-2 h-100">
            <p className="fs-5" style={{ color: "#6f747d" }}>
              When you delete your account, you will lose access to the portal
              and your personal data will be permanently deleted. You have 14
              days to cancel the deletion if you change your mind.
            </p>
            <div className="d-flex">
              <Checkbox
                onChange={() => setIsChecked(!isChecked)}
                label={"Yes, i want to delete my account"}
                errorMessage={errorMessage}
              />
            </div>
          </div>
        </div>
        <div className="d-flex gap-4" style={{ paddingRight: "20px" }}>
          <div className="w-100">
            <SubmitButton type="contained" handler={() => {}}>
              Learn More
            </SubmitButton>
          </div>
          <div className="w-100">
            <SubmitButton handler={onOpen}>
              Delete Account
            </SubmitButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsDeleteAccount;
