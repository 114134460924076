import { Link } from "react-router-dom";
import MapLine from "assets/MapPinLineSm.png";
import dollar from "assets/CurrencyDollar.png";
import LocalServiceProfessionalSearch from "Helpers/LocalServiceProfessionalSearch";
import { FirstCharacterIcon } from "Components/GeneralComponents";
import { Tooltip } from "antd";
import Loader from "Helpers/Loader";
import { useEffect, useState } from "react";
import CustomMobileTablePaginagtion from "Components/GeneralComponents/CustomPagination";
import NotFound from "assets/not-found-img.png";

const FindLocalProfessionalTab = ({
  professionals,
  totalProfessionalsCount,
  filters,
  setFilters,
  setUpdate,
  isLoading,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    setFilters((prev) => ({ ...prev, page: currentPage, limit: itemsPerPage }));
    setUpdate((prev) => !prev);
  }, [currentPage]);

  return (
    <div>
      <LocalServiceProfessionalSearch
        filters={filters}
        setFilters={setFilters}
        setUpdate={setUpdate}
      />

      {isLoading ? (
        <Loader />
      ) : totalProfessionalsCount > 0 ? (
        <>
          {professionals.map((data) => (
            <div className="local-professional-container mt-4" key={data.id}>
              <Link to={`/all-service-professional-details?id=${data.id}`}>
                <div className="local-professional-box d-flex align-items-start gap-3">
                  <div
                    className="d-flex align-items-center justify-content-center  flex-shrink-0"
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    {data.companyLogo ? (
                      <img
                        src={data.companyLogo}
                        alt="task"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <FirstCharacterIcon name={data.companyName} />
                    )}
                  </div>
                  <div className="local-professional-content">
                    <div className="local-top-heading">{data.companyName}</div>
                    {data.new && (
                      <div className="local-main-heading d-flex align-items-center gap-2">
                        {data.services.slice(0, 3).map((service, index) => (
                          <span key={index}>
                            {service}
                            {index < data.services.slice(0, 3).length - 1 &&
                              ", "}
                          </span>
                        ))}
                        {data.services.length > 3 && (
                          <>
                            <Tooltip title={data.services.slice(3).join(", ")}>
                              <span
                                style={{
                                  border: "1px solid rgba(238, 240, 247, 0.86)",
                                  borderRadius: "10px",
                                  padding: "3px 20px 3px 6px",
                                  cursor: "pointer",
                                }}
                              >
                                +{data.services.slice(3).length}
                              </span>
                            </Tooltip>
                          </>
                        )}
                        <span className="new-post">New post</span>
                      </div>
                    )}
                    <div className="local-info d-flex gap-3 align-items-center">
                      <div className="d-flex align-items-center gap-2">
                        <img src={MapLine} alt="" /> {data.state}
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <img src={dollar} alt="" /> {data.charges || "-"}
                      </div>
                    </div>
                    <div className="local-desc mt-1">{data.description}</div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
          <CustomMobileTablePaginagtion
            totalItems={totalProfessionalsCount}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      ) : (
        <div
          className="d-flex align-items-center flex-column justify-content-center"
          style={{ height: "70vh" }}
        >
          <img src={NotFound} alt="Not Found" width={80} />
          <span className="text-secondary mt-2 " style={{ fontSize: "14px" }}>
            No Professionals Found
          </span>
        </div>
      )}
    </div>
  );
};

export default FindLocalProfessionalTab;
