import React from 'react';

function InformationFromMinors() {
  return (
    <div>
       <section>
        <p className="text-gray-600 mb-4">
          <em>In Short:</em> We do not knowingly collect data from or market to children under 18 years of age.
        </p>
        <p className="text-gray-600">
          We do not knowingly collect, use or market to children under 16 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 16 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the Services. If we learn that personal information from users less than 16 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 16, please contact us at privacy@bigcompany.com.
        </p>
      </section>

    </div>
  );
}

export default InformationFromMinors;
