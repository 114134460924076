import { configureStore } from "@reduxjs/toolkit";
import DropDown from "./Slices/DropDownSlice";
import ResponsiveSideDrawer from './Slices/SideDrawerSlice'
import Search from './Slices/SearchSlice'
import PropertyData from "./Slices/PropertyData";
import Chat from "./Slices/ChatSlice";
import {FilterReducer, ReportReducer} from "./Slices/FilterSlice";
import Notifications from "./Slices/Notifications";
const store = configureStore({
    reducer: {
        DropDown: DropDown,
        sideDrawer: ResponsiveSideDrawer,
        Search: Search,
        propertyId: PropertyData,
        Chat: Chat,
        FilterValue: FilterReducer,
        reports: ReportReducer,
        Notifications:Notifications
    },
})
export default store;