import React from 'react';
import { 
  LineChartOutlined,
  SearchOutlined,
  FileTextOutlined
} from '@ant-design/icons';

const TenantApplicationReports = () => {
  return (
    <div className="container-fluid py-4">
      <div className="row g-4">
        <div className="col-12 col-md-4">
          <div className="bg-light rounded p-4 h-100">
            <div className="d-flex justify-content-between align-items-start mb-2">
              <div className="d-flex align-items-center">
                <LineChartOutlined className="fs-4 me-2 primary-text" />
                <h3 className="fs-5 fw-bold mb-0">Credit Report</h3>
              </div>
              <span className="badge primary-text rounded-pill">Available</span>
            </div>
            <p className="text-muted mb-2 small">
              Tenant credit report is verified and ready for review
            </p>
            <p className="text-muted mb-0 small">
              Valid until: 02/03/2025
            </p>
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="bg-light rounded p-4 h-100">
            <div className="d-flex justify-content-between align-items-start mb-2">
              <div className="d-flex align-items-center">
                <SearchOutlined className="fs-4 me-2  primary-text" />
                <h3 className="fs-5 fw-bold mb-0">Criminal History</h3>
              </div>
              <span className="badge  primary-text rounded-pill">Available</span>
            </div>
            <p className="text-muted mb-2 small">
              Criminal history is ready for review
            </p>
            <p className="text-muted mb-0 small">
              Valid until: 02/03/2025
            </p>
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="bg-light rounded p-4 h-100">
            <div className="d-flex justify-content-between align-items-start mb-2">
              <div className="d-flex align-items-center">
                <FileTextOutlined className="fs-4 me-2  primary-text " />
                <h3 className="fs-5 fw-bold mb-0">Eviction History</h3>
              </div>
              <span className="badge  primary-text rounded-pill">Available</span>
            </div>
            <p className="text-muted mb-2 small">
              Eviction history is ready for review
            </p>
            <p className="text-muted mb-0 small">
              Valid until: 02/03/2025
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenantApplicationReports;

