import axios from 'axios';
import config from 'Helpers/config.js';

// General function to fetch reports
const fetchReports = async (url) => {
  try {
    const response = await fetch(`${config["baseUrl"]}/api/reports/getReports/filter/${url}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    const data = await response.json();
    if (data.apiCallStatus === "success") {
      console.log(data.message, "Report fetched successfully");
      return data.message; // Return the report data
    } else {
      console.error(data, "Error fetching report");
      throw new Error(data?.message || "Failed to fetch report");
    }
  } catch (err) {
    console.error("Fetch error:", err);
    throw err; // Propagate the error
  }
};

const deleteReportById = async (id) => {
    try {
      const response = await fetch(`${config["baseUrl"]}/api/reports/delete/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
  
      // Ensure the response status is okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData?.message || "Failed to delete report");
      }
  
      const data = await response.json();
      console.log(data);
      if (data.apiCallStatus === "success") {
        console.log("Report deleted successfully:", data.message);
        return data; // Return success message
      } else {
       return  "Error deleting report";
      }
    } catch (err) {
      console.error("Error occurred while deleting report:", err.message);
      return err; 
    }
  };

  const bulkDeleteReports = async (ids) => {
    try {
      const response = await fetch(`${config["baseUrl"]}/api/reports/bulkDelete`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ids }), // Pass the array of IDs in the request body
      });
  
      // Ensure the response status is okay
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData?.message || "Failed to delete reports");
      }
  
      const data = await response.json();
      console.log(data);
  
      if (data.apiCallStatus === "success") {
        console.log("Reports deleted successfully:", data.message);
        return data; // Return success message
      } else {
        return "Error deleting reports";
      }
    } catch (err) {
      console.error("Error occurred while deleting reports:", err.message);
      return err;
    }
  };
  
  

export { fetchReports, deleteReportById, bulkDeleteReports };
