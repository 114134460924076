import React, { useState, useEffect } from "react";
import { Skeleton } from "antd";
import {
  InputField,
  DateField,
  TextAreaField,
  AmountField,
  SubmitButton,
} from "Components/GeneralComponents";
import { formatDate } from "utils";
import config from "Helpers/config";
import { toNumber } from "lodash";

const MileageEditModal = ({ onClose, id, setUpdate }) => {
  const [form, setForm] = useState({
    fromLocation: "",
    toLocation: "",
    businessName: "",
    tripDate: "",
    returnTrip: false,
    returnTripDate: "",
    purpose: "",
    miles: 0,
    centsPerMile: "",
    amount: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`${config["baseUrl"]}/api/mileage/${id}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.apiCallStatus === "success") {
          const {
            fromLocation,
            toLocation,
            businessName,
            tripDate,
            returnTrip,
            returnTripDate,
            purpose,
            miles,
            centsPerMile,
            amount,
          } = res.message;

          console.log( formatDate(new Date(tripDate)))
          setForm({
            fromLocation,
            toLocation,
            businessName,
            tripDate: tripDate,
            returnTrip,
            returnTripDate,
            purpose,
            miles: toNumber(miles),
            centsPerMile,
            amount,
          });
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [id]);

  const calculateAmount = (updatedForm) => {
    const miles = parseFloat(updatedForm.miles || 0);
    const centsPerMile = parseFloat(updatedForm.centsPerMile || 0);
    const isRoundTrip = updatedForm.returnTrip ? 2 : 1;
    return ((miles * isRoundTrip * centsPerMile) / 100).toFixed(2);
  };

  const handleChange = (fieldName, value) => {
    const updatedForm = {
      ...form,
      [fieldName]: value,
    };

    if (
      fieldName === "miles" ||
      fieldName === "centsPerMile" ||
      fieldName === "returnTrip"
    ) {
      updatedForm.amount = calculateAmount(updatedForm);
    }

    setForm(updatedForm);
  };
  const formatErrorMessage = (key) => {
    return (
      key.charAt(0).toUpperCase() +
      key.slice(1).replace(/([A-Z])/g, " $1") +
      " is required"
    );
  };

  const validateForm = () => {
    const newErrors = {};
    for (const key in form) {
      if ((key === "returnTripDate" && !form.returnTrip)) {
        continue;
      }
      if (form[key] === "" || form[key] === null || form[key] === undefined) {
        newErrors[key] = formatErrorMessage(key);
      }
    }
    setErrors(newErrors);
    console.error(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const editMileage = () => {
    if (!validateForm()) return;

    setLoader(true);
    fetch(`${config["baseUrl"]}/api/mileage/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(form),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoader(false);
        if (res.apiCallStatus === "success") {
          setUpdate(true);
          onClose();
        }
      })
      .catch(() => setLoader(false));
  };

  if (loading) {
    return (
      <div style={{ maxWidth: "600px", margin: "auto" }}>
        <Skeleton active paragraph={{ rows: 6 }} />
      </div>
    );
  }

  return (
    <div>
      <div className="modal-content-main-section p-41">
        <div className="row mt-3">
          <div className="col-md-6">
            <InputField
              label="From"
              name="fromLocation"
              placeholder="From"
              value={form.fromLocation}
              handler={(e) => handleChange("fromLocation", e.target.value)}
              errorMessage={errors.fromLocation}
              required
            />
          </div>
          <div className="col-md-6">
            <InputField
              label="To"
              name="toLocation"
              placeholder="To"
              value={form.toLocation}
              handler={(e) => handleChange("toLocation", e.target.value)}
              errorMessage={errors.toLocation}
              required
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <InputField
              label="Business Name"
              name="businessName"
              placeholder="Enter Business Name"
              value={form.businessName}
              handler={(e) => handleChange("businessName", e.target.value)}
              errorMessage={errors.businessName}
              required
            />
          </div>

          <div className="col-md-6">
            <DateField
              label="Trip Date"
              name="tripDate"
              defaultValue={
                form.tripDate && formatDate(form.tripDate)
              }
              value={form.tripDate}
              placeholder="Select Date"
              handler={(e) => handleChange("tripDate", formatDate(new Date(e)))}
              errorMessage={errors.tripDate}
              required={true}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <label className="d-flex align-items-center">
              <input
                type="checkbox"
                checked={form.returnTrip}
                onChange={(e) => handleChange("returnTrip", e.target.checked)}
              />
              <span className="ms-2">Return Trip</span>
            </label>
          </div>

          {form.returnTrip && (
            <div className="col-md-6">
              <DateField
                // label={"Return Trip Date"}
                name={"returnTripDate"}
                placeholder={"Select return date"}
                defaultValue={
                  form.returnTripDate && formatDate(form.returnTripDate)
                }
                handler={(e) => {
                  const formattedDate = formatDate(new Date(e));
                  handleChange("returnTripDate", formattedDate);
                }}
                errorMessage={errors.returnTripDate}
              />
            </div>
          )}
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <TextAreaField
              rows={3}
              label="Purpose"
              name="purpose"
              placeholder="Add Purpose"
              value={form.purpose}
              handler={(e) => handleChange("purpose", e.target.value)}
              errorMessage={errors.purpose}
              required
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 d-flex flex-wrap">
            <div className="col-md-4 px-2">
              <InputField
                type={"number"}
                name={"Cents Per Mile"}
                required={true}
                label={"Cents Per Mile"}
                placeholder={"Enter cents per mile"}
                value={form.centsPerMile}
                handler={(e) => handleChange("centsPerMile", e.target.value)}
                errorMessage={errors.centsPerMile}
              />
            </div>
            <div className="col-md-4 px-2">
              <InputField
                type={"number"}
                name={"Miles"}
                required={true}
                label={"Miles (One Way)"}
                placeholder={"Enter miles"}
                value={form.miles}
                handler={(e) => handleChange("miles", e.target.value)}
                errorMessage={errors.miles}
              />
            </div>
            <div className="col-md-4 px-2">
              <AmountField
                name="amount"
                required={true}
                label="Amount"
                placeholder={"Calculated amount"}
                disabled={true}
                value={{ amount: form.amount }}
                handler={(value) => handleChange("amount", value.amount)}
                errorMessage={errors.amount}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end col-4 mt-3">
          <SubmitButton
            handler={editMileage}
            isLoading={loader}
            disabled={loader}
          >
            Save
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default MileageEditModal;
