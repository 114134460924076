import React, { useState } from "react";
import * as Component from "Components/GeneralComponents";
import { terminateService } from "network/landlord/serviceprofessional";
const TerminateModal = ({ onClose, id }) => {
  // State for radio selection and description input
  const [form, setForm] = useState({
    reason: "",
    description: "",
  });

  // Options for radio buttons
  const radioOptions = [
    { value: "Illegal", label: "Illegal" },
    { value: "Repetitive", label: "Repetitive" },
    { value: "Delays", label: "Delays" },
    { value: "Other", label: "Other" },
  ];

  // Handle change for radio buttons and text area
  const handleChange = (field, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      [field]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async() => {
    if (!form.reason) {
      alert("Please select a reason for termination.");
      return;
    }
    console.log("Form data submitted:", form);
    // Handle form submission logic (API call, etc.)

    const response = await terminateService(id)

    if(response.apiCallStatus === "success") {
        alert("Service terminated successfully");
    }else{
        alert("Failed to terminate service");
    }

    console.log("TERMINATE SERVICE: ",response);
    onClose();
  };

  return (
    <div className="w-100 p-4">
      <div className="row mt-3 mb-4 px-4">
        <div className="col-12">
          <p className="fw-semibold mb-3">
            Why do you want to terminate this service?
          </p>
          <div className="mb-4">
            <Component.RadioGroupField
              value={form.reason}
              handler={(e) => handleChange("reason", e)}
              options={radioOptions}
            />
          </div>
        </div>
      </div>

      <div className="row mb-4 px-4">
        <div className="col-12">
          <Component.TextAreaField
            rows={4}
            placeholder="Add description"
            value={form.description}
            handler={(e) => handleChange("description", e.target.value)}
          />
        </div>
      </div>

      <div className="d-flex justify-content-end px-4">
        <Component.SubmitButton handler={handleSubmit}>
          Submit
        </Component.SubmitButton>
      </div>
    </div>
  );
};

export default TerminateModal;
