import React from 'react';

function PrivacyRights() {
  return (
    <div>
      <section>
        <p className="text-gray-600 mb-4">
          <em>In Short:</em> You may review, change, or terminate your account at any time, depending on your location, province, or state of residence.
        </p>
        <p className="text-gray-600">
          <strong>Withdrawing your consent:</strong> If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below to update your preferences.
        </p>
      </section>
    </div>
  );
}

export default PrivacyRights;
