import React, { useEffect, useState, useRef } from "react";
import dot from "assets/_Dot.png";
import inActiveDot from "assets/_inActive-Dot.png";
import { useNavigate } from "react-router-dom";
import { usePDF } from "react-to-pdf";
import trashIconWhite from "assets/trash-icon-white.png";
import { useSelector } from "react-redux";
import CustomTable from "Components/GeneralComponents/CustomTable";
import * as Component from "Components/GeneralComponents";
import * as SVG from "assets/svg";
import { fetchReports, deleteReportById, bulkDeleteReports } from "network/landlord/reports";
import { CSVLink } from "react-csv";
import { VerticalElipsisIcon } from "assets/icons";
import { message } from "antd";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TenantReports = () => {
  // States start
  const { toPDF, targetRef } = usePDF({ filename: "Tenant Reports.pdf" });

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [report, setReport] = useState([]); // State for fetched report
  const [data, setData] = useState([]);
  const [csvData, setCsvData] = useState([]); // State for
  const [deleteId, setDeleteId] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [key, setKey] = useState(null);

  const [openBulkDeleteModal, setOpenBulkDeleteModal] = useState(false)
  const [bulkDeleteLoading, setBulkDeleteLoading] = useState(false);

  const fromDate = useSelector((state) => {
    return state.FilterValue.fromDate;
  });
  const toDate = useSelector((state) => {
    return state.FilterValue.toDate;
  });
  const search = useSelector((state) => {
    return state.Search.value;
  });


  const exportType = useSelector((state) => state.reports.exportType);
  const csvLinkRef = useRef(null); // Reference to the CSVLink component

  const navigate = useNavigate();
  
  // Modal Function
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

 

  // Fetch and process report data
  const fetchData = async () => {
    try {
      setLoading(true); // Set loading before fetching
      const reportData = await fetchReports(
        `tenant?fromDate=${fromDate}&toDate=${toDate}&search=${search}`
      );

      console.log(reportData);
      if(reportData.length ==0){
        message.info("No Records Found!");
        setLoading(false); // Stop loading after fetch
      }

      setReport(reportData); // Save fetched data to state
    } catch (err) {
      console.error("Error fetching report data:", err);
      setLoading(false); // Stop loading after fetch

    } finally {
    }
  };

  useEffect(() => {
    const handleExport = async () => {
      if (exportType === "excel") {
        const toastId = toast.loading("Preparing your excel file...", {
          autoClose: false,
        });

        setTimeout(() => {
          if (csvLinkRef.current) {
            csvLinkRef.current.link.click();
            toast.update(toastId, {
              render: "CSV downloaded successfully!",
              type: "success",
              isLoading: false,
              autoClose: 3000,
            });
          }
        }, 2000);
      } else if (exportType === "pdf") {
        const toastId = toast.loading("Generating your PDF...");

        try {
          await toPDF();
          toast.update(toastId, {
            render: "PDF downloaded successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        } catch (error) {
          toast.update(toastId, {
            render: "Failed to generate PDF. Please try again.",
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
          console.error("PDF generation error:", error);
        }
      }
    };

    handleExport();
  }, [exportType]);

  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const onOpenBulkDeleteModal = () => {
    setOpenBulkDeleteModal(true);
  };
  const onCloseBulkDeleteModal = () => {
    setOpenBulkDeleteModal(false);
  };

  const handleDeleteRecord = async (recordId) => {
    setDeleteLoading(true);
    const response = await deleteReportById(recordId);

    console.log("Delte Respsone: ", response);
    if (response.apiCallStatus === "success") {
      message.success(response.message);
      setDeleteId(null);
      fetchData();
    } else {
      message.error(response);
    }
    setOpenDeleteModal(false);
    setDeleteLoading(false);
  };

  const handleBulkDelete = async () => {
    setBulkDeleteLoading(true);
   const response  = await bulkDeleteReports(selectedTableItem)

   console.log("Delte Respsone: ", response);
    if (response.apiCallStatus === "success") {
      message.success(response.message.message);
      setSelectedTableItem([]);
      fetchData(); 
    }else{
      
      message.error(response);
    }
    setOpenBulkDeleteModal(false);
    setBulkDeleteLoading(false);

  };

  const handleIconClick = (result) => {
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  // Process and filter data for table
  useEffect(() => {
    if (report?.length) {
      const filteredData = report.map((e, index) => ({
        key: e._id,
        name: `${e.data.firstName} ${e.data.lastName}`,
        properties: e.data.properties,
        email: e.data.email,
        status: e.data.tenantStatus,
        }));
      setData(filteredData);
      setLoading(false); // Stop loading after fetch


      const filterDataForCSV = report.map((e, index) => ({
        "Name": `${e.data.firstName} ${e.data.lastName}`,
        "Email": e.data.email,
        "Status": e.data.tenantStatus,
        "Properties": [
          ...(e.data.properties || []), 
        ]
          .map((property) => `${property.title}`)
          .join(", "), // Combine names with a comma
      }));

      setCsvData(filterDataForCSV); // Save filtered data for CSV export
    } else {
      setData([]); // Clear data if report is empty
    }
  }, [report, search]);

  // Fetch data on component mount and when filters change
  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, search]);
 
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: (
        <>
          Properties
          {/* <Tooltip title={"Non-Expired Leases Properties"}>
            <img src={helpIcon} alt="" className="cursor ps-2" />
          </Tooltip> */}
        </>
      ),
      dataIndex: "properties",
      render: (text) => (
        <>
          <span className="tenants_table_properties_text">
            <Component.IconList
              properties={text}
              defaultimage={SVG.TownHouse}
            />
          </span>
        </>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      render: (text, type) => (
        <>
          <span>{text} </span>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, status) => (
        <span
          className={
            text === "Inactive" ? "tenant-report-inactive-bar" : "tenant-report-active-bar"
          }
        >
          <img
            src={text === "Inactive" ? inActiveDot : dot}
            alt=""
            className="me-1"
          />
          {text}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          {" "}
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDeleteRecord: () => {
                  onOpenDeleteModal();
                  setDeleteId(setting.key);
                },
              }}
              fields={{
                delete: true,
              }}
            />
          </div>
        </>
      ),
    },
  
  ];
 
  
  return (
    <>
     <ToastContainer />

     {
        <CSVLink
          data={csvData}
          ref={csvLinkRef}
          filename="Tenant Reports.csv"
          style={{ display: "none" }} // Hide the link
        />
      }
        {
        <Component.DeleteModal
          isOpen={openDeleteModal}
          onClose={onCloseDeleteModal}
          message={"Do you confirm to delete this report record?"}
          deleteCall={() => handleDeleteRecord(deleteId)}
          isLoading={deleteLoading}
        />
      }
        {
        <Component.DeleteModal
          isOpen={openBulkDeleteModal}
          onClose={onCloseBulkDeleteModal}
          message={`Do you confirm to delete ${selectedTableItem.length } report record(s)?`}
          deleteCall={() => handleBulkDelete()}
          isLoading={bulkDeleteLoading}
        />
      }
      <div className="container-fluid">
        {selectedTableItem.length >= 1 && (
          <div className="table-delete-icon mt-3">
            <button onClick={onOpenBulkDeleteModal} className="table-delete-btn next-btn-main">
              <img src={trashIconWhite} />
              Delete
            </button>
          </div>
        )}
        <div className="report-table-container mt-3" ref={targetRef}>
          <CustomTable
            columns={columns}
            dataSource={data}
            loading={loading}
            rowSelection={{
              selectedRowKeys: selectedTableItem,
              onChange: setSelectedTableItem,
            }}
            pagination={true}
            //  onPageChange={handlePageChange}
            //  total={totalRecords} // Pass total records for pagination
            //  currentPage={currentPage}
            //  setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </>
  );
};

export default TenantReports;
