import React from "react";

const GeneralPoints = () => {
  return (
    <div className="container mt-5">
      {/* <div className="card"> */}
        {/* <div className="card-body"> */}
          <h1 className="card-title text-center">Privacy Policy</h1>
          <p className="text-muted text-center">Last updated December 12, 2024</p>
          <p>
            This Privacy Notice for DigitalIntent ("we," "us," or "our"), describes how and why we might access,
            collect, store, use, and/or share ("process") your personal information when you use our services
            ("Services"), including when you:
          </p>
          <ul>
            <li>
              Visit our website at <a href="http://www.digitalintent.com">http://www.digitalintent.com</a>, or any
              website of ours that links to this Privacy Notice
            </li>
            <li>
              Download and use our mobile application (DigitalIntent), or any other applications of ours that links
              to this Privacy Notice
            </li>
            <li>Engage with us in other related ways, including any sales, marketing, or events</li>
          </ul>
          <p>
            Questions or concerns? Reading this Privacy Notice will help you understand your privacy rights and
            choices. We are committed to protecting your personal information and your right to privacy. If you have
            any questions or concerns, please contact us at <a href="mailto:privacy@digitalintent.com">privacy@digitalintent.com</a>.
          </p>

          <h2 className="mt-4">Summary of Key Points</h2>
          <p>
            This summary provides key points from our Privacy Notice, but you can find more details about any of these
            topics by clicking the link following each key point or by using our table of contents below to find the
            section you are looking for.
          </p>

          <ul>
            <li>
              <strong>What personal information do we process?</strong> When you visit, use, or navigate our
              Services, we may process personal information depending on how you interact with DigitalIntent and the
              Services, the choices you make, and the products and features you use. Learn more about
              <a href="#what-info-we-collect"> what personal information we collect.</a>
            </li>
            <li>
              <strong>Do we process any sensitive information?</strong> Some of the information that you share with
              us may be considered “sensitive” in certain jurisdictions, such as your racial or ethnic origins, sexual
              orientation, and religious beliefs. Learn more about
              <a href="#sensitive-info"> sensitive information we process.</a>
            </li>
            <li>
              <strong>Do we collect any information from third parties?</strong> We do not collect any information
              from third parties.
            </li>
            <li>
              <strong>How do we process your information?</strong> Learn more about
              <a href="#how-we-process"> how we process your information.</a>
            </li>
            <li>
              <strong>When and with whom do we share personal information?</strong> Learn more about
              <a href="#sharing-info"> when and with whom we share personal information.</a>
            </li>
            <li>
              <strong>How do we keep your information safe?</strong> Learn more about
              <a href="#keeping-info-safe"> how we keep your information safe.</a>
            </li>
            <li>
              <strong>What are your rights?</strong> Learn more about
              <a href="#your-rights"> your rights regarding your personal information.</a>
            </li>
          </ul>

          <h3 className="mt-4">Table of Contents</h3>
          <ol>
            <li><a href="#what-info-we-collect">What Information Do We Collect?</a></li>
            <li><a href="#how-we-process">How Do We Process Your Information?</a></li>
            <li><a href="#sharing-info">When And With Whom Do We Share Your Personal Information?</a></li>
            <li><a href="#cookies">Do We Use Cookies And Other Tracking Technologies?</a></li>
            <li><a href="#social-logins">How Do We Handle Your Social Logins?</a></li>
            <li><a href="#keeping-info-safe">How Long Do We Keep Your Information?</a></li>
            <li><a href="#children">Do We Collect Information From Minors?</a></li>
            <li><a href="#rights">What Are Your Privacy Rights?</a></li>
            <li><a href="#review-delete">How Can You Review, Update, Or Delete The Data We Collect From You?</a></li>
          </ol>

          <p>
            Want to learn more about what we do with any information we collect? Review the full Privacy Notice by
            clicking on the respective section above.
          </p>
        {/* </div>
      </div> */}
    </div>
  );
};

export default GeneralPoints;
