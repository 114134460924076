import React from "react";
import { InputField } from "Components/GeneralComponents";
import searchIcon from "assets/search.png";

function Searchbar({handler}) {
  return (
    <div className="task-search-input position-relative">
      {/* <Component.FilterRow
              
              /> */}
      <InputField
        handler={handler}
        type="text"
        placeholder="Search"
        className="form-control search-form-control-task"
      />
      <div className="search-icon-task">
        <img src={searchIcon} alt="" />
      </div>
    </div>
  );
}

export default Searchbar;
