import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";
import TenantPassport from "Components/TenantPassport/TenantPassport";
import TitleTopBar from "Helpers/TitleTopBar";
import PrivacyPolicy from "Components/PrivacyPolicy";
import React from "react";

const PrivacyPolicyPage = () => {
  return (
    <div className=" bg-white d-flex align-items-stretch">
      <PrivacyPolicy />
    </div>
  );
};

export default PrivacyPolicyPage;
