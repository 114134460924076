import { createSlice } from "@reduxjs/toolkit";

// Filter slice
const Filter = createSlice({
    name: "filter",
    initialState: {
        value: "",
        property: "",
        property_sub_type: "",
        maxRent: "",
        minRent: "",
        status: "",
        bedroom: [],
        bathroom: [],
        state: "",
        lease_term: "",
        rollover_end_of_term: false,
        toDate: "",
        fromDate: "",
    },
    reducers: {
        FilterValue(state, action) {
            const {
                value,
                property,
                property_sub_type,
                minRent,
                maxRent,
                status,
                bedroom,
                bathroom,
                state: propertyState,
                lease_term,
                rollover_end_of_term,
                toDate,
                fromDate,
                reset,
            } = action.payload;

            // Update fields only if values exist in the payload
            if (value !== undefined) state.value = value;
            if (property !== undefined) state.property = property;
            if (property_sub_type !== undefined) state.property_sub_type = property_sub_type;
            if (minRent !== undefined) state.minRent = minRent;
            if (maxRent !== undefined) state.maxRent = maxRent;
            if (status !== undefined) state.status = status;
            if (propertyState !== undefined) state.state = propertyState;

            // Process bedroom and bathroom filters
            if (bedroom) {
                state.bedroom = Object.entries(bedroom)
                    .filter(([_, isSelected]) => isSelected)
                    .map(([key]) => key);
            }

            if (bathroom) {
                state.bathroom = Object.entries(bathroom)
                    .filter(([_, isSelected]) => isSelected)
                    .map(([key]) => key);
            }

            if (lease_term !== undefined) state.lease_term = lease_term;
            if (rollover_end_of_term !== undefined) state.rollover_end_of_term = rollover_end_of_term;
            if (toDate !== undefined) state.toDate = toDate;
            if (fromDate !== undefined) state.fromDate = fromDate;

            // Reset all fields if reset flag is true
            if (reset) {
                state.value = "";
                state.property = "";
                state.property_sub_type = "";
                state.minRent = "";
                state.maxRent = "";
                state.status = "";
                state.bedroom = [];
                state.bathroom = [];
                state.state = "";
                state.lease_term = "";
                state.rollover_end_of_term = false;
                state.toDate = "";
                state.fromDate = "";
            }
        },
    },
});

// Report slice
const ReportSlice = createSlice({
    name: "reports",
    initialState: {
        exportType: "",
    },
    reducers: {
        setExportType(state, action) {
            state.exportType = action.payload; // Update the exportType state
        },
        resetExportType(state) {
            state.exportType = ""; // Reset exportType to an empty string
        },
    },
});

// Export reducers
export const FilterReducer = Filter.reducer;
export const ReportReducer = ReportSlice.reducer;

// Export actions
export const { FilterValue } = Filter.actions;
export const { setExportType, resetExportType } = ReportSlice.actions;
