// TenantIdentityVerificationComponent.js
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { PlaidLink } from "react-plaid-link";
import { generateIdentityVerificatoinLink, hadleIdentityVerificationSuccess } from "network/tenant/passport";
import { message } from "antd";
import Loader from "Helpers/Loader";
import { refundPayment } from "network/tenant/passport";
import Warning from "Components/GeneralComponents/Modals/Warning";
const IdentityVerification = ({ handleNext, data, handlePrev,handleError }) => {
  const [linkToken, setLinkToken] = useState(null);
  const plaidLinkRef = useRef(null);
  const [error, setError] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [failedWarning, setFailedWarning] = useState(null);
  
  const navigate = useNavigate()

  useEffect(() => {
    const fetchLinkToken = async () => {
      try {
        console.log(`Verifying data for ${data}`);
        const response = await generateIdentityVerificatoinLink(data);
        console.log("Link token fetched: ", response);
        if (response?.apiCallStatus == "error") {
          message.error(response.error);
          setFetchError(response?.error.replace("."," "))

          return;
        }
        setLinkToken(response?.linkTokenResponse?.link_token);
        // handleNext();

      } catch (error) {
        console.error("Error fetching link token:", error);
      }
    };

    fetchLinkToken();

    // Optional cleanup if needed for the link token
    return () => setLinkToken(null);
  }, [data]); // Added `data` as a dependency to refetch if it changes

  

  const onSuccess = async(public_token, metadata, identity_verification_id ) => {
    console.log("Public Token: ", public_token);
    console.log("Metadata: ", metadata);
    console.log("Identity Verification ID: ", identity_verification_id);
    const completeVerification = async () => {
      try {
        console.log(`Verifying data for ${data}`);
        const response = await hadleIdentityVerificationSuccess(metadata.link_session_id);
        console.log("Link token fetched: ", response);
        if (response?.apiCallStatus == "error") {
          message.error(response.error);
          setFetchError(response?.error.replace("."," "))

          return;
        }
        // handleNext();

      } catch (error) {
        console.error("Error fetching link token:", error);
      }
    };

    completeVerification();
    handleNext(); // Proceed to the next step after successful verification
  };

 const onFailure = () =>{
   setError("Failed to verify Identity")
   setFailedWarning(true)
   refundPayment();
 }

  return (
    <>
     {
      fetchError &&(
        <Warning
        isOpen={fetchError}
        message={fetchError || "invalid error"}
        onClose={() => handleError()}
        />
      )
    }
     {
      failedWarning &&(
        <Warning
        isOpen={failedWarning}
        message={"Your Identity verification Fialed!"}
        onClose={() => navigate("/tenant-passport")}
        />
      )
    }

{linkToken && (
  <div className="container text-center d-flex flex-column align-items-center justify-content-center"
  style={{
    position: 'relative',
  }}
  >
    <div className="p-4">
      <h2 className="text-center mb-4">Identity Verification</h2>
      <p>Click on the button below to verify your Identity</p>

      {linkToken ? (
        <div className="d-flex justify-content-center align-items-center">
          <PlaidLink
            ref={plaidLinkRef}
            token={linkToken}
            // onLoad={() => {
            //   if (plaidLinkRef.current) {
            //     plaidLinkRef.current.open();
            //   }
            // }}
            onSuccess={onSuccess}
            onFailure={onFailure}
            onExit={(err, metadata) => console.log("Exit: ", err, metadata)}
            className="btn btn-primary text-center"
            style={{
              background: "#ef6b3e",
              borderRadius: "8px",
            }} // Add classes for button styling
          >
            <span className="d-block text-white  p-2 font-weight-0">
              Identity Verification
            </span>
          </PlaidLink>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  </div>
)}
    </>
  );
};

export default IdentityVerification;
