import React from 'react';

function UpdateAndDeleteData() {
  return (
    <div>
      <p>You have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please fill out and submit a data subject access request.</p>
    </div>
  );
}

export default UpdateAndDeleteData;
