import { useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { DatePicker } from "antd";
import CustomTable from "Components/GeneralComponents/CustomTable";
import dot from "assets/_Dot.png";
import dueDot from "assets/_red-Dot.png";
import trashIconWhite from "assets/trash-icon-white.png";
import { fetchReports, deleteReportById, bulkDeleteReports } from "network/landlord/reports";
import { formatPhoneNumber } from "utils";
import { CSVLink } from "react-csv";
import { usePDF } from "react-to-pdf";
import * as Component from "Components/GeneralComponents";

import { VerticalElipsisIcon } from "assets/icons";
import { message } from "antd";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { RangePicker } = DatePicker;

const InvoiceReports = () => {
  // States
  const [data, setData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [selectedTableItem, setSelectedTableItem] = useState([]);
  const [report, setReport] = useState([]);
  const [deleteId, setDeleteId] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [key, setKey] = useState(null);
  const [openBulkDeleteModal, setOpenBulkDeleteModal] = useState(false)
  const [bulkDeleteLoading, setBulkDeleteLoading] = useState(false);

  const fromDate = useSelector((state) => state.FilterValue.fromDate);
  const toDate = useSelector((state) => state.FilterValue.toDate);
  const search = useSelector((state) => state.Search.value);
  const exportType = useSelector((state) => state.reports.exportType);
  const csvLinkRef = useRef(null); 
  const { toPDF, targetRef } = usePDF({ filename: "Invoice Reports.pdf" });

  console.log("export type: ", exportType);
  const navigate = useNavigate();

  console.log("toDate: ", toDate);
  console.log("fromDate: ", fromDate);
  console.log("search: ", search);
  // Fetch and process report data
  const fetchData = async () => {
    try {
      setLoading(true); 
      const reportData = await fetchReports(
        `invoice?fromDate=${fromDate}&toDate=${toDate}&search=${search}`
      );
      setReport(reportData); 
    } catch (err) {
      console.error("Error fetching report data:", err);
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    const handleExport = async () => {
      if (exportType === "excel") {
        const toastId = toast.loading("Preparing your excel file...", {
          autoClose: false,
        });

        setTimeout(() => {
          if (csvLinkRef.current) {
            csvLinkRef.current.link.click();
            toast.update(toastId, {
              render: "CSV downloaded successfully!",
              type: "success",
              isLoading: false,
              autoClose: 3000,
            });
          }
        }, 2000);
      } else if (exportType === "pdf") {
        const toastId = toast.loading("Generating your PDF...");

        try {
          await toPDF();
          toast.update(toastId, {
            render: "PDF downloaded successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        } catch (error) {
          toast.update(toastId, {
            render: "Failed to generate PDF. Please try again.",
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
          console.error("PDF generation error:", error);
        }
      }
    };

    handleExport();
  }, [exportType]);
 

  const onOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const onOpenBulkDeleteModal = () => {
    setOpenBulkDeleteModal(true);
  };
  const onCloseBulkDeleteModal = () => {
    setOpenBulkDeleteModal(false);
  };


  const handleDeleteRecord = async (recordId) => {
    setDeleteLoading(true);
    const response = await deleteReportById(recordId);

    console.log("Delte Respsone: ", response);
    if (response.apiCallStatus === "success") {
      message.success(response.message);
      setDeleteId(null);
      fetchData();
    } else {
      message.error(response);
    }
    setOpenDeleteModal(false);
    setDeleteLoading(false);
  };

  const handleBulkDelete = async () => {
    setBulkDeleteLoading(true);
   const response  = await bulkDeleteReports(selectedTableItem)

   console.log("Delte Respsone: ", response);
    if (response.apiCallStatus === "success") {
      message.success(response.message.message);
      setSelectedTableItem([]);
      fetchData(); 
    }else{
      
      message.error(response);
    }
    setOpenBulkDeleteModal(false);
    setBulkDeleteLoading(false);

  };

  const handleIconClick = (result) => {
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };

  useEffect(() => {
    if (report?.length) {
      const filteredData = report.map((e, index) => ({
        key: e._id,
        invoice_no: e?.data?.invoiceNo.substring(
          e?.data?.invoiceNo.indexOf("INV")
        ),
        name: e.data?.tenantId?.firstName || "N/A",
        phone: e.data?.tenantId?.phone || "N/A",
        amount: e.data?.total || 0,
        invoice_date:
          new Date(e.data?.invoiceDate).toLocaleDateString() || "N/A",
        status: e.data?.paidStatus || "Pending",
      }));
      setData(filteredData);

      const filterDataForCSV = report.map((e, index) => ({
        "Invoice No": e?.data?.invoiceNo.substring(
          e?.data?.invoiceNo.indexOf("INV")
        ),
        "Name": e.data?.tenantId?.firstName || "N/A",
        "Phone": e.data?.tenantId?.phone || "N/A",
        "Amount": e.data?.total || 0,
        "Invoice Date":
          new Date(e.data?.invoiceDate).toLocaleDateString() || "N/A",
        "Status": e.data?.paidStatus || "Pending",
      }));

      setCsvData(filterDataForCSV); // Save filtered data for CSV export
    } else {
      setData([]); // Clear data if report is empty
    }
  }, [report, search]);

  // Fetch data on component mount and when filters change
  useEffect(() => {
    fetchData();
  }, [fromDate, toDate, search]);

  // Table columns
  const columns = [
    {
      title: "Invoice No",
      dataIndex: "invoice_no",
      render: (text) => (
        // <Link to="">
          <span className="property-table-name-text">{text}</span>
        // </Link>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => (
        // <Link to="">
          <span className="property-table-name-text">{text}</span>
        // </Link>
      ),
    },
    {
      title: "Phone No",
      dataIndex: "phone",
      render: (text) => <span>{formatPhoneNumber(text)}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <span
          className={
            text === "Due"
              ? "tenant-report-due-bar"
              : "tenant-report-active-bar"
          }
        >
          <img src={text === "Due" ? dueDot : dot} alt="" className="me-1" />
          {text}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "setting",
      render: (text, setting) => (
        <>
          {" "}
          <div
            className="position-relative cursor text-center"
            onClick={() => handleIconClick(setting.key)}
          >
            <VerticalElipsisIcon width={23} height={23} />
            <Component.RecordMenuPopup
              isOpen={setting.key === key}
              onClose={() => setKey(null)}
              handler={{
                handleDeleteRecord: () => {
                  onOpenDeleteModal();
                  setDeleteId(setting.key);
                },
              }}
              fields={{
                delete: true,
              }}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <>
     <ToastContainer />

      {
        <CSVLink
          data={csvData}
          ref={csvLinkRef}
          filename="Invoice Reports.csv"
          style={{ display: "none" }} // Hide the link
        />
      }
      {
        <Component.DeleteModal
          isOpen={openDeleteModal}
          onClose={onCloseDeleteModal}
          message={"Do you confirm to delete this report record?"}
          deleteCall={() => handleDeleteRecord(deleteId)}
          isLoading={deleteLoading}
        />
      }
       {
        <Component.DeleteModal
          isOpen={openBulkDeleteModal}
          onClose={onCloseBulkDeleteModal}
          message={`Do you confirm to delete ${selectedTableItem.length } report record(s)?`}
          deleteCall={() => handleBulkDelete()}
          isLoading={bulkDeleteLoading}
        />
      }
      <div className="container-fluid">
        {selectedTableItem.length >= 1 && (
          <div className="table-delete-icon mt-3">
            <button onClick={onOpenBulkDeleteModal} className="table-delete-btn next-btn-main">
              <img src={trashIconWhite} alt="Trash Icon" />
              Delete
            </button>
          </div>
        )}
        <div className="report-table-container mt-3" ref={targetRef}>
          <CustomTable
            columns={columns}
            dataSource={data}
            loading={loading} // Show loading spinner while fetching
            rowSelection={{
              selectedRowKeys: selectedTableItem,
              onChange: setSelectedTableItem,
            }}
            pagination={true}
          />
        </div>
      </div>
    </>
  );
};

export default InvoiceReports;
