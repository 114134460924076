import React from "react";

function SpecificPrivacyRights() {
  return (
    <div>
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse border border-gray-200">
          <thead>
            <tr className="bg-gray-50">
              <th className="border border-gray-200 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                Category
              </th>
              <th className="border border-gray-200 px-4 py-2 text-left text-sm font-semibold text-gray-700">
                Examples
              </th>
              <th className="border border-gray-200 px-4 py-2 text-center text-sm font-semibold text-gray-700">
                Collected
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-200 px-4 py-2 text-sm font-medium">
                A. Identifiers
              </td>
              <td className="border border-gray-200 px-4 py-2 text-sm text-gray-600">
                Contact details, such as real name, alias, postal address, email
                address, and contact numbers
              </td>
              <td className="border border-gray-200 px-4 py-2 text-center text-sm">
                YES
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2 text-sm font-medium">
                B. Personal information as defined in the California Customer
                Records statute
              </td>
              <td className="border border-gray-200 px-4 py-2 text-sm text-gray-600">
                Name, contact information, education, employment, employment
                history
              </td>
              <td className="border border-gray-200 px-4 py-2 text-center text-sm">
                YES
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2 text-sm font-medium">
                C. Protected classification characteristics under state or
                federal law
              </td>
              <td className="border border-gray-200 px-4 py-2 text-sm text-gray-600">
                Gender and date of birth
              </td>
              <td className="border border-gray-200 px-4 py-2 text-center text-sm">
                NO
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2 text-sm font-medium">
                D. Commercial information
              </td>
              <td className="border border-gray-200 px-4 py-2 text-sm text-gray-600">
                Transaction information, purchase history, financial details
              </td>
              <td className="border border-gray-200 px-4 py-2 text-center text-sm">
                NO
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2 text-sm font-medium">
                E. Biometric information
              </td>
              <td className="border border-gray-200 px-4 py-2 text-sm text-gray-600">
                Fingerprints and voiceprints
              </td>
              <td className="border border-gray-200 px-4 py-2 text-center text-sm">
                NO
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2 text-sm font-medium">
                F. Internet or other similar network activity
              </td>
              <td className="border border-gray-200 px-4 py-2 text-sm text-gray-600">
                Browsing history, search history, online behavior, interest
                data, and interactions with our and other websites,
                applications, systems, and advertisements
              </td>
              <td className="border border-gray-200 px-4 py-2 text-center text-sm">
                NO
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2 text-sm font-medium">
                G. Geolocation data
              </td>
              <td className="border border-gray-200 px-4 py-2 text-sm text-gray-600">
                Device location
              </td>
              <td className="border border-gray-200 px-4 py-2 text-center text-sm">
                YES
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2 text-sm font-medium">
                H. Audio, electronic, sensory, or similar information
              </td>
              <td className="border border-gray-200 px-4 py-2 text-sm text-gray-600">
                Images and audio, video or call recordings created in connection
                with our business activities
              </td>
              <td className="border border-gray-200 px-4 py-2 text-center text-sm">
                NO
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2 text-sm font-medium">
                I. Professional or employment-related information
              </td>
              <td className="border border-gray-200 px-4 py-2 text-sm text-gray-600">
                Business contact details in order to provide you our Services at
                a business level or job title, work history, and professional
                qualifications if you apply for a job with us
              </td>
              <td className="border border-gray-200 px-4 py-2 text-center text-sm">
                YES
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2 text-sm font-medium">
                J. Education Information
              </td>
              <td className="border border-gray-200 px-4 py-2 text-sm text-gray-600">
                Student records and directory information
              </td>
              <td className="border border-gray-200 px-4 py-2 text-center text-sm">
                NO
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2 text-sm font-medium">
                K. Inferences drawn from collected personal information
              </td>
              <td className="border border-gray-200 px-4 py-2 text-sm text-gray-600">
                Inferences drawn from any of the collected personal information
                listed above to create a profile or summary about, for example,
                an individual’s preferences and characteristics
              </td>
              <td className="border border-gray-200 px-4 py-2 text-center text-sm">
                YES
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2 text-sm font-medium">
                L. Sensitive personal Information
              </td>
              <td className="border border-gray-200 px-4 py-2 text-sm text-gray-600">
                Account login information, debit or credit card numbers,
                drivers' licenses, passport numbers, precise geolocation, social
                security numbers and state ID card numbers
              </td>
              <td className="border border-gray-200 px-4 py-2 text-center text-sm">
                YES
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          We only collect sensitive personal information, as defined by
          applicable privacy laws or the purposes allowed by law or with your
          consent. Sensitive personal information may be used, or disclosed to a
          service provider or contractor, for additional, specified purposes.
          You may have the right to limit the use or disclosure of your
          sensitive personal information. We do not collect or process sensitive
          personal information for the purpose of inferring characteristics
          about you.
        </p>
        <ul>
          <li>Receiving help through our customer support channels;</li>
          <li>Participation in customer surveys or contests; and</li>
          <li>
            Facilitation in the delivery of our Services and to respond to your
            inquiries.
          </li>
        </ul>
        <p>
          We will use and retain the collected personal information as needed to
          provide the Services or for:
        </p>
        <ul>
          <li>
            Category A - Personal identifiers are retained only as long as
            necessary to fulfill their intended purpose, after which they are
            securely deleted in accordance with our data retention policies
          </li>
          <li>Category B - As long as the user has an account with us</li>
          <li>
            Category C - Personal identifiers are retained only as long as
            necessary to fulfill their intended purpose, after which they are
            securely deleted in accordance with our data retention policies
          </li>
          <li>
            Category D - Personal identifiers are retained only as long as
            necessary to fulfill their intended purpose, after which they are
            securely deleted in accordance with our data retention policies
          </li>
          <li>
            Category G - Personal identifiers are retained only as long as
            necessary to fulfill their intended purpose, after which they are
            securely deleted in accordance with our data retention policies
          </li>
          <li>
            Category I - Personal identifiers are retained only as long as
            necessary to fulfill their intended purpose, after which they are
            securely deleted in accordance with our data retention policies
          </li>
          <li>
            Category K - Personal identifiers are retained only as long as
            necessary to fulfill their intended purpose, after which they are
            securely deleted in accordance with our data retention policies
          </li>
          <li>Category L - As long as the user has an account with us</li>
        </ul>
        <h5 className="py-4">Sources of Personal Information</h5>
        <p>
          Learn more about the sources of personal information we collect in
          "WHAT INFORMATION DO WE COLLECT?"
        </p>
        <h5 className="py-4">How We Use and Share Personal Information</h5>
        <p>
          Learn more about how we use your personal information in the section,
          "HOW DO WE PROCESS YOUR INFORMATION?"
        </p>
        <h5 className="py-4">Will your information be shared with anyone else?</h5>
        <p>
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider.
          Learn more about how we disclose personal information to in the
          section, "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
        </p>
        <p>
          We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological
          development and demonstration. This is not considered to be "selling"
          of your personal information.
        </p>
        <p>
          We have not disclosed, sold, or shared any personal information to
          third parties for a business or commercial purpose in the preceding
          twelve (12) months. We will not sell or share personal information in
          the future belonging to website visitors, users, and other consumers.
        </p>
      </div>

      <section className="space-y-4">
        <h5 className="text-lg font-bold">Your Rights</h5>
        <ul className="list-disc pl-5 space-y-2 text-gray-600">
          <li>
            Right to know about the personal information we collect and share
          </li>
          <li>Right to delete the personal information we have about you</li>
          <li>Right to correct or update your personal data</li>
          <li>Right to opt-out/in to marketing communications</li>
          <li>Right to non-discrimination for exercising your rights</li>
          <li>
            Right to opt out of the processing of your personal data if it is
            used for targeted advertising (or sharing as defined under
            California’s privacy law), the sale of personal data, or profiling
            in furtherance of decisions that produce legal or similarly
            significant effects ("profiling")
          </li>
        </ul>
        <p>
          Depending upon the state where you live, you may also have the
          following rights:
        </p>
        <ul>
          <li>
            Right to access the categories of personal data being processed (as
            permitted by applicable law, including Minnesota’s privacy law)
          </li>
          <li>
            Right to obtain a list of the categories of third parties to which
            we have disclosed personal data (as permitted by applicable law,
            including California's and Delaware's privacy law)
          </li>
          <li>
            Right to obtain a list of specific third parties to which we have
            disclosed personal data (as permitted by applicable law, including
            Minnesota's and Oregon's privacy law)
          </li>
          <li>
            Right to review, understand, question, and correct how personal data
            has been profiled (as permitted by applicable law, including
            Minnesota’s privacy law)
          </li>
          <li>
            Right to limit use and disclosure of sensitive personal data (as
            permitted by applicable law, including California’s privacy law)
          </li>
          <li>
            Right to opt out of the collection of sensitive data and personal
            data collected through the operation of a voice or facial
            recognition feature (as permitted by applicable law, including
            Florida’s privacy law)
          </li>
        </ul>

        <h5 className="py-4">How to Exercise Your Rights</h5>
        <p>
          To exercise these rights, you can contact us by submitting a data
          subject access request, by emailing us at privacy@digitaltenant.com,
          or by referring to the contact details at the bottom of this document.
        </p>
        <p>
          Under certain US state data protection laws, you can designate an
          authorized agent to make a request on your behalf. We may deny a
          request from an authorized agent that does not submit proof that they
          have been validly authorized to act on your behalf in accordance with
          applicable laws.
        </p>

        <h5 className="py-4">Request Verification</h5>
        <p>
          Upon receiving your request, we will need to verify your identity to
          determine you are the same person about whom we have the information
          in our system. We will only use personal information provided in your
          request to verify your identity or authority to make the request.
          However, if we cannot verify your identity from the information
          already maintained by us, we may request that you provide additional
          information for the purposes of verifying your identity and for
          security or fraud-prevention purposes.
        </p>
        <p>
          If you submit the request through an authorized agent, we may need to
          collect additional information to verify your identity before
          processing your request and the agent will need to provide a written
          and signed permission from you to submit such request on your behalf.
        </p>
        <h5 className="py-4">Appeals</h5>
        <p>
          Under certain US state data protection laws, if we decline to take
          action regarding your request, you may appeal our decision by emailing
          us at privacy@digitaltenant.com. We will inform you in writing of any
          action taken or not taken in response to the appeal, including a
          written explanation of the reasons for the decisions. If your appeal
          is denied, you may submit a complaint to your state attorney general.
        </p>
        <h5 className="py-4">California "Shine The Light" Law</h5>
        <p>
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us by using the contact details provided in the section
          "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
        </p>
      </section>

      <section className="space-y-4">
        <p className="text-sm text-gray-500">
          For more information about our privacy practices, or if you have
          questions, please contact us at{" "}
          <a
            href="mailto:privacy@company.com"
            className="text-blue-600 hover:underline"
          >
            privacy@company.com
          </a>
        </p>
      </section>
    </div>
  );
}

export default SpecificPrivacyRights;
