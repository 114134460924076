import React from "react";
import TitleTopBar from "Helpers/TitleTopBar";
import TenantApplications from "Components/TenantApplications";
import Fixedtopbar from "Components/FixedTopbar/Fixedtopbar";

const TenantApplicationsPage = () => {
  return (
    <div className="right-component bg-white d-flex align-items-stretch">
      <Fixedtopbar
        topBarTitleDetail={"Tenant Applications"}
        pop={true}
      />
      <TenantApplications />
    </div>
  );
};

export default TenantApplicationsPage;
