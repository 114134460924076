import React,{useState, useEffect} from "react";
import {
  UserOutlined,
  DollarOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  BankOutlined,
  HomeOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import Loader from "Helpers/Loader";
const TenantApplicationOverview = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
  
    useEffect(() => {
      setTimeout(() => {
        const dummyData = {
          personalInfo: {
            name: "Michael Johnson",
            email: "mjohnson.lv@gmail.com",
            itin: "N/A",
            phone: "702-555-9876",
            dob: "03/1992",
            requestedMoveIn: "12/15/2024",
            address: "1435 Pine Valley Rd, Las Vegas, NV 89128",
          },
          currentEmployment: {
            employer: "Desert Valley Tech",
            startDate: "06/2021",
            phone: "702-555-0123",
            position: "Line Manager",
            endDate: "Present",
            address: "2800 Sunset Road, Las Vegas, NV 89120",
          },
          previousEmployment: {
            employer: "Mountain View Systems",
            startDate: "03/2019",
            phone: "702-555-8765",
            position: "Assistant Manager",
            endDate: "05/2021",
            address: "4560 Spring Mountain Rd, Las Vegas, NV 89102",
          },
          rentalHistory: {
            landlord: "Silver State Properties",
            moveInDate: "07/2021",
            contact: "702-555-4321",
            lengthOfStay: "2.5 years",
            currentAddress: "1435 Pine Valley Rd, Las Vegas, NV 89128",
          },
          screeningQuestions: [
            { question: "Do you or anyone else living in the property smoke?", answer: "No" },
            { question: "Have you declared bankruptcy in the last 7 years?", answer: "No" },
            { question: "Do you have any criminal convictions?", answer: "No" },
          ],
          standardQuestions: [
            { question: "How many pets do you have?", answer: "2" },
            { question: "How many people will be living in the unit with you?", answer: "2" },
            { question: "How many total individuals will be applying for this unit including you?", answer: "3" },
          ],
          financialSummary: {
            employmentStatus: "Employed",
            income1: "$4,000",
            income2: "$3,500",
            totalIncome: "$7,500",
          },
          moveInCountdown: "10 days left",
        };
  
        setData(dummyData);
        setLoading(false);
      }, 1000);
    }, []);
  
    if (loading) {
        return <Loader/>;
    }
  return (
    <div
      className="container-fluid py-4 "
      style={{
        fontFamily: "Montserrat, sans-serif",
      }}
    >
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
        <h1 className="h3 mb-0 text-center text-md-left">DigitalTenant Tenant Application Overview</h1>
        <div className="d-flex align-items-center">
          <CheckCircleOutlined className="text-success" />
          <span className="ms-2">Verified Application</span>
        </div>
      </div>

      <div className="row g-3 mb-4">
        <div className="col-12 col-md-4">
          <div className="border rounded p-3 h-100">
            <div className="d-flex align-items-center">
              <DollarOutlined className="me-2" />
              <span className="text-muted">Monthly Income</span>
            </div>
            <h3 className="mt-2 mb-0 fs-4">$7,500</h3>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="border rounded p-3 h-100">
            <div className="d-flex align-items-center">
              <ClockCircleOutlined className="me-2" />
              <span className="text-muted">Move-in Countdown</span>
            </div>
            <div className="text-danger mt-2 fs-6 fw-semibold">10 days left</div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <select className="form-select h-100 p-3">
            <option>Approve Application</option>
          </select>
        </div>
      </div>

      <div className="row g-4">
        <div className="col-12 col-md-6">
          <div className="border rounded p-4">
            <div className="d-flex align-items-center mb-4">
              <UserOutlined className="me-2 fs-5" />
              <h5 className="mb-0">Personal Information</h5>
            </div>
            <div className="row g-3">
              <div className="col-6">
                <div className="mb-3">
                  <div className="text-muted small">Name</div>
                  <div>{data.personalInfo.name}</div>
                </div>
                <div className="mb-3">
                  <div className="text-muted small">Email</div>
                  <div>{data.personalInfo.email}</div>
                </div>
                <div className="mb-3">
                  <div className="text-muted small">ITIN</div>
                  <div>{data.personalInfo.itin}</div>
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <div className="text-muted small">Phone</div>
                  <div>{data.personalInfo.phone}</div>
                </div>
                <div className="mb-3">
                  <div className="text-muted small">DOB</div>
                  <div>{data.personalInfo.dob}</div>
                </div>
                <div className="mb-3">
                  <div className="text-muted small">Requested Move-in</div>
                  <div>{data.personalInfo.requestedMoveIn}</div>
                </div>
              </div>
              <div className="col-12">
                <div className="text-muted small">Address</div>
                <div>{data.personalInfo.address}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="border rounded p-4">
            <div className="d-flex align-items-center mb-4">
              <BankOutlined className="me-2 fs-5" />
              <h4 className="mb-0 ">Current Employment</h4>
            </div>
            <div className="row g-3">
              <div className="col-6">
                <div className="mb-3">
                  <div className="text-muted small">Employer</div>
                  <div>{data.currentEmployment.employer}</div>
                </div>
                <div className="mb-3">
                  <div className="text-muted small">Start Date</div>
                  <div>{data.currentEmployment.startDate}</div>
                </div>
                <div className="mb-3">
                  <div className="text-muted small">Phone</div>
                  <div>{data.currentEmployment.phone}</div>
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <div className="text-muted small">Position</div>
                  <div>{data.currentEmployment.position}</div>
                </div>
                <div className="mb-3">
                  <div className="text-muted small">End Date</div>
                  <div>{data.currentEmployment.endDate}</div>
                </div>
              </div>
              <div className="col-12">
                <div className="text-muted small">Address</div>
                <div>{data.currentEmployment.address}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="border rounded p-4">
            <div className="d-flex align-items-center mb-4">
              <BankOutlined className="me-2 fs-5" />
              <h4 className="mb-0 ">Previous Employment</h4>
            </div>
            <div className="row g-3">
              <div className="col-6">
                <div className="mb-3">
                  <div className="text-muted small">Employer</div>
                  <div>{data.previousEmployment.employer}</div>
                </div>
                <div className="mb-3">
                  <div className="text-muted small">Start Date</div>
                  <div>{data.previousEmployment.startDate}</div>
                </div>
                <div className="mb-3">
                  <div className="text-muted small">Phone</div>
                  <div>{data.previousEmployment.phone}</div>
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <div className="text-muted small">Position</div>
                  <div>{data.previousEmployment.position}</div>
                </div>
                <div className="mb-3">
                  <div className="text-muted small">End Date</div>
                  <div>{data.previousEmployment.endDate}</div>
                </div>
              </div>
              <div className="col-12">
                <div className="text-muted small">Address</div>
                <div>{data.previousEmployment.address}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="border rounded p-4">
            <div className="d-flex align-items-center mb-4">
              <HomeOutlined className="me-2 fs-5" />
              <h4 className="mb-0 ">Rental History</h4>
            </div>
            <div className="row g-3">
              <div className="col-6">
                <div className="mb-3">
                  <div className="text-muted small">Current Landlord</div>
                  <div>{data.rentalHistory.landlord}</div>
                </div>
                <div className="mb-3">
                  <div className="text-muted small">Move-in Date</div>
                  <div>{data.rentalHistory.moveInDate}</div>
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <div className="text-muted small">Contact</div>
                  <div>{data.rentalHistory.contact}</div>
                </div>
                <div className="mb-3">
                  <div className="text-muted small">Length of Stay</div>
                  <div>{data.rentalHistory.lengthOfStay}</div>
                </div>
              </div>
              <div className="col-12">
                <div className="text-muted small">Current Address</div>
                <div>{data.rentalHistory.currentAddress}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="border rounded p-4">
            <div className="d-flex align-items-center mb-4">
              <QuestionCircleOutlined className="me-2 fs-5" />
              <h4 className="mb-0">Screening Questions</h4>
            </div>
            {data.screeningQuestions.map((item, index) => (
              <div className="mb-3" key={index}>
                <div className="text-muted ">{item.question}</div>
                <div>{item.answer}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="border rounded p-4">
            <div className="d-flex align-items-center mb-4">
              <QuestionCircleOutlined className="me-2 fs-5" />
              <h4 className="mb-0">Standard Questions</h4>
            </div>
            {data.screeningQuestions.map((item, index) => (
              <div className="mb-3" key={index}>
                <div className="text-muted ">{item.question}</div>
                <div>{item.answer}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-12">
          <div className="border rounded p-4">
            <div className="d-flex align-items-center mb-4">
              <DollarOutlined className="me-2 fs-5" />
              <h4 className="mb-0">Financial Summary</h4>
            </div>
            <div className="row g-3">
              <div className="col-md-3">
                <div className="text-muted small">Employment Status</div>
                <div>{data.financialSummary.employmentStatus}</div>
              </div>
              <div className="col-md-3">
                <div className="text-muted small">
                  Income (Desert Valley Tech)
                </div>
                <div>$4,000</div>
              </div>
              <div className="col-md-3">
                <div className="text-muted small">
                  Income (Mountain View Systems)
                </div>
                <div>{data.financialSummary.income1}</div>
              </div>
              <div className="col-md-3">
                <div className="text-muted small">Total Monthly Income</div>
                <div>{data.financialSummary.income2}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenantApplicationOverview;
