import { useState, useRef, useCallback, useEffect } from "react";
import searchIcon from "assets/search.png";
import FilterIcon from "assets/filter.png";
import { Select, ConfigProvider } from "antd";
import NotFound from "assets/not-found-img.png";
import settingIcon from "assets/three-dots.png";
import viewIcon from "assets/Icon.png";
import editIcon from "assets/edit-05.png";
import completeIcon from "assets/calendar-check-01.png";
import deleteIcon from "assets/trash-01.png";
import trashIconWhite from "assets/trash-icon-white.png";
import { useNavigate, Link, Outlet } from "react-router-dom";
import { Table, DatePicker, Space } from "antd";
import { usePDF } from "react-to-pdf";
import { CSVLink } from "react-csv";
import checkMark from "assets/check-mark.png";
import chevronIcon from "assets/chevron-down.png";
import calendarIconDate from "assets/calendar-icon.png";
import { InputField } from "Components/GeneralComponents";
import { useDispatch, useSelector } from "react-redux";
import { getValue } from "Store/Slices/SearchSlice";
import { FilterValue, setExportType, resetExportType } from "Store/Slices/FilterSlice";
import {
  reposrtFiltersOptions,
  reposrtsExportOptions,
} from "utils/dropdownData";
import * as Component from "Components/GeneralComponents";
import SearchBar from "Helpers/SearchBar";
import { DateRangeSelect, Searchbar } from "Components/GeneralComponents";
import debounce from "lodash.debounce";

const { RangePicker } = DatePicker;
let calendar;
calendar = (
  <>
    <img src={calendarIconDate} alt="" />
  </>
);

const AllReports = () => {
  // States start
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
  const [selectedTableItem, setSelectedTableItem] = useState("");
  const [selectionType, setSelectionType] = useState("checkbox");
  const [exportDropdown, setExportDropdown] = useState(false);
  const [taskFilterDropdown, settaskFilterDropdown] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [key, setKey] = useState([]);
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [exportTypeOption, setExportTypeOption] = useState(null);
  const exportType = useSelector((state) => state.reports.exportType);

  const [filter, setFilter] = useState({
    fromDate: fromDate,
    toDate: toDate,
    search: search,
  });
  const dispatch = useDispatch();
  // const exportTypeOption = useSelector((state) => state.reports.exportTypeOption);

  // States end

  const navigate = useNavigate();
  // Modal Function
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  const handleChangeExportType = (e) => {
    setExportTypeOption(e);
  };

  const handleDateChange = (dates) => {
    console.log("Dates: ",dates);
    const fromDate = dates ? new Date(dates[0])?.toLocaleDateString() : "";
    const toDate = dates ? new Date(dates[1])?.toLocaleDateString() : "";
    console.log(fromDate);
    console.log(toDate);
    setFilter({
      ...filter,
      fromDate: fromDate,
      toDate: toDate,
    });
    dispatch(FilterValue({ toDate }));
    dispatch(FilterValue({ fromDate }));
  };

  // Function to handle input change
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    // Debugging: log the search term when it changes
    console.log("Search term changed:", search);

    // Debounced function for dispatching both actions
    const debouncedDispatch = debounce((searchTerm) => {
      console.log("Dispatching with search term:", searchTerm); // Debugging

      // Dispatch FilterValue action
      dispatch(FilterValue({ search: searchTerm }));

      // Dispatch getValue action (assuming you need to call it alongside)
      dispatch(getValue(searchTerm));
    }, 500); // Delay of 500ms

    debouncedDispatch(search); // Call debounced dispatch

    // Cleanup on component unmount or when search changes
    return () => {
      console.log("Cleaning up debounce...");
      debouncedDispatch.cancel(); // Cancel debounce if search term changes
    };
  }, [search, dispatch]); // Dependency array to watch changes to `search` and `dispatch`

  // Data Table functions
  const data = [
    {
      key: "1",
      propertyName: "2418 Ellingwood....",
      phone: "+1 (555) 098-7654",
      type: "5/4/2023",
      address: "1500 Marilla St, TX 75201",
      rent: "$381.76",
      tenant: "Emilly Durkheim",
    },
    {
      key: "2",
      propertyName: "2418 Ellingwood....",
      phone: "+1 (555) 098-7654",
      type: "5/4/2023",
      address: "1500 Marilla St, TX 75201",
      rent: "$381.76",
      tenant: "Emilly Durkheim",
    },
    {
      key: "3",
      propertyName: "2418 Ellingwood....",
      phone: "+1 (555) 098-7654",
      type: "5/4/2023",
      address: "1500 Marilla St, TX 75201",
      rent: "$381.76",
      tenant: "Emilly Durkheim",
    },
    {
      key: "4",
      propertyName: "2418 Ellingwood....",
      phone: "+1 (555) 098-7654",
      type: "5/4/2023",
      address: "1500 Marilla St, TX 75201",
      rent: "$381.76",
      tenant: "Emilly Durkheim",
    },
  ];
  const handleIconClick = (result) => {
    // Toggle the dropdownOpen state
    const filterData = data.filter((item) => {
      return item.key === result;
    });
    setKey(filterData[0].key);
    if (key === result) {
      setKey(null);
    } else {
      setKey(result);
    }
  };
  const routeHandleChange = (route) => {
    console.log("route: ", route);

    navigate(`/${route}`);

    dispatch(FilterValue({ reset: true }));
    dispatch(resetExportType());
};


  let dropdownIcon;
  dropdownIcon = (
    <>
      <img src={chevronIcon} alt="" />
    </>
  );
  // dispatch(getValue(search));
  const csvLinkRef = useRef(null); // Reference to the CSVLink component

  const handleExport = () => {
    dispatch(setExportType(exportTypeOption));

    setExportDropdown(false); // Close the dropdown after triggering the download
  };

  return (
    <>
      {
        <CSVLink
          data={data}
          ref={csvLinkRef}
          filename="Property Reports.csv"
          style={{ display: "none" }} // Hide the link
        />
      }
      <div className="container-fluid bg-white p-3 ">
        <div className="row">
          <div className="col-md-12">
            <Searchbar handler={handleSearchChange} />
          </div>
        </div>
        <div className="report-date-container mt-2 d-flex align-items-center justify-content-between flex-wrap">
          <div className="report-type-text-container mt-3">
            <span className="report-type-text ">Report Type</span>
          </div>
          <div className="col-12 col-md-2">
            <Space direction="vertical" size={12}>
              <DateRangeSelect handleChange={handleDateChange} />
            </Space>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-6">
            <div className="report-type-box d-flex gap-3 align-items-center">
              <Component.SelectField
                placeholder={"GENERAL REPORTS"}
                options={reposrtFiltersOptions}
                handler={routeHandleChange}
                // value={route}
              />
            </div>
          </div>
          <div className="col-md-6 text-end">
            <div className="report-export-buttons d-flex justify-content-end align-items-center gap-3">
              <div
                // style={{
                //   width: "150px",
                // }}

                className="col-12 col-md-3 "
              >
                <Component.SelectField
                  placeholder="Export as"
                  options={reposrtsExportOptions}
                  handler={(e) => handleChangeExportType(e)}
                />
              </div>

              <div className="col-5 col-md-3">
                <Component.SubmitButton
                  className="report-download-btn"
                  handler={handleExport}
                  //  isLoading={isLoading}
                   disabled={!exportTypeOption}
                >
                  Download
                </Component.SubmitButton>
              </div>
            </div>
          </div>
        </div>
        {selectedTableItem.length >= 1 && (
          <div className="table-delete-icon mt-3">
            <button className="table-delete-btn next-btn-main">
              <img src={trashIconWhite} />
              Delete
            </button>
          </div>
        )}
        <div className="table-container mt-5" ref={targetRef}>
          <Outlet />
        </div>
        <div
          id="report-table"
          className="not-found-container text-center d-none"
        >
          <img src={NotFound} alt="" />
          <p>
            <strong>No tasks found</strong>
          </p>
          <p>
            No tasks were found; the folder is empty. <br /> Please try again.
          </p>
          <button className="not-found-add-task-btn primary-orange-text">
            <span className="plus">
              <svg
                width={21}
                height={21}
                fill="#EF6B3E"
                stroke="#EF6B3E"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 5v14" />
                <path d="M5 12h14" />
              </svg>
            </span>
            Add Tasks
          </button>
        </div>
      </div>
    </>
  );
};

export default AllReports;
