import React, { useEffect, useState } from "react";
import { ConfigProvider, Tabs, message } from "antd";
import settingIconOrange from "assets/dots-vertical.png";
import deleteIcon from "assets/trash-01.png";
import dollar from "assets/CurrencyDollar.png";
import { useNavigate } from "react-router-dom";
import inProgressIcon from "assets/task-details-progress.png";
import DueDateIcon from "assets/task-details-due.png";
import TerminateModal from "Modals/TerminateModal/TerminateModal";
import UseUrlParamsHook from "Hooks/UseUrlParamsHook";
import config from "Helpers/config.js";
import * as Component from "Components/GeneralComponents";
import InviteProfessionalModal from "Modals/InviteProfessionalModal/InviteProfessionalModal";
import { getCompany } from "network/serviceProfessional/company";
const { TabPane } = Tabs;

const ProfessionalDetails = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [customerQueryId, setCustomerQueryId] = useState(null);
  const navigate = useNavigate();
  const [listProfessionalDetails, setListProfessionalDetails] = useState([]);
  const { id } = UseUrlParamsHook();

  const fetchListProfessionalDetails = async () => {
    const res = await getCompany(id);

    if (res.apiCallStatus === "success") {
      setListProfessionalDetails(res.message);
    } else {
      message.error(res.message);
    }
  };

  useEffect(() => {
    fetchListProfessionalDetails();
  }, []);

  // console.log(listProfessionalDetails, 'aahad')
  const handleIconClickCustom = () => {
    // Toggle the dropdown state when the icon is clicked
    setDropdownOpen(!isDropdownOpen);
  };
  const onOpenModal = (id) => {
    setCustomerQueryId(id)
    setOpenModal(true);
    setDropdownOpen(false);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  const deleteData = (id) => {
    fetch(`${config["baseUrl"]}/api/serviceProviders/deleteInvite/${id}`, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.apiCallStatus === "success") {
          message.success("Invitation deleted successfully");
          navigate(`/all-service-professional`);
          if (setUpdate) setUpdate(true);
        }
        if (res.apiCallStatus === "error") {
          message.error("error in deleting");
        }
      });
  };

 

  const handleInviteModalOpen = (id) => {
    setCompanyId(id);
    setInviteModal(true);
    setDropdownOpen(false);
  }
  const onCloseInviteModal = () => {
    setInviteModal(false);
  }
  return (
    <>
      {openModal === true && 
      <Component.FormModalLayout
      isOpen={openModal}
      title="Select reason for termination"
      onClose={onCloseModal}
      >
          <TerminateModal onClose={onCloseModal}  id={customerQueryId}  />

      </Component.FormModalLayout>
   }
    {inviteModal === true && (
        <Component.FormModalLayout
          title={"Select Reason for Invite"}
          isOpen={inviteModal}
          onClose={onCloseInviteModal}
          width={"600px"}
        >
          <InviteProfessionalModal onClose={onCloseInviteModal} company={companyId} />
        </Component.FormModalLayout>
      )}
      <div className="container-fluid p-3">
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                inkBarColor: "#EF6B3E",
                itemColor: "#667085",
                itemSelectedColor: "#EF6B3E",
                itemHoverColor: "#EF6B3E",
                titleFontSize: 15,
                horizontalItemGutter: window.innerWidth <= 768 ? 10 : 60,
                fontFamily: "Montserrat",
              },
            },
          }}
        >
          <Tabs centered defaultActiveKey="1" style={{ fontWeight: 500 }}>
            <TabPane tab="Overview" key="1">
              <div className="global-setting-icon">
                <img
                  onClick={handleIconClickCustom}
                  src={settingIconOrange}
                  alt=""
                  className="cursor"
                />
                {isDropdownOpen && (
                  <div className="task-table-setting-dropdown-prospect bg-white box-shadow text-start">
                    <ul className="p-0 d-flex flex-column gap-3">
                      <li
                        onClick={()=> onOpenModal(id)}         //listProfessionalDetails.id
                        className="list-style-none cursor lease-details-dropdown-icons"
                      >
                        {" "}
                        <img src={deleteIcon} alt="" /> Terminate
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="row mt-3">
                <div className="col-md-4">
                  <div className="task-overview-tab-boxes p-3 position-relative">
                    <div className="overview-box-img">
                      <img src={inProgressIcon} alt="" />
                    </div>
                    <div className="over-view-box-text text-start">
                      <span>Status</span>
                      <br />
                      <span className="status-active text-white mt-2">
                        {listProfessionalDetails?.status}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="task-overview-tab-boxes p-3 position-relative">
                    <div className="overview-box-img">
                      <img src={DueDateIcon} alt="" />
                    </div>
                    <div className="over-view-box-text">
                      <span>Open work orders</span>
                      <br />
                      <span>
                        {" "}
                        {listProfessionalDetails?.company?.orders
                          ? listProfessionalDetails?.company?.orders
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="task-overview-tab-boxes p-3 position-relative">
                    <div className="overview-box-img">
                      <img src={DueDateIcon} alt="" />
                    </div>
                    <div className="over-view-box-text">
                      <span>Charges</span>
                      <br />
                      <span>
                        <img src={dollar} alt="" />
                      </span>{" "}
                      <span>
                        {listProfessionalDetails?.hourlyRateStart
                          ? listProfessionalDetails?.hourlyRateStart +" - "+ listProfessionalDetails?.hourlyRateEnd+"  Hourly"
                          : "-"}
                       
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-10">
                  <div className="task-info-heading">
                    <h4>Service Professional info</h4>
                  </div>
                  <div className="task-info-lists mt-4">
                    <p>
                      <span className="task-info-list-span me-3">
                        Company Name:
                      </span>{" "}
                      {listProfessionalDetails?.companyName}
                    </p>

                    <p>
                      <span className="task-info-list-span me-3">
                        Location:
                      </span>{" "}
                      {listProfessionalDetails?.address +
                        ", " +
                        listProfessionalDetails?.city +
                        ", " +
                        listProfessionalDetails?.state +
                        ", " +
                        listProfessionalDetails?.zipcode}
                    </p>
                    <p>
                      <span className="task-info-list-span me-3">
                        Phone No :
                      </span>{" "}
                      {listProfessionalDetails?.phone}
                    </p>
                    <p>
                      <span className="task-info-list-span me-3">Email:</span>{" "}
                      {listProfessionalDetails?.email}
                    </p>
                    <p>
                      <span className="task-info-list-span me-3">
                        Services:
                      </span>
                      {listProfessionalDetails?.services?.length > 0
                        ? listProfessionalDetails.services.join(", ")
                        : "-"}
                    </p>

                    <p>
                      <span className="task-info-list-span me-3">
                        Description:
                      </span>
                      {listProfessionalDetails?.description ? listProfessionalDetails.description : "N/A"}
                    </p>
                  </div>
                  <div className="row mt-4">
                    <div className=" row col-md-12">
                      <button
                        onClick= {()=> handleInviteModalOpen(listProfessionalDetails.id)}
                        className="modal-submit-btn next-btn-main w-25  mr-4"
                        style={{
                          marginRight: "10px",
                        }}
                      >
                        Send Invite
                      </button>

                      <button
                        onClick={deleteData}
                        className="modal-submit-btn next-btn-main w-25 mr-4"
                      >
                        Cancel Invite
                      </button>
                    </div>
                  
                  </div>
                </div>
                <div className="col-md-2 text-end">
                  <img
                    src={listProfessionalDetails?.companyLogo}
                    alt=""
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50px",
                    }}
                  />
                </div>
              </div>
            </TabPane>
          </Tabs>
        </ConfigProvider>
      </div>
    </>
  );
};

export default ProfessionalDetails;
