import React from "react";

function ContactUsForNotice() {
  return (
    <div>
      <p>
        If you have questions or comments about this notice, you may email us at
        privacy@digitaltenant.com or contact us by post at:
      </p>
      <p>
        DigitalTenant <br />
        19033 E Linvale Pl, Aurora, CO 80013, USA <br />
        Aurora, CO 80013 <br />
        United States
      </p>
    </div>
  );
}

export default ContactUsForNotice;
